import React, { useContext, useState } from 'react';
import { View, StyleSheet, ViewStyle, StyleProp, TextStyle } from 'react-native';
import MyAppText from './MyAppText';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';
import { TextInputMask } from 'react-native-masked-text';
import { translate } from '../services/translate';

export type PhoneCode = string;

interface MyPhoneInputProps {
    label: string,
    placeholder?: string,
    value: string,
    invalid: (value: string) => boolean;
    onChangeText: (value: string) => void,
    viewStyle?: StyleProp<ViewStyle>;
    labelStyle?: StyleProp<TextStyle>;
}

export default function MyPhoneInput({ label, placeholder, value, invalid, onChangeText, viewStyle, labelStyle }: MyPhoneInputProps) {
    const { theme } = useContext(ThemeContext);
    const [isInvalid, setIsInvalid] = useState(false);

    const styles = getStyles(theme);

    return (
        <View style={[{ rowGap: 5 }, viewStyle]}>
            <MyAppText style={[styles.label, labelStyle]}>{label}</MyAppText>
            <TextInputMask
                placeholder={placeholder}
                value={value}
                includeRawValueInChangeText={true}
                onChangeText={(maskedText, rawText) => {
                    onChangeText(rawText || '');
                }}
                type={'cel-phone'}
                style={[styles.input, {
                    color: value == '' ? getThemedColor(theme, '#CBCBCB') : getThemedColor(theme, '#222222'),
                }, isInvalid ? styles.invalid : undefined]}
                options={{
                    maskType: 'BRL',
                    withDDD: true,
                    dddMask: '(99) '
                }}
                onBlur={() => {
                    setIsInvalid(invalid(value));
                }}
            />
            {isInvalid ?
                <MyAppText style={styles.error}>{translate('invalidPhone')}</MyAppText> : null}
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        input: {
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            maxHeight: 40,
            padding: 10,
            borderRadius: 4,
            color: getThemedColor(theme, '#222222'),
        },
        error: {
            color: '#FF0000',
            fontSize: 10
        },
        invalid: {
            borderColor: '#FF0000',
        },
    });
}
