import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
    unitName: string;
}

function DisplacedDropdown({ value, setValue, zIndex, unitName }: Props) {
    const [selectableDisplaces, setSelectableDisplaces] = useState<{ label: string, value: number; }[]>([]);

    async function getDisplaceReasons() {
        try {
            const equipments = await dispatchService.getDisplaceReasons();
            setSelectableDisplaces(equipments.map((item: DropdownResource) => {
                return {
                    label: item.name, value: item.id
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getDisplaceReasons();
    }, []);

    return (
        <FilterDropdown
            label={`${translate('displaced')}: ${unitName}`}
            items={selectableDisplaces}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default DisplacedDropdown;
