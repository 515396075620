import React, { useState } from 'react';
import { Modal, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import CustomButton from '../../../components/CustomButton';
import MyAppText from '../../../components/MyAppText';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { cameraService } from '../../../services/central-api/cameras';

export interface MaintenanceRequestValues {
    prompt: string;
    msStart: number;
    msFinish: number;
}

interface Props {
    cameraId?: string;
    authenticatedUser?: AuthenticatedUser;
}

export default function MaintenanceRequestModal({ cameraId, authenticatedUser }: Props) {
    const { styles } = useStyles(stylesheet);

    const [isVisible, setIsVisible] = useState(false);
    const [emergency, setEmergency] = useState(false);
    const [reason, setReason] = useState('');

    async function createMaintenanceRequest() {
        try {
            if (!cameraId) {
                return;
            }

            if (emergency) {
                await cameraService.createEmergencyMaintenanceRequest(cameraId, { reason });
            } else {
                await cameraService.createMaintenanceRequest(cameraId, { reason });
            }

            onClose();
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('requestMaintenanceCreated'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function onClose() {

        setIsVisible(false);
        setReason('');
        setEmergency(false);
    }

    return (
        <>
            {authenticatedUser?.isAdmin || authenticatedUser?.permissions.create_emergency_camera_maintenance_requests ?
                <CustomButton
                    icon={faScrewdriverWrench}
                    onPress={() => {
                        setIsVisible(true);
                        setEmergency(true);
                    }}
                    text={translate('requestEmergencyMaintenance')}
                />
                : null
            }
            {authenticatedUser?.isAdmin || authenticatedUser?.permissions.create_camera_maintenance_requests ?
                <CustomButton
                    icon={faScrewdriverWrench}
                    onPress={() => {
                        setIsVisible(true);
                        setEmergency(false);
                    }}
                    text={translate('requestMaintenance')}
                />
                : null
            }

            <Modal visible={isVisible} animationType='fade' transparent onRequestClose={onClose}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalComponent}>
                        <MyAppText style={styles.modalTitle}>
                            {emergency ? translate('requestEmergencyMaintenance') : translate('requestMaintenance')}
                        </MyAppText>

                        <FormInput
                            label={translate('request')}
                            placeholder={translate('requestMaintenancePlaceholder')}
                            value={reason}
                            onChangeText={setReason}
                            invalid={(value: string) => {
                                return value == '';
                            }}
                            multiline
                            numberOfLines={4}
                        />

                        <FormActions
                            onSubmit={() => {
                                createMaintenanceRequest();
                            }}
                            confirmText='toRequest'
                            onClose={onClose}
                            disabled={reason === ''}
                        />
                    </View>
                </View>
            </Modal>
        </>
    );
}

const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
        justifyContent: 'center',
        alignItems: 'center',
    },

    modalComponent: {
        minWidth: {
            xs: undefined,
            md: 450
        },
        minHeight: 150,
        width: {
            md: undefined,
            xs: '90%'
        },
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    },

    modalTitle: {
        fontSize: 18,
        fontWeight: '600'
    },

}));

