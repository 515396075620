import React from 'react';
import LateralMenu from '../LateralMenu';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_smart_search_facial.png';
import collapsedLogo from '../../../assets/smart_search_facial_icon.png';

export type SelectedMenu = 'smartSearchFacial';

interface SmartSearchFacialLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function SmartSearchFacialLateralMenu({ navigation, selectedMenu, open, authenticatedUser, setOpen }: SmartSearchFacialLateralMenuProps) {
    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faSearch,
                text: translate('searchByPhoto'),
                action: () => navigation.navigate('SmartSearchFacial', { screen: 'SmartSearchFacial' }),
                selected: selectedMenu == 'smartSearchFacial'
            }]}
            goBack={() => navigation.navigate('Menu')}
        />
    );
}
