import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ActivityIndicator, DimensionValue, Modal, TouchableOpacity, View } from 'react-native';
import PlateImage from '../../../components/lpr/PlateImage';
import Map from '../../../components/Map';
import LazyLoad from 'react-lazy-load';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import { Hoverable, ScrollView } from 'react-native-web-hover';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faChevronDown, faLocationDot, faRoute, faVideo } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { MyCheckbox } from '../../../components/MyCheckbox';
import CustomButton from '../../../components/CustomButton';
import Toast from 'react-native-toast-message';
import { WindowInformation } from '../../../services/window-information';

interface DetectionDetailsProps {
    detection: LprDetection;
    detectionRoutes: { name: string; cameras: CameraMap[]; }[];
    setDetectionRoutes: Dispatch<SetStateAction<{ name: string; cameras: CameraMap[]; }[]>>;
}

const limit = 20;

export default function Detections({ detection, detectionRoutes, setDetectionRoutes }: DetectionDetailsProps) {
    const { styles, theme } = useStyles(styleSheet);
    const [detections, setDetections] = useState<(LprDetection & { selected?: boolean; })[]>([]);
    const [selected, setSelected] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasMoreResults, setHasMoreResults] = useState(false);
    const [page, setPage] = useState(0);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const windowInfo = WindowInformation();

    async function getDetections() {
        try {
            setIsLoading(true);
            const newDetections = await licensePlateRecognitionService.getDetections({
                page,
                plate: detection.plate,
                irregularSituation: false,
                isMotorcycle: detection.isMotorcycle,
                limit
            });

            if (newDetections.length < limit) {
                setHasMoreResults(false);
            } else {
                setHasMoreResults(true);
            }

            setDetections(prevDetections => [...prevDetections, ...newDetections]);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getDetections();
    }, [page]);

    return (
        <View style={styles.cardList}>
            <View style={{ flex: 1, rowGap: 15, minWidth: 300 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <MyAppText style={{ fontSize: 18, fontWeight: 'bold' }}>{translate('detectionList')}</MyAppText>
                    <TouchableOpacity onPress={() => {
                        setDetections(prevDetections =>
                            prevDetections.map(detection => ({ ...detection, selected: !selected }))
                        );
                        setSelected(!selected);
                    }}>
                        <MyAppText style={{ fontWeight: 'bold' }}>{translate(selected ? 'unselectAll' : 'selectAll')}</MyAppText>
                    </TouchableOpacity>
                </View>
                <ScrollView contentContainerStyle={{ flexDirection: 'column', gap: 15, flexWrap: 'wrap' }}>
                    {
                        isLoading ?
                            <ActivityIndicator size='large' color={theme.colors.loadingColor} /> :
                            detections.map(item => (
                                <View key={item.id} style={{ gap: 4 }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <MyAppText>{translate('detectionId')}: {item.id}</MyAppText>
                                        <View>
                                            <MyCheckbox
                                                label={translate(item.selected ? 'unselect' : 'select')}
                                                checked={!!item.selected}
                                                setChecked={(value: boolean) => {
                                                    setDetections(prevDetections => {
                                                        const updatedDetections = prevDetections.map(d =>
                                                            d.id === item.id ? { ...d, selected: value } : d
                                                        );
                                                        return updatedDetections;
                                                    });
                                                }}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.detectionCard}>
                                        <LazyLoad>
                                            <PlateImage src={item.imageUrl} platePosition={item.platePosition} width={100} height={50} />
                                        </LazyLoad>
                                        <View style={styles.cameraContent}>
                                            <FontAwesomeIcon style={{ width: 22 }} fontSize={16} color={theme.colors.iconColor} icon={faLocationDot} />
                                            <MyAppText >{item.camera.address}</MyAppText>
                                        </View>
                                        <View style={styles.cameraContent}>
                                            <FontAwesomeIcon style={{ width: 22 }} fontSize={16} color={theme.colors.iconColor} icon={faVideo} />
                                            <MyAppText>{item.camera.title}</MyAppText>
                                        </View>
                                        <View style={styles.cameraContent}>
                                            <FontAwesomeIcon style={{ width: 22 }} fontSize={16} color={theme.colors.iconColor} icon={faCalendarCheck} />
                                            <MyAppText >{moment(item.time).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                                        </View>
                                    </View>
                                </View>
                            ))
                    }
                    {!isLoading && hasMoreResults ?
                        <Hoverable style={styles.loadMore}>
                            {({ hovered }) => (
                                <TouchableOpacity style={[styles.loadMore, {
                                    borderBottomWidth: hovered ? 1 : 0
                                }]} onPress={() => setPage(page + 1)}>
                                    <MyAppText style={styles.loadMoreText}>{translate('loadMore')}...</MyAppText>
                                </TouchableOpacity>

                            )}
                        </Hoverable>
                        : null
                    }
                </ScrollView>
                {windowInfo.isMobile ?
                    <CustomButton
                        onPress={() => setModalOpen(true)}
                        text={translate('openOnMaps')}
                        type='secondary'
                    /> : null}
                <CustomButton
                    onPress={() => {
                        setDetectionRoutes(prevDetections => [...prevDetections, ...[{
                            name: `#${detectionRoutes.length + 1} ${translate('route')}`,
                            cameras: detections.filter(detection => detection.selected)
                                .sort((a, b) => moment(a.time).diff(moment(b.time)))
                                .map(detection => detection.camera)
                        }]]);
                        Toast.show({
                            type: 'sentinelxSuccess',
                            text1: translate('CreateRouteSuccessfully'),
                        });
                    }}
                    disabled={detections.filter(detection => detection.selected).length < 2}
                    text={translate('generateRoute')}
                    icon={faRoute} />
            </View>
            {
                !windowInfo.isMobile ? <View style={{ flexGrow: 2 }}>
                    <Map mapControls={false} cameraPositions={detections.filter(detection => detection.selected).map(detection => detection.camera)} />
                </View> : null
            }
            <Modal
                animationType='slide'
                transparent={true}
                visible={modalOpen}
                onRequestClose={() => {
                    setModalOpen(!modalOpen);
                }}>
                <View style={styles.mapModalView}>
                    <TouchableOpacity style={styles.modalBackgroundView} onPress={() => setModalOpen(!modalOpen)} />
                    <View style={styles.topModalView}>
                        <TouchableOpacity style={{ flex: 1, flexDirection: 'row', padding: 10, justifyContent: 'space-between' }} onPress={() => setModalOpen(!modalOpen)}>
                            <FontAwesomeIcon icon={faChevronDown} style={{
                                color: theme.colors.iconColor
                            }} />
                            <MyAppText>{translate('selectedDetections')}</MyAppText>
                            <View></View>
                        </TouchableOpacity>
                    </View>
                    <View style={{ width: '100%', height: '80%' }}>
                        <Map mapControls={false} cameraPositions={detections.filter(detection => detection.selected).map(detection => detection.camera)} />
                    </View>
                </View>
            </Modal>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    cardList: {
        flex: 1,
        flexDirection: 'row',
        gap: 15
    },
    detectionCard: {
        borderTopWidth: 1,
        borderColor: theme.colors.dataTable.border,
        rowGap: 8,
        paddingVertical: 10
    },
    cameraContent: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    loadMoreText: {
        color: theme.colors.labelColor,
        fontSize: 16,
    },
    loadMore: {
        borderColor: theme.colors.labelColor,
        alignItems: 'center',
        justifyContent: 'center'
    },
    mapModalView: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    modalBackgroundView: {
        height: `calc(20% - 50px)` as DimensionValue,
        width: '100%',
        borderColor: theme.colors.cardBorder,
        borderTopWidth: 1
    },
    topModalView: {
        height: 50,
        flexDirection: 'row',
        backgroundColor: theme.colors.backgroundColor,
        alignItems: 'center'
    },
}));
