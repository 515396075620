import React, { useMemo } from 'react';
import FilterDropdown from '../../components/Filter/components/FilterDropdown';
import { translate } from '../../services/translate';

interface Props {
    value: OccurrencePriorities | 'all';
    setValue: React.Dispatch<React.SetStateAction<OccurrencePriorities | 'all'>>;
    zIndex: number;
}

function PriorityDropdown({ value, setValue, zIndex }: Props) {
    const items = useMemo(() => [
        'all',
        'maximum',
        'high',
        'medium',
        'low'
    ].map(priority => {
        return { label: translate(priority), value: priority };
    }), []);

    return (
        <FilterDropdown
            label={translate('priority')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default PriorityDropdown;
