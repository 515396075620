import React, { useEffect, useState } from 'react';
import { accessService } from '../../services/central-api/access';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';


interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
    actingBodyId?: number;
    label: string;
}

function GenericUserDropdown({ value, setValue, zIndex, actingBodyId, label }: Props) {
    const [selectableUsers, setSelectableUsers] = useState<{ label: string, value: number; }[]>([]);

    async function getUsers(actingBodyId?: number) {
        try {
            const users = await accessService.getAccessAllUsers({ actingBodyId });
            setSelectableUsers([
                ...users.map((user) => {
                    return {
                        value: user.id,
                        label: user.name
                    };
                })
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getUsers(actingBodyId);
    }, [actingBodyId]);

    return (
        <FilterDropdown
            label={translate(label)}
            items={selectableUsers}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default GenericUserDropdown;
