import React, { useEffect, useState } from 'react';
import { translate } from '../../../services/translate';
import { Modal, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import { WindowInformation } from '../../../services/window-information';
import FormActions from '../../../components/formActions';
import { dispatchService } from '../../../services/central-api/dispatch';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import MyDropDownPicker from '../../../components/MyDropDownPicker';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    unit?: PaginatedUnit;
}

function UnitPauseModal({ isModalVisible, setModalVisible, unit, onClose }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();
    const [pauseReason, setPauseReason] = useState<number>(0);
    const [pauseReasonVisible, setPauseReasonVisible] = useState<boolean>(false);
    const [pauseReasons, setPauseReasons] = useState<{ label: string; value: number; }[]>([{
        label: '', value: 0
    }]);

    async function getPauseReasons() {
        try {
            const pauseReasons = await dispatchService.getPauseReasons();
            setPauseReasons(pauseReasons.map(p => {
                return { value: p.id, label: p.name };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    async function pauseUnit() {
        try {
            if (unit) {
                await dispatchService.pauseUnpauseUnit(unit.id, unit.paused ? 'unpause' : 'pause', pauseReason);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setPauseReason(0);
                setModalVisible(false);
            }
            onClose();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        getPauseReasons();
    }, [unit]);

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
                    <MyAppText>{`${translate('pauseTeam')}: ${unit?.unitName?.name || ''}`}</MyAppText>
                    <MyDropDownPicker
                        items={pauseReasons}
                        multiple={false}
                        value={pauseReason}
                        setValue={setPauseReason}
                        setOpen={setPauseReasonVisible}
                        open={pauseReasonVisible}
                        zIndex={3}
                    />
                    <FormActions
                        onSubmit={async () => {
                            setModalVisible(false);
                            await pauseUnit();
                        }}
                        confirmText='save'
                        onClose={() => {
                            setModalVisible(false);
                            setPauseReason(0);
                            onClose();
                        }}
                        disabled={pauseReason == 0} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));


export default UnitPauseModal;
