import React from 'react';
import { View, TextInput, TouchableOpacity } from 'react-native';
import MyAppText from './MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-native-toast-message';
import { translate } from '../services/translate';
import { useStyles, createStyleSheet } from 'react-native-unistyles';

interface CopyToClipboardInputProps {
    label: string,
    value: string,
}

export default function CopyToClipboardInput({ label, value }: CopyToClipboardInputProps) {
    const { styles, theme } = useStyles(styleSheet);


    async function copyToClipboard() {
        await navigator.clipboard.writeText(value);
        Toast.show({
            type: 'sentinelxSuccess',
            text1: translate('copiedToClipboard'),
        });
    }

    return (
        <View style={{ rowGap: 5 }}>
            <MyAppText style={styles.label}>{label}</MyAppText>
            <View style={{ flexDirection: 'row' }}>
                <TextInput
                    editable={false}
                    placeholder={label}
                    style={styles.input(value == '')}
                    value={value}
                />
                <TouchableOpacity onPress={() => copyToClipboard()} style={styles.copyBox} delayPressIn={100}>
                    <FontAwesomeIcon icon={faCopy} color={theme.colors.iconColor} fontSize={16}></FontAwesomeIcon>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    label: {
        color: theme.colors.labelColor,
    },
    input: (isPlaceholder: boolean) => ({
        flexGrow: 1,
        fontSize: 16,
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.backgroundColor,
        borderColor: theme.colors.fieldBorderColor,
        borderWidth: 1,
        height: 40,
        padding: 10,
        borderRadius: 4,
        borderEndEndRadius: 0,
        borderTopEndRadius: 0,
        color: isPlaceholder ? theme.colors.placeholder : theme.colors.textColor
    }),
    copyBox: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: theme.colors.fieldBorderColor,
        borderWidth: 1,
        borderRadius: 4,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftWidth: 0
    },
}));

