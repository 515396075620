import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../../services/central-api/dispatch';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    zIndex: number;
    actingBodyId?: number;
}

function TagsDropdown({ value, setValue, zIndex, actingBodyId }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);

    async function getReportTags() {
        try {
            const tags = await dispatchService.getDropDownReportTags(actingBodyId);
            setItems(tags.map((tag) => {
                return {
                    value: tag.id,
                    label: tag.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        setValue([]);
        getReportTags();
    }, [actingBodyId]);

    return (
        <FilterDropdown
            label={translate('tags')}
            items={items}
            setValue={setValue}
            value={value}
            multiple={true}
            mode='BADGE'
            zIndex={zIndex}
        />
    );
}

export default TagsDropdown;
