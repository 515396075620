import React from 'react';
import { translate } from '../../services/translate';
import { Modal, View } from 'react-native';
import FormActions from '../formActions';
import DisplacedDropdown from './DisplacedDropdown';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../CustomButton';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: () => void;
    onRemoveDisplaced: () => void;
    isDisplaced: boolean;
}

function DisplacedModal({ isModalVisible, setIsModalVisible, value, setValue, onSubmit, onRemoveDisplaced, isDisplaced }: Props) {
    const { styles } = useStyles(styleSheet);

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setIsModalVisible(false)}>
            <View style={styles.container}>
                <View style={styles.formContainer}>
                    <DisplacedDropdown setValue={setValue} value={value} unitName='test' zIndex={2} />
                    {isDisplaced ?
                        <CustomButton onPress={() => onRemoveDisplaced()} text={translate('removeDisplaced')} icon={faTimes} />
                        : null}
                    <FormActions
                        onSubmit={async () => onSubmit()}
                        onClose={() => setIsModalVisible(false)}
                        disabled={value == 0} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        gap: 20,
        maxHeight: 230
    },
}));


export default DisplacedModal;
