import React, { useContext, useState } from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import MyAppText from '../../MyAppText';
import { faLocationDot, faPlay, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import OccurrenceVideoModal from './OccurrenceVideoModal';
import { translate } from '../../../services/translate';
import { dispatchService } from '../../../services/central-api/dispatch';

export default function SceneChangeDetection({
    occurrence, navigation
}: {
    occurrence: Occurrence;
    navigation: Navigation;
}) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [isModalVisible, setModalVisible] = useState(false);

    if (occurrence.triggerType != 'defocus' && occurrence.triggerType != 'scenechangedetection') {
        return <></>;
    }

    return (
        <ScrollView >
            <View style={{ gap: 10 }}>
                <MyAppText style={styles.nameText}>
                    {occurrence.resume.serialNo}
                </MyAppText>
                <View style={{ gap: 5 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                        <FontAwesomeIcon icon={faPlay} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                        <TouchableOpacity onPress={() => setModalVisible(true)}>
                            <MyAppText style={[styles.detectionInfoText, { textDecorationLine: 'underline' }]}>
                                {translate('watchVideo')}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                        <FontAwesomeIcon icon={faVideo} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('CameraPlayer', { id: occurrence.externalReference?.id });
                        }}>
                            <MyAppText style={styles.detectionInfoText}>
                                {occurrence.externalReference?.title}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }}>
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(new Date(occurrence.resume.time)).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }}>
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {occurrence.externalReference?.address}
                        </MyAppText>
                    </View>

                </View>
            </View>
            <OccurrenceVideoModal
                occurrenceId={occurrence.id || 0}
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                occurrenceTime={new Date(occurrence.resume.time).getTime()}
                apiService={dispatchService}
            />
        </ScrollView>
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        nameText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 20
        },
        detectionInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 16
        },
    });
}
