import React, { useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { dispatchService } from '../../../services/central-api/dispatch';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import ActingBodiesDropdown from './ActingBodiesDropdown';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    vehicleRelation?: VehicleRelation;
    authenticatedUser?: AuthenticatedUser;
}

export default function PersonRelationsModal({ onClose, vehicleRelation, authenticatedUser, isModalVisible, setModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState(vehicleRelation?.name || '');
    const [actingBodyId, setActingBodyId] = useState<number>(0);

    function clearValues() {
        setName('');
        setActingBodyId(0);
    }

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    useEffect(() => {
        if (!vehicleRelation) {
            return;
        }
        setName(vehicleRelation.name);
        setActingBodyId(vehicleRelation.actingBodyId);
    }, [vehicleRelation]);

    async function formSubmit() {
        try {
            if (vehicleRelation?.id) {
                await dispatchService.updateVehicleRelation({
                    id: vehicleRelation.id,
                    name,
                });
            } else {
                await dispatchService.createVehicleRelation({
                    name,
                    actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined
                });
            }

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            clearValues();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
                    {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_vehicle_relations) &&
                        <ActingBodiesDropdown includeAllOption={false} value={actingBodyId} setValue={setActingBodyId} zIndex={2} disabled={!!vehicleRelation?.id} />
                    }
                    <FormInput
                        label={translate('name')}
                        placeholder={translate('name')}
                        value={name}
                        onChangeText={setName}
                        invalid={isNameInvalid}
                    />
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
