import React, { useContext, useState } from 'react';
import { View, StyleSheet, Switch } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { licensePlateRecognitionService } from '../../services/central-api/license-plate-recognition';
import { WindowInformation } from '../../services/window-information';
import MyAppText from '../../components/MyAppText';

const regPlate = new RegExp(/[A-Z]{3}[-]?[0-9][0-9A-Z][0-9]{2}/);

export default function PlatesModal({ onClose, plateData }: { onClose: () => void, plateData?: PlateData; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [plate, setPlate] = useState(plateData?.plate || '');
    const [description, setDescription] = useState(plateData?.description || '');
    const [isActive, setIsActive] = useState(true);

    function isPlateInvalid(value: string) {
        return value == '' || value.length > 7 || !regPlate.test(value);
    }

    function isDescriptionInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isPlateInvalid(plate) && !isDescriptionInvalid(description);
    }

    async function formSubmit() {
        try {
            if (plateData?.id) {
                await licensePlateRecognitionService.updatePlate(plateData.id, {
                    description,
                    isActive
                });
                onClose();
                return;
            }

            await licensePlateRecognitionService.createPlate({
                plate,
                description,
                isActive
            });
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
            <View style={{ rowGap: 25 }}>
                <FormInput
                    label={translate('plate')}
                    placeholder={translate('plate')}
                    value={plate}
                    onChangeText={(value) => {
                        if (value.length > 7) {
                            return;
                        }
                        setPlate(value.toUpperCase());
                    }}
                    invalid={isPlateInvalid}
                    disabled={plateData?.id ? true : false}
                />
                <FormInput
                    label={translate('description')}
                    placeholder={translate('description')}
                    value={description}
                    onChangeText={setDescription}
                    invalid={isDescriptionInvalid}
                />
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    <MyAppText style={{
                        color: getThemedColor(theme, '#58595B'),
                        fontSize: 16,
                    }}>
                        {translate('active')}
                    </MyAppText>
                    <Switch
                        trackColor={{ false: '#767577', true: '#81b0ff' }}
                        thumbColor={isActive ? '#f5dd4b' : '#f4f3f4'}
                        onValueChange={(value) => {
                            setIsActive(value);
                        }}
                        value={isActive}
                    />
                </View>
            </View>
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            minWidth: 300,
            minHeight: 200,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            rowGap: 25
        },
    });
}
