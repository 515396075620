import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '../../../services/translate';
import { faPlus, faEdit, faShield } from '@fortawesome/free-solid-svg-icons';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import MyAppText from '../../../components/MyAppText';
import moment from 'moment';
import { Pagination } from '../../../components/Pagination';
import { dispatchService } from '../../../services/central-api/dispatch';
import MyDataTable from '../../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import { TextMask } from 'react-native-masked-text';
import useFilters from '../../../components/Filter/hooks/useFilters';
import OccurrencesFilter from './Filters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import CustomButton from '../../../components/CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import getBestContrastColor from '../../../services/best-contrast-color';

export default function Occurrences({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(stylesheet);
    const route = useRoute<RouteProp<DispatchParamList, 'Occurrences'>>();

    const [occurrences, setOccurrences] = useState<PaginatedOccurrence[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState(0);

    const [filters, setFilters] = useFilters<DispatchParamList['Occurrences']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    async function getOccurrences(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getOccurrences({
                ...filters
            });
            setOccurrences(response.rows);
            setTotalItems(response.count);

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOccurrences();
    }, [filters]);

    function getTitle(occurrence: PaginatedOccurrence) {
        if (occurrence.triggerType == null && occurrence.occurrenceType) {
            return occurrence.occurrenceType.name;
        } else if (occurrence.triggerType == 'face_detected') {
            return translate('faceDetectedAlert');
        } else if (occurrence.triggerType == 'mp_face_detected') {
            return translate('mpFaceDetectedAlert');
        } else if (occurrence.triggerType == 'plate_detected') {
            return translate('plateDetectedAlert');
        } else if (occurrence.triggerType == 'mp_plate_detected') {
            return translate('mpPlateDetectedAlert');
        } else if (occurrence.triggerType == 'irregular_vehicle') {
            return translate('irregular_vehicle');
        } else if (occurrence.triggerType == 'area_invasion') {
            return translate('area_invasion');
        } else if (occurrence.triggerType == 'alarm_center') {
            return translate('alarmCenterAlert');
        } else if (occurrence.triggerType == 'guardian_app') {
            return translate('guardianAppAlert');
        } else if (['scenechangedetection', 'defocus'].includes(occurrence.triggerType || '')) {
            return translate('cameraDepredationSuspect');
        }
        return '';
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    const renderUnitContent = (row: PaginatedOccurrence) => {
        const renderUnitInfo = () => {
            if (!row.OccurrenceUnits || row.OccurrenceUnits.length === 0) return null;
            return row.OccurrenceUnits.map((unit, i) => {

                return (
                    <View key={i} style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                        <FontAwesomeIcon icon={faShield} fontSize={16} color={'#888888'} />
                        <MyAppText>{unit.unitName.name}</MyAppText> / <MyAppText>{unit.actingBodyUnit.name}</MyAppText>
                    </View>
                );
            });
        };

        return (
            <View style={{ paddingVertical: 10, paddingLeft: 50, flexDirection: 'column', rowGap: 10 }}>
                {renderUnitInfo()}
            </View>
        );
    };

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='occurrences' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                            <FilterInput
                                label={translate('searchOccurrence')}
                                value={textFilter ?? ''}
                                onChange={text => {
                                    setTextFilter(text);
                                }}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}

                            />
                        </View>

                        <View style={{ flex: 1 }}></View>

                        <View style={styles.buttonsContainer}>
                            <OccurrencesFilter
                                authenticatedUser={authenticatedUser}
                                filters={filters}
                                setFilters={setFilters}
                                textFilter={textFilter}
                                setTextFilter={setTextFilter} />
                            <CustomButton
                                onPress={() => navigation.navigate('DispatchOccurrenceDetail')}
                                text={translate('create')}
                                icon={faPlus}
                            />
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyDataTable
                                columns={[
                                    {
                                        name: translate('event'),
                                        selector: row => row.id || '',
                                        grow: 0,
                                    },
                                    {
                                        name: translate('requester'),
                                        selector: row => row.triggerType == null && row.requester ? row.requester : '',
                                        wrap: true,
                                        grow: 2
                                    },
                                    {
                                        name: translate('phone'),
                                        cell: row => <View style={{ width: '100%' }}>
                                            <TextMask
                                                style={{ color: theme.colors.textColor, fontFamily: 'Open Sans' }}
                                                type={'cel-phone'}
                                                value={row.triggerType == null && row.phone ? row.phone : ''} />
                                        </View>,
                                    },
                                    {
                                        name: translate('type'),
                                        wrap: true,
                                        grow: 2,
                                        selector: row => getTitle(row),
                                    },
                                    {
                                        name: translate('priority'),
                                        wrap: true,
                                        cell: row => <MyAppText style={{ color: row.priority === 'maximum' ? '#FF0027' : undefined }}>
                                            {row.priority ? translate(row.priority) : ''}
                                        </MyAppText>,
                                    },
                                    {
                                        name: translate('start_date'),
                                        selector: row => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '',
                                        wrap: true
                                    },
                                    {
                                        name: translate('end_date'),
                                        selector: row => row?.finishedAt ? moment(row?.finishedAt).format('DD/MM/YYYY HH:mm:ss') : '',
                                        wrap: true
                                    },
                                    {
                                        name: translate('status'),
                                        cell: row =>
                                            <View style={styles.status}>
                                                <View style={[styles.circle, (row.situation == 'concluded' ? styles.red : styles.green)]} />
                                                <MyAppText>{translate(row.situation)}</MyAppText>
                                            </View>
                                    },
                                    {
                                        name: translate('tags'),
                                        cell: row =>
                                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5, paddingVertical: 5 }}>
                                                {row.tags ?
                                                    row.tags.map((tag) =>
                                                        <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                            <MyAppText style={styles.markerText(tag.color)}>
                                                                {tag.name}
                                                            </MyAppText>
                                                        </View>
                                                    ) : null
                                                }</View>
                                    },
                                    {
                                        name: translate('action'),
                                        button: true,
                                        cell: row =>
                                            <TouchableOpacity onPress={() => navigation.navigate('DispatchOccurrenceDetail', { occurrenceId: row.id })}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.dataTable.actionIcon} />
                                            </TouchableOpacity>
                                    }
                                ]}
                                data={occurrences}
                                expandableRowsComponent={({ data }) => renderUnitContent(data)}
                                expandableRows
                                progressPending={isLoading}
                            />
                        </View>
                        <Pagination
                            currentPage={filters.page}
                            totalItems={totalItems}
                            setPage={page => {
                                setFilters(old => ({
                                    ...old,
                                    page
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    page: page + 1
                                });
                            }}
                            pageSize={filters.limit}
                            setPageSize={pageSize => {
                                setFilters(old => ({
                                    ...old,
                                    limit: pageSize,
                                    page: 0,
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    limit: pageSize,
                                    page: 0,
                                });
                            }}
                        />
                    </View>

                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}
const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: 7.5,
    },
    red: {
        backgroundColor: 'red',
    },
    green: {
        backgroundColor: 'green',
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    status: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10,
        width: '100%'
    },
    marker: {
        borderRadius: 8,
        minHeight: 16,
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: '100%'
    },
    markerText: (tagColor: string) => {
        return {
            fontWeight: '500',
            color: getBestContrastColor(tagColor),
            fontSize: 11,
        };
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));
