import React from 'react';
import LateralMenu from '../LateralMenu';
import { faCalculator, faPlug, faScrewdriverWrench, faVideo } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_crm.png';
import collapsedLogo from '../../../assets/crm_icon.png';

export type SelectedMenu = 'cameras' | 'reports' | 'alarm-centers' | 'offline-events' | 'maintenance-requests';

interface CrmLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function CrmLateralMenu({ navigation, selectedMenu, open, authenticatedUser, setOpen }: CrmLateralMenuProps) {
    const items = [];

    items.push({
        icon: faVideo,
        text: translate('cameras'),
        action: () => navigation.navigate('CRM', { screen: 'Management' }),
        selected: selectedMenu == 'cameras'
    });
    items.push({
        icon: faCalculator,
        text: translate('alarmCenters'),
        action: () => navigation.navigate('CRM', { screen: 'AlarmCentersList' }),
        selected: selectedMenu == 'alarm-centers'
    });
    items.push({
        icon: faPlug,
        text: translate('offlineEvents'),
        action: () => navigation.navigate('CRM', { screen: 'OfflineEvents' }),
        selected: selectedMenu == 'offline-events'
    });
    items.push({
        icon: faScrewdriverWrench,
        text: translate('maintenanceRequests'),
        action: () => navigation.navigate('CRM', { screen: 'MaintenanceRequests' }),
        selected: selectedMenu == 'maintenance-requests'
    });

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={items}
            goBack={() => navigation.navigate('Menu')}
        />
    );
}
