import { BaseService, ClientError, Unauthorized, UnexpectedError } from './base-service';
import { MoreThanOneFaceOnPhoto, NoFaceOnPhoto } from './facial';

interface FindDetectionByPictureParams {
    photo: File;
    /**
     * a 0 to 1 percentage. You can use until 3 decimal points. Ex.: 0.714
     */
    threshold: number;
}

class SmartSearchFacialService extends BaseService {

    async gcmAgentFindPeopleByPicture(photo: File): Promise<{ people: FacialPerson[]; prev: string | null; next: string | null; }> {
        const token = await this.getToken();

        const formData = new FormData();
        formData.append('photo', photo);

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/find-by-picture/people`, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: formData
        });

        if (res.status == 400) {
            const resJson = await res.json();
            if (resJson.code == 'NO_FACE_ON_PHOTO') {
                throw new NoFaceOnPhoto();
            }
            if (resJson.code == 'MORE_THAN_ONE_FACE_ON_PHOTO') {
                throw new MoreThanOneFaceOnPhoto();
            }
            throw new ClientError(resJson.code);
        }

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findDetectionByPicture({ photo, threshold }: FindDetectionByPictureParams): Promise<{ detections: FacialDetection[]; next: string | null; }> {
        const token = await this.getToken();

        const formData = new FormData();
        formData.append('photo', photo);

        const res = await fetch(`${this.centralEndpoint}/find-by-picture/detections${this.encodeQueryParams({ threshold })}`, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: formData
        });

        if (res.status == 400) {
            const resJson = await res.json();
            if (resJson.code == 'NO_FACE_ON_PHOTO') {
                throw new NoFaceOnPhoto(resJson.code);
            }
            if (resJson.code == 'MORE_THAN_ONE_FACE_ON_PHOTO') {
                throw new MoreThanOneFaceOnPhoto(resJson.code);
            }
            throw new ClientError(resJson.code);
        }


        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }
}

export const smartSearchFacialService = new SmartSearchFacialService();
