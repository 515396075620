import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { TouchableOpacity, View } from 'react-native';
import MyAppText from './MyAppText';
import { Hoverable } from 'react-native-web-hover';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyDropDownPicker from './MyDropDownPicker';
import { translate } from '../services/translate';
import { WindowInformation } from '../services/window-information';

interface PaginationParameters {
    totalItems: number;
    currentPage: number;
    setPage: (value: number) => void;
    pageSize: number;
    setPageSize: (value: number) => void;
}

export function Pagination({ totalItems, currentPage, setPage, pageSize, setPageSize }: PaginationParameters) {
    const { styles, theme } = useStyles(stylesheet);
    const [isPaginationDropdownOpen, setIsPaginationDropdownOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const windowInfo = WindowInformation();

    useEffect(() => {
        if (totalItems == 0) {
            return;
        }
        setTotalPages(Math.ceil(totalItems / pageSize));
    }, [totalItems, pageSize]);

    const items = [{
        label: '25',
        value: 25,
    }, {
        label: '100',
        value: 100,
    }];

    if (!items.find(i => i.value == pageSize)) {
        items.push({
            label: String(pageSize),
            value: pageSize,
        });
        items.sort((a, b) => a.value - b.value);
    }

    return (<View style={styles.content}>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
        }}>
            {windowInfo.isMobile ?
                <MyAppText>
                    {totalItems}{' '}{translate('items')}
                </MyAppText>
                :
                <><MyAppText>{translate('itemsPerPage')}</MyAppText>
                    <View>
                        <MyDropDownPicker
                            multiple={false}
                            items={items}
                            open={isPaginationDropdownOpen}
                            setOpen={setIsPaginationDropdownOpen}
                            searchable={false}
                            value={pageSize}
                            setValue={(value) => {
                                setPageSize(value(-1));
                            }}
                        />
                    </View>
                    <MyAppText>
                        {totalItems == 0 ? 0 : (currentPage * pageSize) + 1}{'-'}{Math.min(
                            (currentPage + 1) * pageSize,
                            totalItems,
                        )}{' '}{translate('of')}{' '}{totalItems}{' '}{translate('items')}
                    </MyAppText>
                </>}
        </View>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
        }}>
            <MyAppText>{currentPage + 1}{' '}{translate('of')}{' '}{totalPages}</MyAppText>
            <Hoverable>
                {({ hovered }) => (
                    <TouchableOpacity
                        style={styles.arrow(currentPage <= 0, hovered)}
                        onPress={() => setPage(currentPage <= 0 ? currentPage : currentPage - 1)}
                        disabled={currentPage <= 0}
                    >
                        <FontAwesomeIcon color={currentPage <= 0
                            ? theme.colors.paginationBar.arrowButton.disabledIcon
                            : theme.colors.paginationBar.arrowButton.icon} fontSize={18} icon={faAngleLeft} />
                    </TouchableOpacity>)
                }
            </Hoverable>
            <Hoverable>
                {({ hovered }) => (
                    <TouchableOpacity
                        style={styles.arrow(currentPage + 1 >= totalPages, hovered)}
                        onPress={() => setPage(currentPage + 1 >= totalPages ? currentPage : currentPage + 1)}
                        disabled={currentPage + 1 >= totalPages}
                    >
                        <FontAwesomeIcon color={currentPage + 1 >= totalPages
                            ? theme.colors.paginationBar.arrowButton.disabledIcon
                            : theme.colors.paginationBar.arrowButton.icon} fontSize={18} icon={faAngleRight} />
                    </TouchableOpacity>
                )}
            </Hoverable>
        </View>
    </View >);

}

const stylesheet = createStyleSheet(theme => ({
    content: {
        flexDirection: 'row',
        paddingHorizontal: 25,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        paddingVertical: 5,
        gap: 10,
        borderWidth: 1,
        borderTopWidth: 0,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderColor: theme.colors.paginationBar.border,
        backgroundColor: theme.colors.paginationBar.background,
    },
    arrow: (disabled: boolean, hovered: boolean) => ({
        height: 40,
        width: 60,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: theme.colors.paginationBar.arrowButton.border,
        justifyContent: 'center',
        backgroundColor: !disabled && hovered ? theme.colors.paginationBar.arrowButton.hoverBackground : undefined
    }),
    number: {
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        borderBottomWidth: 0
    }
}));
