import React, { Dispatch, SetStateAction } from 'react';
import { View } from 'react-native';
import TimePicker from 'react-time-picker';
import { UnistylesRuntime } from 'react-native-unistyles';
import DatePicker from 'react-date-picker';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../MyAppText';

interface MyDateTimeInputProps {
    label: string;
    date: CalendarValue;
    onChangeDate: Dispatch<SetStateAction<CalendarValue>>;
    time: string;
    onChangeTime: (value: string) => void;
    editable?: boolean;
    maxDate?: Date;
    zIndex?: number;
}

type ValuePiece = Date | null;
export type CalendarValue = ValuePiece;

export default function FilterDatePicker({ label, date, time, onChangeDate, onChangeTime, maxDate, zIndex }: MyDateTimeInputProps) {
    const { styles, theme } = useStyles(styleSheet);

    return (
        <View style={[{ rowGap: 5, flex: 1 }, zIndex ? { zIndex: zIndex } : {}]}>
            <MyAppText style={styles.filterLabel}>{label}</MyAppText>
            <View style={{ flexDirection: 'row', columnGap: 5, flex: 1 }}>
                <View style={{ flexGrow: 2 }}>
                    <DatePicker
                        calendarProps={{ locale: 'pt-BR' }}
                        maxDate={maxDate}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onChange={onChangeDate}
                        autoFocus={false}
                        shouldOpenCalendar={({ reason }) => reason !== 'focus'}
                        value={date}
                        clearIcon={null}
                        locale='pt-BR'
                        className={UnistylesRuntime.themeName === 'light' ? ['inputColor', `inputColorLight`] : ['inputColor', `inputColorDark`]}
                        calendarIcon={<FontAwesomeIcon icon={faCalendar} color={theme.colors.iconColor}></FontAwesomeIcon>}
                    />
                </View>
                <TimePicker
                    disableClock={true}
                    format='HH:mm'
                    locale='pt-BR'
                    maxDetail='minute'
                    className={UnistylesRuntime.themeName === 'light' ? ['inputColor', `inputColorLight`] : ['inputColor', `inputColorDark`]}
                    value={time}
                    clearIcon={null}
                    onChange={(value) => {
                        if (value) {
                            onChangeTime(value);
                        }
                    }}
                />
            </View>
        </View>
    );
}


const styleSheet = createStyleSheet((theme) => ({
    filterLabel: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
}));
