import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { WindowInformation } from '../services/window-information';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const headerHeight = 64;

interface ContentContainerProps {
    children: React.ReactNode | undefined;
    style?: StyleProp<ViewStyle>;
}

export default function ContentContainer(props: ContentContainerProps) {
    const windowInfo = WindowInformation();
    const insets = useSafeAreaInsets();

    return (
        <View
            style={[{
                width: '100%',
                height: windowInfo.height - headerHeight - insets.top - insets.bottom,
            }, props.style]}
        >
            {props.children}
        </View>
    );
}
