import React from 'react';
import LateralMenu from '../LateralMenu';
import { faBriefcase, faClipboardList, faFolderOpen, faHomeLg, faListAlt, faShield, faStar, faUserGear, faTag, faCube, faCar, faUser, faUsers, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_dispatch.png';
import collapsedLogo from '../../../assets/dispatch_icon.png';

export type SelectedMenu = 'resume' | 'occurrences' | 'equipment' | 'occurrence_types' | 'reports' | 'skills' | 'units' | 'assignments' | 'occurrence_tags' | 'report_tags' | 'objects_categories' | 'vehicle_relation' | 'person_relation' | 'team_names' | 'finish_categories';

interface DispatchLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}

export default function DispatchLateralMenu({ navigation, selectedMenu, authenticatedUser, open, setOpen }: DispatchLateralMenuProps) {
    const items = [];

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_module_access) {
        items.push({
            icon: faHomeLg,
            text: translate('general_vision'),
            action: () => navigation.navigate('Dispatch', { screen: 'Resume' }),
            selected: selectedMenu == 'resume'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_module_access) {
        items.push({
            icon: faClipboardList,
            text: translate('occurrences'),
            action: () => navigation.navigate('Dispatch', { screen: 'Occurrences' }),
            selected: selectedMenu == 'occurrences'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_occurrence_tags) {
        items.push({
            icon: faTag,
            text: translate('occurrenceTags'),
            action: () => navigation.navigate('Dispatch', { screen: 'OccurrenceTags' }),
            selected: selectedMenu == 'occurrence_tags'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_equipments) {
        items.push({
            icon: faBriefcase,
            text: translate('equipments'),
            action: () => navigation.navigate('Dispatch', { screen: 'Equipments' }),
            selected: selectedMenu == 'equipment'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_module_access) {
        items.push({
            icon: faListAlt,
            text: translate('occurrence_types'),
            action: () => navigation.navigate('Dispatch', { screen: 'OccurrenceTypes' }),
            selected: selectedMenu == 'occurrence_types'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_module_access) {
        items.push({
            icon: faFolderOpen,
            text: translate('reports'),
            action: () => navigation.navigate('Dispatch', { screen: 'Reports' }),
            selected: selectedMenu == 'reports'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_report_tags) {
        items.push({
            icon: faTag,
            text: translate('reportTags'),
            action: () => navigation.navigate('Dispatch', { screen: 'ReportTags' }),
            selected: selectedMenu == 'report_tags'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_module_access) {
        items.push({
            icon: faStar,
            text: translate('skills'),
            action: () => navigation.navigate('Dispatch', { screen: 'Skills' }),
            selected: selectedMenu == 'skills'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_module_access) {
        items.push({
            icon: faUserGear,
            text: translate('assignments'),
            action: () => navigation.navigate('Dispatch', { screen: 'Assignments' }),
            selected: selectedMenu == 'assignments'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_vehicle_relations) {
        items.push({
            icon: faCar,
            text: translate('vehicleRelations'),
            action: () => navigation.navigate('Dispatch', { screen: 'VehicleRelations' }),
            selected: selectedMenu == 'vehicle_relation'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_person_relations) {
        items.push({
            icon: faUser,
            text: translate('personRelations'),
            action: () => navigation.navigate('Dispatch', { screen: 'PersonRelations' }),
            selected: selectedMenu == 'person_relation'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_objects_categories) {
        items.push({
            icon: faCube,
            text: translate('objectsCategories'),
            action: () => navigation.navigate('Dispatch', { screen: 'ObjectsCategories' }),
            selected: selectedMenu == 'objects_categories'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_team_names) {
        items.push({
            icon: faUsers,
            text: translate('teamNames'),
            action: () => navigation.navigate('Dispatch', { screen: 'TeamNames' }),
            selected: selectedMenu == 'team_names'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_units || authenticatedUser?.permissions.dispatch_manage_acting_body_units) {
        items.push({
            icon: faShield,
            text: translate('teams'),
            action: () => navigation.navigate('Dispatch', { screen: 'Units' }),
            selected: selectedMenu == 'units'
        });
    }
    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_finish_categories) {
        items.push({
            icon: faFlagCheckered,
            text: translate('finishCategories'),
            action: () => navigation.navigate('Dispatch', { screen: 'FinishCategories' }),
            selected: selectedMenu == 'finish_categories'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            items={items}
            authenticatedUser={authenticatedUser}
            goBack={() => navigation.navigate('Menu')}
        />
    );
}
