import React from 'react';
import LateralMenu from '../LateralMenu';
import { faGear, faKey, faLock } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';

export type SelectedMenu = 'account' | 'password' | 'sessions';

interface UserLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function UserLateralMenu({ navigation, selectedMenu, open, authenticatedUser, setOpen }: UserLateralMenuProps) {
    return (
        <LateralMenu
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faGear,
                text: translate('account'),
                action: () => navigation.navigate('User', { screen: 'Account' }),
                selected: selectedMenu == 'account'
            }, {
                icon: faKey,
                text: translate('changePassword'),
                action: () => navigation.navigate('User', { screen: 'Password' }),
                selected: selectedMenu == 'password'
            }, {
                icon: faLock,
                text: translate('sessions'),
                action: () => navigation.navigate('User', { screen: 'Sessions' }),
                selected: selectedMenu == 'sessions'
            }]}
            goBack={() => navigation.navigate('Menu')}
        />
    );
}
