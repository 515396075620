import React from 'react';
import '../styles/tooltips.css';

export default function Tooltip(props: {
    tooltipContent: string | React.ReactNode;
    tooltipDirection: 'right' | 'left';
    children: React.ReactNode;
    width?: number;
}) {
    const width = props.width || 120;

    return (
        <div className='tooltip'>
            {props.children}
            {props.tooltipDirection == 'left' ?
                <span className='tooltip-text-left' style={{ width }}>{props.tooltipContent}</span>
                : <span className='tooltip-text-right' style={{ width }}>{props.tooltipContent}</span>
            }
        </div>
    );
}
