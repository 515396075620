import React, { useEffect, useState } from 'react';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';
import { accessService } from '../../../services/central-api/access';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    actingBodyId: number | null;
    disabled: boolean;
    zIndex: number;
}

function ActingBodiesUnitDropdown({ value, setValue, actingBodyId, disabled, zIndex }: Props) {
    const [selectableActingBodyUnits, setSelectableActingBodyUnits] = useState<{ label: string, value: number; }[]>([]);

    async function getActingBodyUnits({ actingBodyId }: { actingBodyId: number; }) {
        try {
            const actingBody = await accessService.getActingBody(actingBodyId);

            if (!actingBody.actingBodyUnits) {
                setSelectableActingBodyUnits([]);
            } else {
                setSelectableActingBodyUnits([
                    ...actingBody.actingBodyUnits.map((role) => {
                        return {
                            value: role.id || 0,
                            label: role.name
                        };
                    })
                ]);
            }
        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        if (actingBodyId) {
            getActingBodyUnits({ actingBodyId });
        }
    }, [actingBodyId]);

    return (
        <FilterDropdown
            label={translate('unit')}
            items={selectableActingBodyUnits}
            setValue={setValue}
            value={value}
            disabled={disabled}
            zIndex={zIndex}
        />
    );
}

export default ActingBodiesUnitDropdown;
