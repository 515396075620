import React, { useState } from 'react';
import { Pressable } from 'react-native';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { ScrollView } from 'react-native-web-hover';
import WarrantMoreInformationModal from './WarrantMoreInformationModal';

interface Params {
    isLoading: boolean;
    warrantInformation: BasicWarrantInformation[];
}

export default function WarrantContext({ isLoading, warrantInformation }: Params) {
    const { styles } = useStyles(styleSheet);

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedWarrantId, setSelectedWarrantId] = useState<number>(0);

    function clearValue() {
        setSelectedWarrantId(0);
    }

    return (
        <>
            <ScrollView contentContainerStyle={{ gap: 5 }}>
                {!isLoading && warrantInformation.map(warrant => (
                    <Pressable key={warrant.idpessoa} style={styles.cardBody} onPress={() => {
                        setSelectedWarrantId(warrant.idpessoa);
                        setIsModalVisible(true);
                    }}>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('nickname')}:</MyAppText> {warrant.alcunha}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('birth')}:</MyAppText> {warrant.dataNascimento}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('id')}:</MyAppText> {warrant.idpessoa}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('confidence')}:</MyAppText> {warrant.indiceAssertividade}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('birthCity')}:</MyAppText> {warrant.naturalidade}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('name')}:</MyAppText> {warrant.nome}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('motherName')}:</MyAppText> {warrant.nomeMae}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('fatherName')}:</MyAppText> {warrant.nomePai}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('gender')}:</MyAppText> {warrant.sexo}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('status')}:</MyAppText> {warrant.statusPessoa}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('type')}:</MyAppText> {warrant.tipoBuscaCPF}</MyAppText>
                    </Pressable>
                ))}
            </ScrollView>

            <WarrantMoreInformationModal
                isModalVisible={isModalVisible}
                setModalVisible={setIsModalVisible}
                warrantId={selectedWarrantId}
                onClose={clearValue}
            />
        </>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    cardBody: {
        gap: 10,
        backgroundColor: theme.colors.backgroundColor,
        padding: 10,
        borderColor: theme.colors.primaryButton.disabledBackground,
        borderWidth: 1,
        borderRadius: 4,
    }
}));
