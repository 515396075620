import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../../services/central-api/dispatch';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
}

function UnitsDropdown({ value, setValue, zIndex }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);

    async function getActiveUnits() {
        try {
            const response = await dispatchService.getActiveUnits();
            setItems(response.map(unit => ({
                value: unit.id,
                label: unit.unitName.name
            })));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getActiveUnits();
    }, []);

    return (
        <FilterDropdown
            label={translate('teams')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default UnitsDropdown;
