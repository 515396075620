import React, { Dispatch, SetStateAction } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormActions from '../../../components/formActions';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import CustomTextInput from '../../../components/CustomTextInput';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
    reason: string;
    setReason: Dispatch<SetStateAction<string>>;
}

export default function FinishEarlyModal({ onClose, reason, setReason, isModalVisible, setModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
            <View style={styles.container}>
                <View style={styles.formContainer}>
                    <MyAppText>{translate('earlyFinishAgentReason')}:</MyAppText>
                    <CustomTextInput
                        placeholder={'reason'}
                        multiline={true}
                        value={reason}
                        onChangeText={setReason} />
                    <FormActions
                        onSubmit={() => {
                            onClose();
                            setModalVisible(false);
                        }}
                        onClose={() => {
                            setModalVisible(false);
                            setReason('');
                        }}
                        disabled={!reason} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
