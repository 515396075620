import React, { useContext } from 'react';
import { View, TouchableOpacity, StyleSheet, ViewStyle, StyleProp, ActivityIndicator } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';

interface FormInputProps {
    onSubmit: () => void,
    onClose: () => void,
    disabled: boolean;
    cancelText?: string;
    confirmText?: string;
    style?: StyleProp<ViewStyle>;
    isLoading?: boolean;
}

export default function FormActions({ onSubmit, onClose, disabled, style, cancelText = 'cancel', confirmText = 'save', isLoading }: FormInputProps) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    return (
        <View style={[styles.container, style]}>
            <TouchableOpacity style={[styles.button, styles.buttonClose]}
                onPress={() => onClose()}>
                <MyAppText style={[styles.buttonTextClose]}>{translate(cancelText)}</MyAppText>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.button, styles.buttonSave, disabled || isLoading ? styles.disabled : null]}
                onPress={onSubmit}
                disabled={disabled || isLoading}>
                {isLoading ? <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} /> : <MyAppText style={[styles.buttonTextSave, disabled ? styles.textDisabled : null]}>{translate(confirmText)}</MyAppText>}
            </TouchableOpacity>
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexDirection: 'row',
            columnGap: 15,
            zIndex: -30
        },
        button: {
            borderRadius: 4,
            borderWidth: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 120,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonSave: {
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonTextSave: {
            fontSize: 16,
            color: getThemedColor(theme, '#FFFFFF'),
        },
        buttonClose: {
            borderColor: getThemedColor(theme, '#CCCCCC'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
        },
        buttonTextClose: {
            fontSize: 16,
            color: getThemedColor(theme, '#58595B'),
        },
        disabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        }
    });
}
