import React, { useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Toast from 'react-native-toast-message';
import moment from 'moment';
import MyAppText from '../../../components/MyAppText';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import 'react-calendar/dist/Calendar.css';
import '../../../styles/date-ranger-picker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import MyDatetimeInput from '../../../components/MyDatetimeInput';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
}

const regex = /^[a-zA-Z0-9 +\-./:=_ãõẽíáàâêóôúüçÃÕẼÍÁÀÂÊÓÔÚÜÇ]*$/;

export default function ReportModal({ onClose, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState<string>('');
    const [plate, setPlate] = useState<string>('');
    const [startInDateFilter, setStartInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [startInHourFilter, setStartInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm:ss'));
    const [endInDateFilter, setEndInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [endInHourFilter, setEndInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm:ss'));
    const [isCameraPickerOpen, setIsCameraPickerOpen] = useState(false);
    const [selectedCameraFilter, setSelectedCameraFilter] = useState<string[]>([]);
    const [selectableCameraFilter, setSelectableCameraFilter] = useState<{ label: string, value: string; }[]>([]);

    function isNameInvalid(value: string) {
        return value == '' || !regex.test(value);
    }

    function isFormValid() {
        return !isNameInvalid(name) &&
            moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf() <= moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf()
            && selectedCameraFilter.length > 0;
    }

    function onCloseNewReportModal() {
        setModalVisible(false);
        resetReportForm();
    }

    async function formSubmit() {
        try {
            await licensePlateRecognitionService.createReport({
                name: name,
                startDate: moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').toISOString(),
                endDate: moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').toISOString(),
                plate: plate,
                camerasIds: selectedCameraFilter
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (error) {
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            onCloseNewReportModal();
            onClose();
        }
    }

    function resetReportForm() {
        setName('');
        setSelectableCameraFilter([]);
        setSelectedCameraFilter([]);
    }

    async function getCameras() {
        try {
            const cameras = await licensePlateRecognitionService.getCameras();
            setSelectableCameraFilter(cameras.map((camera) => {
                return {
                    value: camera.id,
                    label: camera.title
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (isModalVisible) {
            getCameras();
        }
    }, [isModalVisible]);

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => onCloseNewReportModal()} >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 400 }]}>
                    <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: theme.colors.labelColor }}>{translate('detectionsReport')}</MyAppText>
                    <FormInput
                        label={translate('name')}
                        value={name}
                        onChangeText={setName}
                        invalid={isNameInvalid}
                        errorMessage={translate('invalidCharacters')}
                    />
                    <FormInput label={translate('plate')}
                        invalid={() => false}
                        value={plate}
                        onChangeText={setPlate}
                    />
                    <MyDatetimeInput
                        label={translate('beginIn')}
                        date={startInDateFilter}
                        onChangeDate={setStartInDateFilter}
                        time={startInHourFilter}
                        onChangeTime={setStartInHourFilter} />
                    <MyDatetimeInput
                        label={translate('endIn')}
                        date={endInDateFilter}
                        onChangeDate={setEndInDateFilter}
                        time={endInHourFilter}
                        onChangeTime={setEndInHourFilter} />
                    <View style={{ zIndex: 3 }}>
                        <MyAppText style={styles.filterText}>{translate('cameras')}</MyAppText>
                        <MyDropDownPicker
                            open={isCameraPickerOpen}
                            value={selectedCameraFilter}
                            items={selectableCameraFilter}
                            setOpen={setIsCameraPickerOpen}
                            setValue={setSelectedCameraFilter}
                            multiple={true}
                            mode='BADGE'
                            searchable={true}
                            borderColor={theme.colors.borderColor}
                        />
                    </View>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => onCloseNewReportModal()}
                        disabled={!isFormValid()}
                        confirmText={'download'} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 15,
        maxHeight: 690
    },
    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
}));
