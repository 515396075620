import React, { useEffect, useRef, useState } from 'react';
import { View, StyleProp, ViewStyle, Animated, Dimensions } from 'react-native';
import { WindowInformation } from '../services/window-information';
import FacialLateralMenu, { SelectedMenu as FacialSelectedMenu } from './facial/FacialLateralMenu';
import CamerasLateralMenu, { SelectedMenu as CamerasSelectedMenu } from './cameras/CamerasLateralMenu';
import LprLateralMenu, { SelectedMenu as LprSelectedMenu } from './lpr/LprLateralMenu';
import DispatchLateralMenu, { SelectedMenu as DispatchSelectedMenu } from './dispatch/DispatchLateralMenu';
import GuardianAppLateralMenu, { SelectedMenu as GuardianAppSelectedMenu } from './guardian-app/GuardianAppLateralMenu';
import AccessLateralMenu, { SelectedMenu as AccessSelectedMenu } from './access/AccessLateralMenu';
import EventsLateralMenu, { SelectedMenu as EventsSelectedMenu } from './events/EventsLateralMenu';
import ContentContainer from './ContentContainer';
import GcmAgentLateralMenu, { SelectedMenu as GcmAgentSelectedMenu } from './gcm-agent/GcmAgentLateralMenu';
import DashboardLateralMenu, { SelectedMenu as DashboardSelectedMenu } from './dashboard/DashboardLateralMenu';
import LogsLateralMenu, { SelectedMenu as LogsSelectedMenu } from './logs/LogsLateralMenu';
import CrmLateralMenu, { SelectedMenu as CrmSelectedMenu } from './crm/CrmLateralMenu';
import SmartSearchFacialLateralMenu, { SelectedMenu as SmartSearchFacialSelectedMenu } from './smart-search-facial/SmartSearchFacialLateralMenu';
import UserLateralMenu, { SelectedMenu as UserSelectedMenu } from './user/UserLateralMenu';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import TopMenu from './TopMenu';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type DefaultPageLayoutProps = {
    navigation: Navigation;
    children: React.ReactNode | undefined;
    contentContainerStyle?: StyleProp<ViewStyle>;
    authenticatedUser?: AuthenticatedUser;
} & ({
    lateralMenu: 'facial';
    selectedMenu: FacialSelectedMenu;
} | {
    lateralMenu: 'cameras';
    selectedMenu: CamerasSelectedMenu;
} | {
    lateralMenu: 'lpr';
    selectedMenu: LprSelectedMenu;
} | {
    lateralMenu: 'dispatch';
    selectedMenu: DispatchSelectedMenu;
} | {
    lateralMenu: 'access';
    selectedMenu: AccessSelectedMenu;
} | {
    lateralMenu: 'events';
    selectedMenu: EventsSelectedMenu;
} | {
    lateralMenu: 'gcmAgent';
    selectedMenu: GcmAgentSelectedMenu;
} | {
    lateralMenu: 'dashboard';
    selectedMenu: DashboardSelectedMenu;
} | {
    lateralMenu: 'logs';
    selectedMenu: LogsSelectedMenu;
} | {
    lateralMenu: 'smartSearchFacial';
    selectedMenu: SmartSearchFacialSelectedMenu;
} | {
    lateralMenu: 'crm';
    selectedMenu: CrmSelectedMenu;
} | {
    lateralMenu: 'user';
    selectedMenu: UserSelectedMenu;
} | {
    lateralMenu: 'guardianApp';
    selectedMenu: GuardianAppSelectedMenu;
});

export type SystemModules = DefaultPageLayoutProps['lateralMenu'];

const screenWidth = Dimensions.get('window').width;

export default function DefaultPageLayout({ children, lateralMenu, navigation, selectedMenu, authenticatedUser, contentContainerStyle }: DefaultPageLayoutProps) {
    const { styles } = useStyles(stylesheet);
    const insets = useSafeAreaInsets();
    const windowInfo = WindowInformation();

    const [showLateralMenu, setShowLateralMenu] = useState<boolean>(localStorage.getItem('HAMBURGER_OPEN') == 'true');

    const animationOpacity = useRef(new Animated.Value(0)).current;
    const animationTranslateX = useRef(new Animated.Value(-screenWidth)).current;

    useEffect(() => {
        Animated.timing(animationOpacity, {
            toValue: showLateralMenu ? 0.6 : 0,
            duration: 200,
            useNativeDriver: true
        }).start();

        Animated.timing(animationTranslateX, {
            toValue: showLateralMenu ? 0 : -screenWidth,
            duration: 0,
            useNativeDriver: true
        }).start();
    }, [showLateralMenu]);

    useEffect(() => {
        if (windowInfo.isMobile) {
            setShowLateralMenu(!windowInfo.isMobile);
        } else {
            getStorage();
        }
    }, [windowInfo.isMobile]);

    useEffect(() => {
        if (!windowInfo.isMobile) {
            setStorage(showLateralMenu);
        }
    }, [showLateralMenu, windowInfo.isMobile]);

    async function getStorage() {
        try {
            const hamburgerOpen = await AsyncStorage.getItem('HAMBURGER_OPEN');
            if (hamburgerOpen) {
                setShowLateralMenu(JSON.parse(hamburgerOpen));
            }
        } catch (e) {
            console.error('Error on parse Storage: ', e);
        }
    }

    async function setStorage(showLateralMenu: boolean) {
        await AsyncStorage.setItem('HAMBURGER_OPEN', String(showLateralMenu));
    }

    return (
        <View style={styles.container}>
            <View style={styles.topBarContainer}>
                <TopMenu navigation={navigation} setOpen={setShowLateralMenu} />
            </View>

            <View style={{ flexDirection: 'row' }}>

                <View style={{ zIndex: 2, height: windowInfo.height - 64 - insets.top - insets.bottom }}>
                    {lateralMenu == 'facial' &&
                        <FacialLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'cameras' &&
                        <CamerasLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'lpr' &&
                        <LprLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'dispatch' &&
                        <DispatchLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'access' &&
                        <AccessLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'events' &&
                        <EventsLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'gcmAgent' &&
                        <GcmAgentLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'dashboard' &&
                        <DashboardLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'logs' &&
                        <LogsLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'smartSearchFacial' &&
                        <SmartSearchFacialLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'crm' &&
                        <CrmLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'user' &&
                        <UserLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}
                    {lateralMenu == 'guardianApp' &&
                        <GuardianAppLateralMenu navigation={navigation} authenticatedUser={authenticatedUser} selectedMenu={selectedMenu} open={showLateralMenu} setOpen={setShowLateralMenu} />}

                </View>

                <View style={styles.contentContainer}>
                    <ContentContainer style={contentContainerStyle}>
                        {children}
                    </ContentContainer>
                </View>

                {
                    windowInfo.isMobile &&
                    <Animated.View onTouchEnd={() => setShowLateralMenu(false)} style={{
                        position: 'absolute',
                        top: 0,
                        transform: [{
                            translateX: animationTranslateX
                        }],
                        height: '100%',
                        backgroundColor: '#313131',
                        opacity: animationOpacity,
                        width: '100%',
                    }}
                    />
                }
            </View>


        </View >
    );
}


const stylesheet = createStyleSheet(() => ({
    container: {
        flex: 1,
        flexDirection: 'column',
    },

    contentContainer: {
        flex: 1,
    },

    topBarContainer: {
        height: 64,
    }
}));
