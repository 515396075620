import React, { useState } from 'react';
import { View, Switch } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { dispatchService } from '../../../services/central-api/dispatch';
import MyAppText from '../../../components/MyAppText';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export default function OccurrenceTypesModal({ onClose, occurrenceType, authenticatedUser }: { onClose: () => void, occurrenceType?: OccurrenceType; authenticatedUser?: AuthenticatedUser; }) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState(occurrenceType?.name || '');
    const [active, setActive] = useState<boolean>(occurrenceType && occurrenceType.active !== null ? occurrenceType.active : true);
    const [actingBodyId, setActingBodyId] = useState<number>(occurrenceType?.actingBodyId ?? 0);

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    async function formSubmit() {
        try {
            if (occurrenceType?.id) {
                await dispatchService.updateOccurrenceType({
                    id: occurrenceType.id,
                    name: name,
                    active: active,
                });
                onClose();
                return;
            }

            await dispatchService.createOccurrenceType({
                name: name,
                active: active,
                actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined
            });
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrence_types) &&
                <ActingBodiesDropdown includeAllOption={false} value={actingBodyId} setValue={setActingBodyId} zIndex={2} disabled={!!occurrenceType?.id} />
            }

            <FormInput
                label={translate('name')}
                placeholder={translate('name')}
                value={name}
                onChangeText={setName}
                invalid={isNameInvalid}
            />
            <View>
                <MyAppText style={styles.generalInformationTitleText}>
                    {translate('active')}
                </MyAppText>
                <Switch
                    trackColor={{ false: '#767577', true: '#81b0ff' }}
                    thumbColor={active ? '#f5dd4b' : '#f4f3f4'}
                    onValueChange={setActive}
                    value={active}
                />
            </View>

            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    },
    generalInformationTitleText: {
        color: theme.colors.textColor
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));
