import React, { useEffect, useState } from 'react';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';
import { dispatchService } from '../../../services/central-api/dispatch';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    actingBodyId: number | null;
    disabled: boolean;
    zIndex: number;
    selectableAgents: { label: string, value: number; }[];
    setSelectableAgents: React.Dispatch<React.SetStateAction<{ label: string, value: number; }[]>>;
    forceUpdate: () => void;
}

function AgentsDropdown({ value, setValue, actingBodyId, disabled, zIndex, selectableAgents, setSelectableAgents, forceUpdate }: Props) {
    const [selectedAgent, setSelectedAgent] = useState<number>(0);
    async function getAgents({ actingBodyId }: { actingBodyId: number; }) {
        try {
            const agents = await dispatchService.getAgents({ actingBodyId });
            setSelectableAgents(agents.map((item) => {
                return {
                    label: `${item.name}${item.warName ? ' - ' + item.warName : ''}: ${item.registry}`,
                    value: item.id
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId) {
            getAgents({ actingBodyId });
        }
    }, [actingBodyId]);

    useEffect(() => {
        if (selectedAgent !== 0) {
            value.push(selectedAgent);
            setValue(value);
            forceUpdate();
        }
    }, [selectedAgent]);

    return (
        <FilterDropdown
            label={translate('agents')}
            items={selectableAgents.filter(item => !value.includes(item.value))}
            multiple={false}
            setValue={setSelectedAgent}
            value={0}
            disabled={disabled}
            zIndex={zIndex}
        />
    );
}

export default AgentsDropdown;
