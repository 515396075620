import React, { useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View, TextInput } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLocationDot, faPencil, faVideo, faXmark } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../../components/MyAppText';
import PlateImage from '../../../components/lpr/PlateImage';
import Map from '../../../components/Map';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { translate } from '../../../services/translate';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import { downloadImage } from '../../../services/download-image';
import LazyLoad from 'react-lazy-load';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ClientError } from '../../../services/central-api/base-service';
import Toast from 'react-native-toast-message';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../../../components/CustomButton';
import { useAuth } from '../../../context/Auth';
import { WindowInformation } from '../../../services/window-information';
import { ScrollView } from 'react-native-web-hover';

const regPlate = new RegExp(/[A-Z]{3}[-]?[0-9][0-9A-Z][0-9]{2}/);

interface DetectionDetailsProps {
    navigation: Navigation;
    detection: LprDetection;
    onSave: () => void;
}


export default function DetectionDetails({ detection, onSave }: DetectionDetailsProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [editingPlate, setEditingPlate] = useState(false);
    const [newPlate, setNewPlate] = useState(detection.plate);
    const { user: authenticatedUser } = useAuth();

    const canvasRef = useRef<HTMLCanvasElement>(null);

    function isPlateInvalid(value: string) {
        return value == '' || value.length > 7 || !regPlate.test(value);
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        if (!detection) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 4;

            const rect = {
                minX: Infinity,
                maxX: 0,
                minY: Infinity,
                maxY: 0
            };

            if (detection.platePosition) {
                for (const position of detection.platePosition) {
                    if (position.x < rect.minX) {
                        rect.minX = position.x;
                    }

                    if (position.x > rect.maxX) {
                        rect.maxX = position.x;
                    }

                    if (position.y < rect.minY) {
                        rect.minY = position.y;
                    }

                    if (position.y > rect.maxY) {
                        rect.maxY = position.y;
                    }
                }

                ctx.beginPath();
                ctx.moveTo(rect.minX, rect.minY);
                ctx.lineTo(rect.minX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.minY);
                ctx.closePath();
                ctx.stroke();
            }

        };

        image.src = detection.imageUrl;
    });

    async function saveNewPlate() {
        if (!detection) {
            return;
        }

        if (isPlateInvalid(newPlate)) {
            return Toast.show({
                type: 'sentinelxError',
                text1: translate('invalidPlate'),
            });
        }

        try {
            await licensePlateRecognitionService.editLprDetection(detection.id, newPlate);
            setEditingPlate(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onSave();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <ScrollView contentContainerStyle={[styles.cardList, windowInfo.isMobile ? { flexDirection: 'column' } : { flexDirection: 'row' }]}>
            <View style={{ rowGap: 15 }}>
                <View style={{ maxWidth: 700 }}>
                    <TransformWrapper>
                        <TransformComponent>
                            <canvas style={{ width: '100%' }} ref={canvasRef}></canvas>
                        </TransformComponent>
                    </TransformWrapper>
                </View>
                <View style={{ flexDirection: 'row', gap: 15, flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <View style={{ gap: 8 }}>
                        <View style={{ flexDirection: 'row', gap: 12, alignItems: 'center' }}>
                            {editingPlate ?
                                <>
                                    <TextInput
                                        placeholder=''
                                        style={{
                                            color: theme.colors.iconColor,
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            width: 80
                                        }}
                                        value={newPlate}
                                        onChangeText={(value) => {
                                            if (value.length > 7) {
                                                return;
                                            }
                                            setNewPlate(value.toUpperCase());
                                        }}
                                    />
                                    <TouchableOpacity
                                        onPress={() => {
                                            setNewPlate(detection.plate);
                                            setEditingPlate(false);
                                        }}
                                    >
                                        <FontAwesomeIcon color={theme.colors.iconColor} icon={faXmark} fontSize={16} />
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={saveNewPlate} >
                                        <FontAwesomeIcon color={theme.colors.iconColor} icon={faCheck} fontSize={16} />
                                    </TouchableOpacity>
                                </>
                                :
                                <>
                                    <MyAppText style={styles.plateText}>
                                        {detection.plate}
                                    </MyAppText>
                                    {authenticatedUser?.isAdmin || authenticatedUser?.permissions.lpr_edit_detection ?
                                        <TouchableOpacity onPress={() => setEditingPlate(true)} >
                                            <FontAwesomeIcon color={theme.colors.iconColor} icon={faPencil} fontSize={12} />
                                        </TouchableOpacity>
                                        : null
                                    }
                                </>
                            }
                        </View>
                        <View style={{ flexDirection: 'column', gap: 4 }}>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={[styles.cameraInfoText, { fontWeight: 'bold' }]}>
                                    {translate('brand')}:
                                </MyAppText>
                                <MyAppText style={styles.cameraInfoText}>
                                    {detection['vehicleDescription.brand']}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={[styles.cameraInfoText, { fontWeight: 'bold' }]}>
                                    {translate('model')}:
                                </MyAppText>
                                <MyAppText style={styles.cameraInfoText}>
                                    {detection['vehicleDescription.model']}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={[styles.cameraInfoText, { fontWeight: 'bold' }]}>
                                    {translate('color')}:
                                </MyAppText>
                                <MyAppText style={styles.cameraInfoText}>
                                    {detection['vehicleDescription.color']}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={[styles.cameraInfoText, { fontWeight: 'bold' }]}>
                                    {translate('vehicleType')}:
                                </MyAppText>
                                <MyAppText style={styles.cameraInfoText}>
                                    {detection['vehicleDescription.type']}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={[styles.cameraInfoText, { fontWeight: 'bold' }]}>
                                    {translate('origin')}:
                                </MyAppText>
                                <MyAppText style={styles.cameraInfoText}>
                                    {detection['vehicleDescription.city']}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={[styles.cameraInfoText, { fontWeight: 'bold' }]}>
                                    {translate('year')}:
                                </MyAppText>
                                <MyAppText style={styles.cameraInfoText}>
                                    {detection['vehicleDescription.modelYear']}
                                </MyAppText>
                            </View>
                        </View>
                    </View>
                    <View style={{ gap: 5 }}>
                        <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('lastDetection')}</MyAppText>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}>
                            <FontAwesomeIcon color={theme.colors.iconColor} icon={faLocationDot} />
                            <MyAppText style={styles.cameraInfoText}>{detection.camera.address}</MyAppText>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <FontAwesomeIcon color={theme.colors.iconColor} icon={faVideo} />
                            <MyAppText style={styles.cameraInfoText}>
                                {detection.camera.title}
                            </MyAppText>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 14 }}>
                            <FontAwesomeIcon color={theme.colors.iconColor} icon={faCalendarCheck} />
                            <MyAppText style={styles.cameraInfoText}>
                                {moment(detection.time).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                    </View>
                    <View style={{ gap: 10 }}>
                        <LazyLoad>
                            <PlateImage
                                src={detection.imageUrl}
                                platePosition={detection.platePosition}
                                width={175}
                                height={75}
                            />
                        </LazyLoad>
                        <CustomButton
                            // icon={faImage}
                            text={translate('downloadImage')}
                            onPress={() => downloadImage({
                                fileName: `${detection.plate}_${moment(detection.time).format('DDMMYYYYHHMMSS')}`,
                                imageUrl: detection.imageUrl
                            })}
                            style={{ width: 175 }} />
                    </View>
                </View>
            </View>
            <View style={{ flex: 1, minWidth: 300, minHeight: 200 }}>
                <Map pinnedMarker={{
                    lat: detection.camera.latitude,
                    lng: detection.camera.longitude
                }} mapControls={false} />
            </View>
        </ScrollView >
    );
}


const styleSheet = createStyleSheet((theme) => ({
    cardList: {
        flex: 1,
        flexDirection: 'row',
        gap: 15
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    plateText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        fontWeight: 'bold'
    },
    cameraInfoText: {
        color: theme.colors.labelColor,
        fontSize: 12
    }
}));
