import React from 'react';
import { ScrollView, View } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { TextMask } from 'react-native-masked-text';

export default function OccurrenceFormGuardianApp({ occurrence }: { occurrence: Occurrence; }) {
    const { styles, theme } = useStyles(styleSheet);

    if (occurrence.triggerType != 'guardian_app' || !occurrence.externalReference) {
        return <></>;
    }

    return (
        <ScrollView>
            <View style={{ gap: 20 }}>
                <View style={{ gap: 5 }}>
                    <MyAppText style={styles.text}>{translate('requester')}</MyAppText>
                    <MyAppText>{translate('name')}: {occurrence.externalReference.name}</MyAppText>
                    <MyAppText>{translate('phone')}: {occurrence.externalReference.phone}</MyAppText>
                    <MyAppText>{translate('phone')} IMEI: {occurrence.externalReference.imei}</MyAppText>
                    <View style={{ flexDirection: 'row', gap: 5 }}>
                        <MyAppText>CPF:</MyAppText>
                        <TextMask
                            style={styles.documentText}
                            value={occurrence.externalReference.document}
                            type={'cpf'}
                        />
                    </View>
                    <MyAppText>{translate('email')}: {occurrence.externalReference.email}</MyAppText>
                    <MyAppText>{translate('birth')}: {moment(new Date(occurrence.externalReference.birth)).format('DD/MM/YYYY')}</MyAppText>
                    {occurrence.externalReference.aggressorName ?
                        <MyAppText>{translate('aggressorName')}: {occurrence.externalReference.aggressorName}</MyAppText>
                        : null}
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.text}>{translate('location')}</MyAppText>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={theme.colors.iconColor} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(new Date(occurrence.createdAt)).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={theme.colors.iconColor} />
                        <MyAppText style={styles.detectionInfoText}>
                            {occurrence.address}
                        </MyAppText>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    detectionInfoText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    text: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 16
    },
    infoText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 24
    },
    documentText: {
        fontFamily: 'Open Sans',
        color: theme.colors.textColor,
        fontSize: 14
    }
}));
