import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    actingBodyId: number;
    zIndex: number;
}

function EquipmentsDropdown({ value, setValue, actingBodyId, zIndex }: Props) {
    const [selectableEquipments, setSelectableEquipments] = useState<{ label: string, value: number; }[]>([]);

    async function getEquipments({ actingBodyId }: { actingBodyId: number; }) {
        try {
            const equipments = await dispatchService.getSimplifiedEquipments(actingBodyId);
            setSelectableEquipments(equipments.map((item: DropdownResource) => {
                return {
                    label: item.name, value: item.id
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId) {
            getEquipments({ actingBodyId });
        }
    }, [actingBodyId]);

    return (
        <FilterDropdown
            placeholder={translate('selectEquipments')}
            items={selectableEquipments}
            multiple={true}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            mode='BADGE'
            style={{ minWidth: 240, maxWidth: 240 }}
        />
    );
}

export default EquipmentsDropdown;
