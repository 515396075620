import React from 'react';
import { Modal, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import FormActions from '../../../components/formActions';
import { crmService } from '../../../services/central-api/crm';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';

interface Props {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    historyId?: number;
    onSubmit?: VoidFunction;
}

function ActOfflineEventModal({ isVisible, setIsVisible, historyId, onSubmit }: Props) {
    const { styles } = useStyles(stylesheet);
    const setOfflineEventActedByUser = async () => {
        if (!historyId) return;

        try {
            await crmService.ackOfflineEvent(historyId);

            setIsVisible(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    };

    return (
        <Modal visible={isVisible} animationType='fade' transparent onRequestClose={() => setIsVisible(false)}>
            <View style={styles.modalContainer}>
                <View style={styles.modalComponent}>
                    <MyAppText style={styles.modalTitle}>{translate('actOfflineEventConfirmMessage')}</MyAppText>

                    <FormActions
                        onSubmit={async () => {
                            await setOfflineEventActedByUser();
                            onSubmit?.();
                        }}
                        onClose={() => {
                            setIsVisible(false);
                        }}
                        confirmText={'confirm'}
                        disabled={false}
                    />
                </View>
            </View>
        </Modal>
    );
}

const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
        justifyContent: 'center',
        alignItems: 'center',
    },

    modalComponent: {
        minWidth: {
            xs: undefined,
            md: 450
        },
        minHeight: 150,
        width: {
            md: undefined,
            xs: '90%'
        },
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    },

    modalTitle: {
        fontSize: 18,
        fontWeight: '600'
    },

    dateFieldsContainer: {
        flexDirection: 'row',
        gap: {
            xs: 10,
            md: 50
        },
        flexWrap: 'wrap'
    }
}));

export default ActOfflineEventModal;
