import React, { useEffect, useState } from 'react';
import { accessService } from '../../services/central-api/access';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';


interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    actingBodyId: number | null;
    zIndex: number;
}

function ActingBodiesCommandsDropdown({ value, setValue, actingBodyId, zIndex }: Props) {
    const [selectableActingBodyCommands, setSelectableActingBodyCommands] = useState<{ label: string, value: number; }[]>([]);

    async function getActingBodyCommands({ actingBodyId }: { actingBodyId: number; }) {
        try {
            const actingBody = await accessService.getActingBody(actingBodyId);

            if (!actingBody.actingBodyCommands) {
                setSelectableActingBodyCommands([]);
            } else {
                setSelectableActingBodyCommands([
                    ...actingBody.actingBodyCommands.map((role) => {
                        return {
                            value: role.id || 0,
                            label: role.name
                        };
                    })
                ]);
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId) {
            getActingBodyCommands({ actingBodyId });
        }
    }, [actingBodyId]);

    return (
        <FilterDropdown
            label={translate('command')}
            items={selectableActingBodyCommands}
            multiple={true}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            mode='BADGE'
        />
    );
}

export default ActingBodiesCommandsDropdown;
