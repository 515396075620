import React, { useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { dispatchService } from '../../../services/central-api/dispatch';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Toast from 'react-native-toast-message';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import { MyCheckbox } from '../../../components/MyCheckbox';
import TagsDropdown from './TagsDropdown';
import MyAppText from '../../../components/MyAppText';
import { UnistylesRuntime } from 'react-native-unistyles';
import GenericUserDropdown from '../../../components/Dropdowns/GenericUserDropdown';
import { ScrollView } from 'react-native-web-hover';
import ActingBodiesCommandsDropdown from '../../../components/Dropdowns/ActingBodiesCommandsDropdown';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    authenticatedUser?: AuthenticatedUser;
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
const regex = /^[a-zA-Z0-9 +\-./:=_ãõẽíáàâêóôúüçÃÕẼÍÁÀÂÊÓÔÚÜÇ]*$/;

export default function ReportModal({ onClose, authenticatedUser, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [isPdf, setIsPdf] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>([]);
    const [dateFrom, setDateFrom] = useState<ValuePiece>(new Date());
    const [dateTo, setDateTo] = useState<ValuePiece>(new Date());
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [assumedBy, setAssumedBy] = useState<number>(0);
    const [transferredBy, setTransferredBy] = useState<number>(0);
    const [receivedBy, setReceivedBy] = useState<number>(0);
    const [finishedBy, setFinishedBy] = useState<number>(0);
    const [actingBodyCommandIds, setActingBodyCommandIds] = useState<number[]>([]);

    function isNameInvalid(value: string) {
        return value == '' || !regex.test(value);
    }

    function isFormValid() {
        return !isNameInvalid(name) && moment(dateTo).isSameOrAfter(moment(dateFrom));
    }

    function clearValues() {
        setName('');
        setIsPdf(false);
        setSelectedTagsIds([]);
        setActingBodyId(0);
        setAssumedBy(0);
        setTransferredBy(0);
        setReceivedBy(0);
        setFinishedBy(0);
    }

    async function formSubmit() {
        try {
            await dispatchService.createReport({
                name: name,
                isPdf: isPdf,
                startDate: moment(dateFrom).format('YYYY-MM-DD'),
                endDate: moment(dateTo).format('YYYY-MM-DD'),
                tags: selectedTagsIds,
                actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
                assumedBy: assumedBy !== 0 ? assumedBy : undefined,
                transferredBy: transferredBy !== 0 ? transferredBy : undefined,
                receivedBy: receivedBy !== 0 ? receivedBy : undefined,
                finishedBy: finishedBy !== 0 ? finishedBy : undefined,
                actingBodyCommandIds: actingBodyCommandIds
            });

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            clearValues();
            onClose();
        }
    }

    function getActingBodyId() {
        if (!authenticatedUser || !authenticatedUser.actingBodyUnit.actingBodyId) {
            return 0;
        }

        if (authenticatedUser.isAdmin || authenticatedUser.permissions.dispatch_manage_all_acting_bodies_reports) {
            return actingBodyId;
        } else {
            return authenticatedUser.actingBodyUnit.actingBodyId;
        }
    }

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : {}]}>
                    <ScrollView contentContainerStyle={styles.scrollContent}>
                        <MyAppText style={styles.textHeader}>{translate('occurrenceReport')}</MyAppText>
                        <MyAppText style={{ color: theme.colors.textColor, fontSize: 12 }}>{translate('occurrenceReportInformation')}</MyAppText>
                        {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_reports) &&
                            <ActingBodiesDropdown includeAllOption={false} value={actingBodyId} setValue={setActingBodyId} zIndex={8} />
                        }
                        <FormInput
                            label={translate('name')}
                            placeholder={translate('name')}
                            value={name}
                            onChangeText={setName}
                            invalid={isNameInvalid}
                            errorMessage={translate('invalidCharacters')}
                        />
                        <View style={{ zIndex: 7, gap: 5 }}>
                            <MyAppText style={styles.tagsLabel}>{translate('period')}</MyAppText>
                            <DateRangePicker calendarProps={{ locale: 'pt-BR' }} maxDate={moment().toDate()}
                                onChange={(value: Value) => {
                                    if (Array.isArray(value)) {
                                        setDateFrom(value[0]);
                                        setDateTo(value[1]);
                                    }
                                }}
                                autoFocus={false}
                                shouldOpenCalendar={({ reason }) => reason !== 'focus'}
                                value={[dateFrom, dateTo]}
                                clearIcon={null}
                                locale='pt-BR'
                                className={UnistylesRuntime.themeName === 'light' ? 'react-daterange-picker__wrapper-light' : 'react-daterange-picker__wrapper-dark'}
                            />
                        </View>
                        <ActingBodiesCommandsDropdown value={actingBodyCommandIds} actingBodyId={getActingBodyId()} setValue={setActingBodyCommandIds} zIndex={6} />
                        <GenericUserDropdown value={assumedBy} setValue={setAssumedBy} actingBodyId={getActingBodyId()} zIndex={5} label='assumedBy' />
                        <GenericUserDropdown value={transferredBy} setValue={setTransferredBy} actingBodyId={getActingBodyId()} zIndex={4} label='transferredBy' />
                        <GenericUserDropdown value={receivedBy} setValue={setReceivedBy} actingBodyId={getActingBodyId()} zIndex={3} label='receivedBy' />
                        <GenericUserDropdown value={finishedBy} setValue={setFinishedBy} actingBodyId={getActingBodyId()} zIndex={2} label='finishedBy' />
                        <TagsDropdown
                            actingBodyId={actingBodyId !== 0 ? actingBodyId : undefined}
                            value={selectedTagsIds}
                            setValue={setSelectedTagsIds}
                            zIndex={1} />
                        <MyCheckbox
                            style={styles.checkbox}
                            label={translate('PDF')}
                            checked={isPdf}
                            setChecked={(value) => setIsPdf(value)} />
                    </ScrollView>
                    <FormActions
                        onSubmit={async () => {
                            await formSubmit();
                        }}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()}
                        confirmText={'download'} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 15,
        maxHeight: 690
    },
    scrollContent: {
        rowGap: 15,
        paddingRight: 10
    },
    checkbox: {
        paddingHorizontal: 8,
        height: 40,
        fontSize: 16,
    },
    tagsLabel: {
        color: theme.colors.labelColor,
        fontSize: 16,
    },
    textHeader: {
        fontSize: 22,
        fontWeight: 'bold',
        color: theme.colors.textColor
    }
}));
