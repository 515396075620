import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import MyRadio from '../../../components/MyRadio';
import { translate } from '../../../services/translate';
import CustomTextFilter from '../../../components/CustomTextFilter';
import CustomButton from '../../../components/CustomButton';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { gcmAgentService } from '../../../services/central-api/gcmAgent';
import moment from 'moment';
import { ClientError, NotFound } from '../../../services/central-api/base-service';
import Toast from 'react-native-toast-message';
import MyCpfInput from '../../../components/MyCpfInput';
import MyDateInput from '../../../components/MyDateInput';
import PersonContext from './PersonContext';
import WarrantContext from './WarrantContext';
import CarContext from './PlateContext';

export default function CortexFinder({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);

    const [searchContext, setSearchContext] = useState<'person' | 'warrant' | 'plate'>('person');
    const [searchType, setSearchType] = useState<'document' | 'motherName' | 'birthDate'>('document');
    const [document, setDocument] = useState<string>('');
    const [isDocumentInvalid, setIsDocumentInvalid] = useState(false);
    const [name, setName] = useState<string>('');
    const [motherName, setMotherName] = useState<string>('');
    const [birthDate, setBirthDate] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [plate, setPlate] = useState<string>('');

    const [personFromDocument, setPersonFromDocument] = useState<PersonFromDocument>();
    const [personFromMotherName, setPersonFromMotherName] = useState<PersonFromMotherName[]>([]);
    const [personFromBirthDate, setPersonFromBirthDate] = useState<PersonFromBirthDate[]>([]);

    const [warrantInformation, setWarrantInformation] = useState<BasicWarrantInformation[]>([]);

    const [carFromPlate, setCarFromPlate] = useState<CarFromPlate>();

    function isValidForm() {
        if (searchContext == 'person' || searchContext == 'warrant') {
            if (searchType == 'document' && (isDocumentInvalid || document == '')) {
                return false;
            } else if (searchType == 'motherName' && (!name || !motherName)) {
                return false;
            } else if (searchType == 'birthDate' && (!name || !moment(birthDate, 'DD/MM/YYYY').isValid() || moment(birthDate, 'DD/MM/YYYY').valueOf() <= moment(`01/01/1900`, 'DD/MM/YYYY').valueOf())) {
                return false;
            }
        } else if (searchContext == 'plate') {
            const regex = /^[A-Za-z]{3}-?\d([A-Ja-j]|\d)\d{2}$/;
            return regex.test(plate);
        }

        return true;
    }

    async function findPerson() {
        try {
            setIsLoading(true);
            if (searchType == 'document') {
                const response = await gcmAgentService.findPersonFromDocument(document);
                setPersonFromDocument(response);
            } else if (searchType == 'motherName') {
                const response = await gcmAgentService.findPersonFromNameAndMotherName(name, motherName);
                setPersonFromMotherName(response);
            } else if (searchType == 'birthDate') {
                const response = await gcmAgentService.findPersonFromNameAndBirthDate(name, moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                setPersonFromBirthDate(response);
            }
        } catch (err) {
            if (err instanceof NotFound) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate('documentNotFound'),
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function findWarrant() {
        try {
            setIsLoading(true);
            if (searchType == 'document') {
                const response = await gcmAgentService.findWarrantFromDocument(document);
                setWarrantInformation(response);
            } else if (searchType == 'motherName') {
                const response = await gcmAgentService.findWarrantFromNameAndMotherName(name, motherName);
                setWarrantInformation(response);
            } else if (searchType == 'birthDate') {
                const response = await gcmAgentService.findWarrantFromNameAndBirthDate(name, moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                setWarrantInformation(response);
            }
        } catch (err) {
            if (err instanceof NotFound) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate('documentNotFound'),
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function findPlate() {
        try {
            setIsLoading(true);
            setPlate(plate.replace(/-/g, '').toUpperCase());
            const response = await gcmAgentService.findCarFromPlate(plate);
            setCarFromPlate(response);
        } catch (err) {
            if (err instanceof NotFound) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate('plateNotFound'),
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        setName('');
        setDocument('');
        setMotherName('');
        setPlate('');
    }, [searchContext]);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'gcmAgent'} selectedMenu='cortex_finder' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.cardList}>
                        <MyRadio
                            selected={searchContext}
                            setSelected={setSearchContext}
                            options={[
                                { label: translate('person'), value: 'person' },
                                { label: translate('warrant'), value: 'warrant' },
                                { label: translate('plate'), value: 'plate' },
                            ]}
                        />

                        {['person', 'warrant'].includes(searchContext) ? (
                            <>
                                <MyRadio
                                    selected={searchType}
                                    setSelected={setSearchType}
                                    options={[
                                        { label: translate('document'), value: 'document' },
                                        { label: translate('motherName'), value: 'motherName' },
                                        { label: translate('birth'), value: 'birthDate' },
                                    ]}
                                />

                                {searchType === 'document' ? (
                                    <MyCpfInput
                                        value={document}
                                        onChangeText={setDocument}
                                        isInvalid={isDocumentInvalid}
                                        setIsInvalid={setIsDocumentInvalid}
                                    />
                                ) : searchType === 'motherName' ? (
                                    <View style={{ gap: 15 }}>
                                        <CustomTextFilter
                                            textFilter={name}
                                            setTextFilter={setName}
                                            title="name"
                                            style={styles.inputText}
                                        />
                                        <CustomTextFilter
                                            textFilter={motherName}
                                            setTextFilter={setMotherName}
                                            title="motherName"
                                            style={styles.inputText}
                                        />
                                    </View>
                                ) : searchType === 'birthDate' ? (
                                    <View style={{ gap: 15 }}>
                                        <CustomTextFilter
                                            textFilter={name}
                                            setTextFilter={setName}
                                            title="name"
                                            style={styles.inputText}
                                        />
                                        <MyDateInput
                                            label={translate('birth')}
                                            date={birthDate}
                                            onChangeDate={setBirthDate}
                                        />
                                    </View>
                                ) : null}
                            </>
                        ) : searchContext === 'plate' ? (
                            <CustomTextFilter
                                textFilter={plate}
                                setTextFilter={setPlate}
                                title="plate"
                                style={styles.inputText}
                            />
                        ) : null}
                        <CustomButton
                            icon={faSearch}
                            onPress={async () => {
                                if (!isValidForm()) {
                                    return;
                                }

                                if (searchContext == 'person') {
                                    await findPerson();
                                } else if (searchContext == 'warrant') {
                                    await findWarrant();
                                } else if (searchContext == 'plate') {
                                    await findPlate();
                                }
                            }}
                            text={translate('toSearch')}
                            disabled={!isValidForm()}
                        />

                        {isLoading ?
                            <ActivityIndicator size='large' color={theme.colors.iconColor} /> :
                            searchContext == 'person' ? (
                                <PersonContext
                                    searchType={searchType}
                                    isLoading={isLoading}
                                    personFromBirthDate={personFromBirthDate}
                                    personFromDocument={personFromDocument}
                                    personFromMotherName={personFromMotherName} />
                            ) : (
                                searchContext == 'warrant' ? (
                                    <WarrantContext
                                        isLoading={isLoading}
                                        warrantInformation={warrantInformation}
                                    />
                                ) : (
                                    searchContext == 'plate' ? (
                                        <CarContext
                                            isLoading={isLoading}
                                            carFromPlate={carFromPlate}
                                        />
                                    ) : <></>)
                            )
                        }
                    </View>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        backgroundColor: theme.colors.backgroundColor,
        padding: 10,
        flex: 1,
        gap: 20
    },
    inputText: {
        minWidth: 300
    },
}));
