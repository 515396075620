import React, { useEffect, useState } from 'react';
import {
    View,
    TouchableOpacity,
    TextInput,
    Modal,
    ActivityIndicator,
    Image,
    ScrollView,
    Switch,
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowLeft, faSearchPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { WindowInformation } from '../../services/window-information';
import { Hoverable } from 'react-native-web-hover';
import { MoreThanOneFaceOnPhoto, NoFaceOnPhoto, facialService } from '../../services/central-api/facial';
import { TextInputMask } from 'react-native-masked-text';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import getBestContrastColor from '../../services/best-contrast-color';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface PersonFormParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params?: {
            id: number;
        };
    };
}

export default function PersonForm({ navigation, route, authenticatedUser }: PersonFormParams) {
    const { styles, theme } = useStyles(stylesheet);

    const windowInfo = WindowInformation();

    const [isLoading, setIsLoading] = useState(false);
    const [imageZoom, setImageZoom] = useState<FacialFacePicture>();
    const [isEditingMarkers, setIsEditingMarkers] = useState(false);
    const [files, setFiles] = useState<{ file: File, objectURI: string; key: string; }[]>([]);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [isDeleteFacePictureModalVisible, setDeleteFacePictureModalVisible] = useState<boolean>(false);
    const [facePictureToDelete, setFacePictureToDelete] = useState<FacialFacePicture>();

    // person props
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [nickname, setNickname] = useState('');
    const [document, setDocument] = useState('');
    const [personMarkers, setPersonMarkers] = useState<FacialMarker[]>([]);
    const [markers, setMarkers] = useState<FacialMarker[]>([]);
    const [facePictures, setFacePictures] = useState<FacialFacePicture[]>([]);
    const [register, setRegister] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');

    // lawsuit props
    const [arrestWarrantNumber, setArrestWarrantNumber] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [issuingBody, setIssuingBody] = useState('');
    const [lawsuitCity, setLawsuitCity] = useState('');
    const [lawsuitNumber, setLawsuitNumber] = useState('');
    const [magistrate, setMagistrate] = useState('');
    const [prisonKind, setPrisonKind] = useState('');
    const [shippingDate, setShippingDate] = useState('');
    const [situation, setSituation] = useState('');

    async function getPerson(id: number) {
        try {
            setIsLoading(true);
            const person = await facialService.getFacialPerson(id);

            // person props
            setComment(person.comment);
            setIsActive(person.isActive);
            setName(person.name);
            setNickname(person.nickname);
            setDocument(person.document);
            setRegister(person.register);
            setState(person.state);
            setCity(person.city);
            setPersonMarkers(person.markers);
            setFacePictures(person.facePictures);

            // lawsuit props
            setArrestWarrantNumber(person.lawsuits[0].arrestWarrantNumber);
            setExpirationDate(person.lawsuits[0].expirationDate);
            setIssuingBody(person.lawsuits[0].issuingBody);
            setLawsuitCity(person.lawsuits[0].lawsuitCity);
            setLawsuitNumber(person.lawsuits[0].lawsuitNumber);
            setMagistrate(person.lawsuits[0].magistrate);
            setPrisonKind(person.lawsuits[0].prisonKind);
            setShippingDate(person.lawsuits[0].shippingDate);
            setSituation(person.lawsuits[0].situation);

        } catch (err) {
            console.error(err);
        } finally {
            setAlreadyLoaded(true);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (route.params?.id && !alreadyLoaded) {
            getPerson(route.params.id);
        }
    }, [route.params]);


    async function getMarkers() {
        try {
            setIsLoading(true);
            const markers = await facialService.getFacialMarkers();
            setMarkers(markers);
        } catch (err) {
            // TODO: add proper error treatment
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getMarkers();
    }, []);

    function removePersonMarker(marker: FacialMarker) {
        const index = personMarkers.indexOf(marker);
        if (index > -1) {
            personMarkers.splice(index, 1);
        }
        setPersonMarkers([...personMarkers]);
    }

    function addPersonMarker(marker: FacialMarker) {
        personMarkers.push(marker);
        setPersonMarkers([...personMarkers]);
    }

    async function deletePerson(personId: number) {
        try {
            setIsLoading(true);
            await facialService.deletePerson(personId);
            navigation.navigate('PeopleList');
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            // TODO: add proper error treatment
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function deleteFacePicture(facePicture: FacialFacePicture) {
        try {
            setIsLoading(true);

            await facialService.deleteFacePicture({
                personId: route.params?.id || 0,
                facePictureId: facePicture.id
            });

            const index = facePictures.indexOf(facePicture);
            if (index !== -1) {
                facePictures.splice(index, 1);
            }
            setFacePictures([...facePictures]);

        } catch (err) {
            // TODO: add proper error treatment
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function save() {
        try {
            setIsLoading(true);

            if (!name) {
                Toast.show({
                    type: 'sentinelxError',
                    text1: translate('youNeedToProvideName'),
                });
                setIsLoading(false);
                return;
            }

            let personId: number;

            if (route.params?.id) {
                personId = route.params?.id;
                await facialService.updatePerson(route.params.id, {
                    name,
                    isActive,
                    nickname,
                    document,
                    comment,
                    register,
                    state,
                    city,
                    arrestWarrantNumber,
                    expirationDate,
                    issuingBody,
                    lawsuitCity,
                    lawsuitNumber,
                    magistrate,
                    prisonKind,
                    shippingDate,
                    situation,
                    markers: personMarkers.map((marker) => marker.id || 0)
                });
            } else {
                const res = await facialService.addPerson({
                    name,
                    isActive,
                    nickname,
                    document,
                    comment,
                    register,
                    state,
                    city,
                    arrestWarrantNumber,
                    expirationDate,
                    issuingBody,
                    lawsuitCity,
                    lawsuitNumber,
                    magistrate,
                    prisonKind,
                    shippingDate,
                    situation,
                    markers: personMarkers.map((marker) => marker.id || 0)
                });
                personId = res.id || 0;
            }

            for (const file of files) {
                await facialService.addFacePicture({
                    personId,
                    photo: file.file
                });
            }

            navigation.navigate('PeopleList');
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof NoFaceOnPhoto) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            if (err instanceof MoreThanOneFaceOnPhoto) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='register' lateralMenu='facial' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                            <TouchableOpacity onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate('PeopleList')}>
                                <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.primaryButton.background} />
                            </TouchableOpacity>
                            <MyAppText style={{ fontSize: 24, color: theme.colors.labelColor, fontWeight: 'bold' }}>
                                {translate('registerPerson')}
                            </MyAppText>
                        </View>


                        {route.params?.id && <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity
                                onPress={() => {
                                    setDeleteModalVisible(true);
                                }}
                                style={[{
                                    borderWidth: 1,
                                    borderRadius: 2,
                                    borderColor: '#FE0127',
                                    backgroundColor: theme.colors.backgroundColor,
                                    width: 128,
                                    height: 40,
                                    justifyContent: 'center',
                                }]}
                            >
                                <MyAppText style={[{ color: '#FE0127', alignSelf: 'center', fontSize: 16 }]}>
                                    {translate('delete')}
                                </MyAppText>
                            </TouchableOpacity>
                        </View>}

                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity
                                onPress={save}
                                style={{
                                    borderWidth: 0,
                                    borderRadius: 2,
                                    backgroundColor: theme.colors.primaryButton.background,
                                    width: 128,
                                    height: 40,
                                    justifyContent: 'center',
                                }}
                            >
                                <MyAppText style={{ color: theme.colors.backgroundColor, alignSelf: 'center', fontSize: 16 }}>
                                    {translate('save')}
                                </MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={styles.cardList}>
                        <ScrollView style={{ padding: 24 }}>

                            {isLoading &&
                                <ActivityIndicator size='small' color={theme.colors.loadingColor} style={{ position: 'absolute', top: 30, right: 20 }} />
                            }

                            <View style={styles.subTitleContainer}>
                                <MyAppText style={styles.subTitleText}>
                                    {translate('general_info')}
                                </MyAppText>
                            </View>

                            <View style={{ flexDirection: 'row' }}>

                                {/* image blocks */}
                                <View style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    gap: 0,
                                    alignItems: 'flex-start',
                                }}>

                                    {facePictures.map((facePicture) =>

                                        <View key={facePicture.id.toString()} style={{ marginRight: 10 }}>
                                            <Hoverable>
                                                {({ hovered }) => (<>
                                                    <Image
                                                        style={{
                                                            height: 136, width: 136,
                                                            borderRadius: 8,
                                                            overflow: 'hidden',
                                                        }}
                                                        source={{ uri: facePicture.imageUrl }}
                                                    />

                                                    {hovered &&
                                                        <View style={{
                                                            position: 'absolute',
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: '#00000080',
                                                            borderRadius: 8
                                                        }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    height: 40,
                                                                    width: 40,
                                                                    position: 'absolute',
                                                                    top: 60,
                                                                    left: 20
                                                                }}
                                                                onPress={async () => {
                                                                    setImageZoom(facePicture);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faSearchPlus} fontSize={22} color={'#FFFFFF'} />
                                                            </TouchableOpacity>
                                                            <TouchableOpacity
                                                                style={{
                                                                    height: 40,
                                                                    width: 40,
                                                                    position: 'absolute',
                                                                    top: 60,
                                                                    right: 20
                                                                }}
                                                                onPress={() => {
                                                                    setFacePictureToDelete(facePicture);
                                                                    setDeleteFacePictureModalVisible(true);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} fontSize={22} color={'#FFFFFF'} />
                                                            </TouchableOpacity>
                                                        </View>
                                                    }

                                                </>)}
                                            </Hoverable>

                                        </View>

                                    )}

                                    {files.map((img) =>

                                        <Image
                                            key={img.key}
                                            style={{
                                                height: 136, width: 136,
                                                borderRadius: 8,
                                                overflow: 'hidden',
                                                marginRight: 10
                                            }}
                                            source={{
                                                uri: img.objectURI
                                            }}
                                        />

                                    )}
                                    <TouchableOpacity style={{
                                        height: 136,
                                        width: 136,
                                        borderRadius: 8,
                                        overflow: 'hidden',
                                        backgroundColor: theme.colors.primaryButton.background,
                                        marginRight: 10,
                                    }} >
                                        <label style={{ width: '100%', height: '100%', display: 'grid' }}>
                                            <input
                                                style={{ display: 'none' }}
                                                type='file'
                                                onChange={(e) => {
                                                    if (!e.target.files) {
                                                        return;
                                                    }
                                                    const file = e.target.files[0];

                                                    if (!['image/jpeg', 'image/png'].includes(file.type)) {
                                                        return Toast.show({
                                                            type: 'sentinelxError',
                                                            text1: translate('imageFormatNotValid'),
                                                        });
                                                    }
                                                    setFiles(current => [...current, { file, objectURI: URL.createObjectURL(file), key: String(Math.random()) }]);
                                                }}
                                                accept='image/jpeg,image/png'
                                            />
                                            <FontAwesomeIcon fontSize={30} icon={faAdd} color={theme.colors.backgroundColor} style={{ justifySelf: 'center', alignSelf: 'center' }} />

                                        </label>
                                    </TouchableOpacity>

                                </View>

                                {/* personal data  */}
                                <View style={{ paddingLeft: 10 }}>
                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('name')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            style={styles.generalInformationInput}
                                            value={name}
                                            onChangeText={setName}
                                        />
                                    </View>
                                    <View style={[styles.generalInformationContainer, { height: 40 }]}>
                                        <MyAppText style={{
                                            width: 200,
                                            textAlign: 'right',
                                            marginRight: 15,
                                            color: theme.colors.labelColor,
                                            fontSize: 16,
                                            alignSelf: 'center'
                                        }}>
                                            {translate('active')}
                                        </MyAppText>
                                        <Switch
                                            trackColor={{ false: '#767577', true: '#81b0ff' }}
                                            thumbColor={isActive ? '#f5dd4b' : '#f4f3f4'}
                                            onValueChange={(value) => {
                                                setIsActive(value);
                                            }}
                                            value={isActive}
                                            style={{ alignSelf: 'center' }}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('nickname')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            style={styles.generalInformationInput}
                                            value={nickname}
                                            onChangeText={setNickname}
                                        />
                                    </View>

                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>CPF</MyAppText>
                                        <TextInputMask
                                            placeholder='000.000.000-00'
                                            style={styles.generalInformationInput}
                                            value={document}
                                            includeRawValueInChangeText={true}
                                            onChangeText={(maskedText, rawText) => {
                                                setDocument(rawText || '');
                                            }}
                                            type={'cpf'}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('personRegister')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            style={styles.generalInformationInput}
                                            value={register}
                                            onChangeText={setRegister}
                                        />
                                    </View>

                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('state')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            style={styles.generalInformationInput}
                                            value={state}
                                            onChangeText={setState}
                                        />
                                    </View>

                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('city')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            style={styles.generalInformationInput}
                                            value={city}
                                            onChangeText={setCity}
                                        />
                                    </View>

                                    <View style={styles.generalInformationContainer}>

                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('markAs')}
                                        </MyAppText>

                                        <View style={[{
                                            borderColor: theme.colors.borderColor,
                                            borderWidth: 1,
                                            borderRadius: 5,
                                            minWidth: 376
                                        }]}>

                                            {personMarkers.length > 0 &&

                                                <View
                                                    style={[{
                                                        flexDirection: 'row',
                                                        padding: 10,
                                                        borderRadius: 5,
                                                    }]}>

                                                    {personMarkers.map((marker) =>
                                                        <TouchableOpacity
                                                            key={marker.id}
                                                            style={[styles.marker, { backgroundColor: marker.color }]}
                                                            onPress={async () => {
                                                                if (!isEditingMarkers) {
                                                                    return;
                                                                }
                                                                removePersonMarker(marker);
                                                            }
                                                            }>
                                                            <MyAppText style={styles.markerText(marker.color)}>
                                                                {marker.name}
                                                            </MyAppText>
                                                        </TouchableOpacity>
                                                    )}
                                                </View>
                                            }

                                            <View
                                                style={[{
                                                    padding: 10,
                                                    borderRadius: 5,
                                                    marginTop: -5,
                                                }]}>

                                                {!isEditingMarkers ?
                                                    <TouchableOpacity onPress={() => setIsEditingMarkers(true)}>
                                                        <MyAppText style={{
                                                            fontWeight: '500', color: 'grey', textDecorationLine: 'underline'
                                                        }}>
                                                            {translate('editMarkers')}
                                                        </MyAppText>
                                                    </TouchableOpacity> :

                                                    markers.map((marker) =>

                                                        personMarkers.map((item) => item.id).includes(marker.id) ?
                                                            null :
                                                            <TouchableOpacity key={marker.id} onPress={async () => {
                                                                addPersonMarker(marker);
                                                            }}>
                                                                <MyAppText style={{ fontWeight: '500', color: 'grey' }}>
                                                                    {marker.name}
                                                                </MyAppText>
                                                            </TouchableOpacity>

                                                    )}

                                            </View>

                                        </View>
                                    </View>

                                </View>
                            </View>

                            <View style={styles.subTitleContainer}>
                                <MyAppText style={styles.subTitleText}>
                                    {translate('arrestWarrants')}
                                </MyAppText>
                            </View>

                            {/* additional comments */}
                            <View style={{ flexDirection: 'row', marginVertical: 20 }}>
                                <View style={{ marginLeft: 25 }}>
                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('situation')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={situation}
                                        onChangeText={setSituation}
                                    />

                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('arrestWarrantNumber')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={arrestWarrantNumber}
                                        onChangeText={setArrestWarrantNumber}
                                    />

                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('shippingDate')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={shippingDate}
                                        onChangeText={setShippingDate}
                                    />

                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('expirationDate')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={expirationDate}
                                        onChangeText={setExpirationDate}
                                    />

                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('lawsuitNumber')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={lawsuitNumber}
                                        onChangeText={setLawsuitNumber}
                                    />

                                </View>
                                <View style={{ marginLeft: 110 }}>
                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('prisonKind')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={prisonKind}
                                        onChangeText={setPrisonKind}
                                    />

                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('magistrate')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={magistrate}
                                        onChangeText={setMagistrate}
                                    />

                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('issuingBody')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={issuingBody}
                                        onChangeText={setIssuingBody}
                                    />

                                    <MyAppText style={styles.lawsuitsInformationTitleText}>
                                        {translate('county')}
                                    </MyAppText>
                                    <TextInput
                                        placeholder=''
                                        style={styles.lawsuitsInformationInput}
                                        value={lawsuitCity}
                                        onChangeText={setLawsuitCity}
                                    />
                                </View>
                            </View>

                            <View style={styles.subTitleContainer}>
                                <MyAppText style={styles.subTitleText}>
                                    {translate('additionalComments')}
                                </MyAppText>
                            </View>

                            <TextInput
                                multiline={true}
                                numberOfLines={5}
                                placeholder=''
                                value={comment}
                                style={[styles.generalInformationInput, { marginLeft: 25, height: 200, width: 865 }]}
                                onChangeText={setComment}
                            />

                            {/* Zoom image modal */}
                            <Modal
                                animationType='fade'
                                transparent={true}
                                visible={imageZoom !== undefined}
                                onRequestClose={() => setImageZoom(undefined)}>
                                <View style={styles.modalContainer}>
                                    <View style={{
                                        backgroundColor: theme.colors.backgroundColor,
                                        borderRadius: 8,
                                        padding: 35,
                                        alignItems: 'center',
                                        width: 600,
                                        marginLeft: (windowInfo.width / 2) - 300,
                                        marginTop: (windowInfo.height / 2) - 300,
                                    }}>
                                        <TouchableOpacity
                                            style={{ margin: 10, position: 'absolute', top: 10, left: 10 }}
                                            onPress={() => setImageZoom(undefined)}>
                                            <FontAwesomeIcon icon={faTimes} fontSize={15} color={theme.colors.textColor} />
                                        </TouchableOpacity>
                                        <Image
                                            style={{
                                                height: 600,
                                                width: '100%',
                                                borderRadius: 8
                                            }}
                                            resizeMode='contain'
                                            source={{
                                                uri: imageZoom?.imageUrl
                                            }}
                                        />
                                    </View>
                                </View>
                            </Modal>
                        </ScrollView>

                    </View>

                </View>

                <DeleteModal
                    setModalVisible={setDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={name}
                    onSubmit={() => deletePerson(route.params?.id || 0)}
                />

                <DeleteModal
                    setModalVisible={setDeleteFacePictureModalVisible}
                    isModalVisible={isDeleteFacePictureModalVisible}
                    onSubmit={() => facePictureToDelete && deleteFacePicture(facePictureToDelete)}
                />
            </DefaultPageLayout >
        </DefaultPageContainer >
    );
}


const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    subTitleContainer: {
        borderBottomWidth: 1,
        borderColor: theme.colors.labelColor,
        marginBottom: 30,
        paddingBottom: 10
    },
    subTitleText: {
        color: theme.colors.labelColor,
        fontWeight: 'bold',
        fontSize: 16
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        backgroundColor: theme.colors.backgroundColor,
        flex: 1,
    },
    generalInformationContainer: {
        flexDirection: 'row',
        marginBottom: 10
    },
    generalInformationTitleText: {
        width: 200,
        textAlign: 'right',
        marginRight: 15,
        color: theme.colors.labelColor,
        fontSize: 16,
        marginTop: 10
    },
    generalInformationInput: {
        width: 376,
        padding: 10,
        borderColor: theme.colors.borderColor,
        borderWidth: 1,
        height: 40,
        borderRadius: 5,
        // font
        fontSize: 16,
        color: theme.colors.textColor,
        fontFamily: 'Open Sans',
    },
    lawsuitsInformationTitleText: {
        color: theme.colors.labelColor,
        fontSize: 16,
    },
    lawsuitsInformationInput: {
        width: 376,
        padding: 10,
        borderColor: theme.colors.borderColor,
        borderWidth: 1,
        height: 40,
        borderRadius: 5,
        // font
        fontSize: 16,
        color: theme.colors.textColor,
        fontFamily: 'Open Sans',
        marginBottom: 15
    },
    container: {
        flex: 1,
        rowGap: 15
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        flexWrap: 'wrap',
        minHeight: 65
    },
    error: {
        color: 'red',
        padding: 10,
    },
    image: {
        width: '100%',
        height: '100%',
        aspectRatio: 9 / 16,
    },
    marker: {
        borderRadius: 8,
        height: 16,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 8,
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11
    }),
}));
