import { BaseService, Unauthorized, UnexpectedError } from './base-service';

interface SessionValidityTimeParams {
    /**
     * Session validity time in seconds.
     */
    sessionValidityTime: number;
    sessionQuantityLimit: number;
    /**
     * Session validity time in seconds.
     */
    mobileSessionValidityTime: number;
    mobileSessionQuantityLimit: number;
}

class PlatformSettingsService extends BaseService {
    async updateTermsOfUse({ termsOfUse }: { termsOfUse: string; }): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/platform-settings/terms-of-use`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                termsOfUse
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getSession(): Promise<SessionValidityTimeParams> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/platform-settings/sessions`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateSession({ sessionValidityTime, sessionQuantityLimit, mobileSessionQuantityLimit, mobileSessionValidityTime }: SessionValidityTimeParams): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/platform-settings/sessions`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                sessionValidityTime,
                sessionQuantityLimit,
                mobileSessionQuantityLimit,
                mobileSessionValidityTime,
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }
}

export const platformSettingsService = new PlatformSettingsService();
