import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import FilterDropdown from '../../components/Filter/components/FilterDropdown';
import { translate } from '../../services/translate';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
}

function ActingBodiesDropdown({ value, setValue, zIndex }: Props) {
    const [selectableActingBodies, setSelectableActingBodies] = useState<{ label: string, value: number; }[]>([]);

    async function getActingBodies() {
        try {
            const actingBodies = await dispatchService.getActingBodies();
            setSelectableActingBodies([
                { value: 0, label: translate('masculineAll') },
                ...actingBodies.map((actingBody) => {
                    return {
                        value: actingBody.id,
                        label: actingBody.name
                    };
                })
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getActingBodies();
    }, []);

    return (
        <FilterDropdown
            label={translate('actingBody')}
            items={selectableActingBodies}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default ActingBodiesDropdown;
