import React, { useMemo } from 'react';
import { NativeSyntheticEvent, TextInput, TextInputKeyPressEventData, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../MyAppText';

interface Props {
    label?: string;
    value: string;
    placeholder?: string;
    onChange?: (text: string) => void;
    onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
}

function FilterInput({ label, value, onChange, placeholder, onKeyPress }: Props) {
    const { styles } = useStyles(stylesheet);

    const isPlaceholder = useMemo(() => !value && value.length === 0, [value]);

    return (
        <View style={styles.inputContainer}>
            {label ? <MyAppText style={styles.filterText}>{label}</MyAppText> : null}
            <TextInput
                style={styles.filterInput(isPlaceholder)}
                value={value}
                onChangeText={(text) => {
                    onChange?.(text);
                }}
                placeholder={placeholder}
                onKeyPress={onKeyPress}
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    inputContainer: {
        minWidth: 200,
    },

    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },

    filterInput: (isPlaceholder: boolean) => ({
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.backgroundColor,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: theme.colors.borderColor,
        height: 40,
        minHeight: 40,
        fontSize: 16,
        color: isPlaceholder ? theme.colors.placeholder : theme.colors.fieldColor,
        padding: 10
    })
}));

export default FilterInput;
