import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import GoogleMapReact from 'google-map-react';
import ThemeContext from '../context/Theme';

export default function HeatMap({ posList }: { posList: LatLng[]; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles();

    return (
        <View style={styles.mapContainer}>
            <GoogleMapReact
                defaultCenter={{
                    lat: -23.533773,
                    lng: -46.625290
                }}
                heatmap={{
                    positions: posList,
                    options: {}
                }}
                draggable={true}
                defaultZoom={11}
                options={() => ({
                    streetViewControl: true,
                    fullscreenControl: false,
                    zoomControl: true,
                    styles: theme === 'light' || theme == 'lightAlt' ? [{
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        stylers: [{ visibility: 'off' }],
                    }] : [
                        { featureType: 'poi', stylers: [{ visibility: 'off' }] },
                        { featureType: 'transit', stylers: [{ visibility: 'off' }] },
                        { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
                        { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
                        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
                        {
                            featureType: 'administrative.locality',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#d59563' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#38414e' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#212a37' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#9ca5b3' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry',
                            stylers: [{ color: '#746855' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#1f2835' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#f3d19c' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#17263c' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#515c6d' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.stroke',
                            stylers: [{ color: '#17263c' }],
                        },
                    ]
                })}
                yesIWantToUseGoogleMapApiInternals>
            </GoogleMapReact>
        </View >
    );
}

function getStyles() {
    return StyleSheet.create({
        mapContainer: {
            flex: 1
        }
    });
}
