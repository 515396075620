import React from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import 'react-calendar/dist/Calendar.css';
import '../../../styles/date-ranger-picker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onConfirm: () => void;
}

export default function GoBackModal({ onConfirm: onClose, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 400 }]}>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', gap: 16 }}>
                        <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: theme.colors.labelColor }}>{translate('wantToContinue')}?</MyAppText>
                        <MyAppText style={{ color: theme.colors.labelColor }}>{translate('exitDetection')}</MyAppText>
                    </View>
                    <FormActions
                        onSubmit={onClose}
                        onClose={() => setModalVisible(false)}
                        disabled={false}
                        confirmText={'yes'} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 32,
        rowGap: 24,
        maxHeight: 690
    },
    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
}));
