import React from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { translate } from '../../../services/translate';
import MyDataTable from '../../../components/MyDataTable';
import RouteMap from '../../../components/RouteMap';

interface DetectionDetailsProps {
    detectionRoutes: { name: string; cameras: CameraMap[]; }[];
}

export default function DetectionRoutes({ detectionRoutes }: DetectionDetailsProps) {
    const { styles } = useStyles(styleSheet);

    const renderRouteContent = (route: { name: string; cameras: CameraMap[]; }) => {
        return (
            <View style={{ width: '100%', height: 250 }}>
                <RouteMap cameraPositions={route.cameras} />
            </View>
        );
    };

    return (
        <View style={styles.cardList}>
            <MyDataTable
                columns={[
                    {
                        name: translate('name'),
                        selector: row => row.name,
                    }
                ]}
                data={detectionRoutes}
                expandableRowsComponent={({ data }) => renderRouteContent(data)}
                expandableRows={true}
            />
        </View >
    );
}

const styleSheet = createStyleSheet((theme) => ({
    cardList: {
        flex: 1,
        flexDirection: 'row',
        gap: 15,
        borderTopWidth: 1,
        borderColor: theme.colors.dataTable.border,
        rowGap: 8,
        paddingVertical: 10
    }
}));
