import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, TouchableOpacity } from 'react-native';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-native-toast-message';
import { cameraService } from '../../../services/central-api/cameras';
import { ClientError } from '../../../services/central-api/base-service';
import { ScrollView } from 'react-native-web-hover';
import AlertGroupsDropdown from '../../../components/Dropdowns/AlertGroupsDropdown';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../../../components/CustomButton';
import FormInput from '../../../components/formInput';
import CameraTagsDropdown from '../../../components/Dropdowns/CameraTagsDropdown';

interface CameraDetailsParameters {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params: {
            id: string;
        };
    };
}

export default function CameraDetails({ navigation, route, authenticatedUser }: CameraDetailsParameters) {
    const { styles, theme } = useStyles(stylesheet);

    const [isLoading, setIsLoading] = useState(true);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [canEditFields, setCanEditFields] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    // Third party cameras cannot be edited beside tags
    const [isThirdParty, setIsThirdParty] = useState<boolean>(false);

    // fields
    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [cameraTags, setCameraTags] = useState<number[]>([]);
    const [selectedAlertGroupId, setSelectedAlertGroupId] = useState<number>(0);

    async function getCamera(id: string) {
        try {
            setIsLoading(true);
            const camera = await cameraService.getCamera(id);
            setTitle(camera.title);
            setAddress(camera.address);
            setLatitude(String(camera.latitude));
            setLongitude(String(camera.longitude));
            setCameraTags((camera.tags || []).map(t => t.id || 0));
            setIsThirdParty(camera.thirdPartyCamera ? true : false);
        } catch (err) {
            console.error(err);
        } finally {
            setAlreadyLoaded(true);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (authenticatedUser) {
            setCanEditFields(authenticatedUser.isAdmin || authenticatedUser.permissions.camera_management);
        }
    }, [authenticatedUser]);

    useEffect(() => {
        if (route.params.id && !alreadyLoaded) {
            getCamera(route.params.id);
        }
    }, [route.params]);


    useEffect(() => {
        if (title === '') {
            return setIsFormValid(false);
        }
        if (address === '') {
            return setIsFormValid(false);
        }
        if (latitude.trim() === '' || Number.isNaN(Number(latitude))) {
            return setIsFormValid(false);
        }
        if (longitude.trim() === '' || Number.isNaN(Number(longitude))) {
            return setIsFormValid(false);
        }
        return setIsFormValid(true);
    }, [title, address, latitude, longitude]);


    async function save() {
        try {
            setIsLoading(true);
            await cameraService.updateCamera(route.params.id, {
                title,
                address,
                latitude: Number(latitude),
                longitude: Number(longitude),
                tags: cameraTags
            });
            navigation.navigate('CamerasList');
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='cameras' lateralMenu='cameras' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                            <TouchableOpacity onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate('CamerasList')}>
                                <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.primaryButton.background} />
                            </TouchableOpacity>
                            <MyAppText style={{ fontSize: 24, color: theme.colors.labelColor, fontWeight: 'bold' }}>
                                {translate('camera')}
                            </MyAppText>
                        </View>
                        <CustomButton
                            type='secondary'
                            text={translate('watchCamera')}
                            onPress={() => {
                                window.open(`/cameras/${route.params.id}`);
                            }} />
                        {canEditFields ?
                            <CustomButton
                                text={translate('save')}
                                onPress={save}
                                disabled={!isFormValid} />
                            : null
                        }
                    </View>
                    <View style={styles.cardList}>
                        <ScrollView contentContainerStyle={{ flex: 1, rowGap: 15, maxWidth: 400, width: '100%' }}>
                            {isLoading &&
                                <ActivityIndicator size='small' color={theme.colors.loadingColor} />
                            }
                            {isThirdParty ?
                                <MyAppText style={{ fontSize: 12 }}>{translate('thirdPartyCameraWarning')}</MyAppText> : null
                            }
                            <View style={styles.generalInformationContainer}>
                                <FormInput
                                    label={translate('title')}
                                    placeholder=''
                                    value={title}
                                    disabled={!canEditFields || isThirdParty}
                                    onChangeText={setTitle}
                                    invalid={() => title === ''}
                                />
                            </View>
                            <View style={styles.generalInformationContainer}>
                                <FormInput
                                    label={translate('address')}
                                    placeholder=''
                                    value={address}
                                    disabled={!canEditFields || isThirdParty}
                                    onChangeText={setAddress}
                                    invalid={() => address === ''}
                                />
                            </View>
                            <View style={styles.generalInformationContainer}>
                                <FormInput
                                    label={translate('latitude')}
                                    placeholder=''
                                    value={latitude}
                                    disabled={!canEditFields || isThirdParty}
                                    onChangeText={setLatitude}
                                    invalid={() => latitude === ''}
                                />
                            </View>
                            <View style={styles.generalInformationContainer}>
                                <FormInput
                                    label={translate('longitude')}
                                    placeholder=''
                                    value={longitude}
                                    disabled={!canEditFields || isThirdParty}
                                    onChangeText={setLongitude}
                                    invalid={() => longitude === ''}
                                />
                            </View>
                            <CameraTagsDropdown value={cameraTags} setValue={setCameraTags} zIndex={3} />

                            {(authenticatedUser?.permissions.manage_camera_alert_groups || authenticatedUser?.isAdmin) ?
                                <AlertGroupsDropdown
                                    value={selectedAlertGroupId}
                                    setValue={setSelectedAlertGroupId}
                                    zIndex={2} />
                                : null
                            }
                        </ScrollView>
                    </View>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

const stylesheet = createStyleSheet(theme => ({
    generalInformationContainer: {
        minHeight: 40,
    },
    container: {
        flex: 1,
        rowGap: 15
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        flexWrap: 'wrap',
        minHeight: 65
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        padding: 24,
        backgroundColor: theme.colors.backgroundColor,
        flex: 1,
        flexDirection: 'row',
    }
}));
