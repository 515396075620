import { BaseService, Unauthorized, UnexpectedError } from './base-service';

class PtzPriorityService extends BaseService {

    async getPtzPriorities(): Promise<PtzPriority[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/ptz-priorities`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async savePtzPriorities(params: { userId: number; priority: number; }[]) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/ptz-priorities`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async addPtzPriority(params: { userId: number; priority: number; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/ptz-priority`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }
}

export const ptzPriorityService = new PtzPriorityService();
