import React, { useContext } from 'react';
import { Text, TextProps } from 'react-native';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';

export default function MyAppText(props: TextProps) {
    const { theme } = useContext(ThemeContext);
    return <Text {...props} style={[{
        fontFamily: 'Open Sans',
        color: getThemedColor(theme, '#222222')
    }, props.style]}>{props.children}</Text>;
}
