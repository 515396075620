import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import TurnstileModal from './TurnstileModal';
import Toast from 'react-native-toast-message';
import { accessService } from '../../services/central-api/access';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { RouteProp, useRoute } from '@react-navigation/native';
import { AccessParamList } from '../../typings/Params';
import MyDataTable from '../../components/MyDataTable';

export default function Turnstiles({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<AccessParamList, 'Turnstiles'>>();

    const [turnstiles, setTurnstiles] = useState<HikTurnstile[]>([]);
    const [selectedTurnstile, setSelectedTurnstile] = useState<HikTurnstile>();
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState(0);
    const [filters, setFilters] = useState({
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    async function getTurnstiles() {
        try {
            setIsLoading(true);
            const response = await accessService.getHikTurnstiles(filters);
            setTurnstiles(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getTurnstiles();
    }, []);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='turnstiles' lateralMenu='access' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyDataTable
                                columns={[
                                    {
                                        name: translate('name'),
                                        selector: row => row.name,
                                        grow: 2,
                                        wrap: true
                                    },
                                    {
                                        name: translate('userCount'),
                                        selector: row => row.userCount || 0,
                                        center: true
                                    },
                                    {
                                        name: translate('action'),
                                        button: true,
                                        cell: row =>
                                            <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                                <TouchableOpacity onPress={() => {
                                                    setSelectedTurnstile(row);
                                                    setModalVisible(true);
                                                }}>
                                                    <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                </TouchableOpacity>
                                            </View>
                                    }
                                ]}
                                data={turnstiles}
                                progressPending={isLoading}
                            />
                        </View>
                        <Pagination
                            totalItems={totalItems}
                            currentPage={filters.page}
                            pageSize={filters.limit}
                            setPageSize={pageSize => {
                                setFilters(old => ({
                                    ...old,
                                    limit: pageSize,
                                    page: 0,
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    limit: pageSize,
                                    page: 0,
                                });
                            }}
                            setPage={page => {
                                setFilters(old => ({
                                    ...old,
                                    page
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    page: page + 1
                                });
                            }}
                        />
                    </View>

                    {/* Create/Edit Modal */}
                    <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <TurnstileModal
                                    onClose={(success) => {
                                        setSelectedTurnstile(undefined);
                                        setModalVisible(false);
                                        getTurnstiles();
                                        if (success) {
                                            Toast.show({
                                                type: 'sentinelxSuccess',
                                                text1: translate('ActionSuccessfully'),
                                            });
                                        }
                                    }}
                                    turnstileId={selectedTurnstile?.id}
                                />
                            </View>
                        </View>
                    </Modal>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B')
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        deleteButtonsView: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        deleteButtons: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 15,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 10,
            color: getThemedColor(theme, '#FFFFFF')
        },
        red: {
            backgroundColor: 'red',
        },
        cardList: {
            borderWidth: 1,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            // borderColor: theme.colors.dataTable.border,
            // backgroundColor: theme.colors.dataTable.background,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1,
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
    });
}
