import { PaginatedResource } from '../../typings/Paginated';
import { BaseService, ClientError, GenericPaginatedParameters, PartialBy, Unauthorized, UnexpectedError } from './base-service';

export class GuardianAppService extends BaseService {
    async getPaginatedGuardianWoman(params: GenericPaginatedParameters): Promise<PaginatedResource<GuardianWoman>> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/guardian-woman${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getGuardianWomanById(id: number): Promise<GuardianWoman> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/guardian-woman/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async createGuardianWoman(params: Omit<GuardianWoman, 'id'>): Promise<GuardianWoman> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/guardian-woman`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async editGuardianWoman(params: PartialBy<GuardianWoman, 'id'>): Promise<GuardianWoman> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/guardian-woman/${params.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async deleteGuardianWoman(id: number): Promise<void> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/guardian-woman/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

}

export const guardianAppService = new GuardianAppService();
