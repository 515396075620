import React, { useMemo } from 'react';
import { translate } from '../../../services/translate';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';


interface Props {
    value: OccurrenceSituation | 'all';
    setValue: React.Dispatch<React.SetStateAction<OccurrenceSituation | 'all'>>;
    zIndex: number;
}

function StatusDropdown({ value, setValue, zIndex }: Props) {
    const items = useMemo(() => [
        'all',
        'pending',
        'in_service',
        'ongoing',
        'concluded'
    ].map(situation => {
        return { label: translate(situation), value: situation };
    }), []);

    return (
        <FilterDropdown
            label={translate('status')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default StatusDropdown;
