import React, { useState } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';
import { faCalendarCheck, faLocationDot, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import personDefaultPicture from '../../../assets/person-default-picture.png';
import { TextMask } from 'react-native-masked-text';
import OccurrenceVideoModal from '../dispatch/occurrenceForm/OccurrenceVideoModal';
import { gcmAgentService } from '../../services/central-api/gcmAgent';
import getBestContrastColor from '../../services/best-contrast-color';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export function FaceDetectedOccurrence({ occurrence, event, navigation, imageUrl }: { occurrence: Occurrence, event: FacialDetection; navigation: Navigation; imageUrl: string; }) {
    const { styles, theme } = useStyles(stylesheet);
    const [isModalVisible, setModalVisible] = useState(false);

    function PersonInfoInRow({ title, desc }: { title: string; desc: string; }) {
        return <View style={{ flexDirection: 'row', gap: 5 }}>
            <MyAppText style={styles.cardBoldText}>
                {title}:
            </MyAppText>
            <MyAppText style={styles.cardText}>
                {desc}
            </MyAppText>
        </View>;
    }

    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyAppText style={{ fontSize: 16, color: theme.colors.labelColor, fontWeight: 'bold' }}>
                            {translate('nProtocol')}: {occurrence?.id}
                        </MyAppText>
                        <MyAppText> </MyAppText>
                        <MyAppText style={{ fontSize: 14, color: theme.colors.cardDetailText }}>
                            ({translate('team')} {translate(occurrence.OccurrenceUnits?.length && occurrence.OccurrenceUnits[0].OccurrenceUnit.isStarter ? 'starter' : 'support').toLowerCase()})
                        </MyAppText>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={theme.colors.textColor} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertText}>
                    {translate('faceDetectedAlert')}:
                </MyAppText>
                <MyAppText style={styles.alertName}>{event.person?.name}</MyAppText>
                <View style={{ flexDirection: 'row', columnGap: 8, paddingBottom: 21 }}>
                    {event.person?.markers?.map((marker) =>
                        <View key={marker.id} style={[styles.marker, { backgroundColor: marker.color }]}>
                            <MyAppText style={styles.markerText(marker.color)}>
                                {marker.name}
                            </MyAppText>
                        </View>
                    )}
                </View>
                <View style={styles.imageBox}>
                    <Image source={{ uri: imageUrl ?? personDefaultPicture }} style={styles.image} />
                </View>
            </View>
            <View style={styles.card}>
                <View style={{ gap: 15 }}>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faPlay} style={{ width: 22 }} color={theme.colors.iconColor} />
                        <TouchableOpacity onPress={() => setModalVisible(true)}>
                            <MyAppText style={styles.watchVideoText}>
                                {translate('watchVideo')}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>
                    <View style={{ gap: 5 }}>
                        <MyAppText style={styles.cardBoldText}>{translate('visualizedOn')}</MyAppText>
                        <View style={styles.visualizedRow}>
                            <FontAwesomeIcon icon={faLocationDot} color={theme.colors.iconColor} />
                            <MyAppText style={styles.cardText}>{event.camera.title}</MyAppText>
                        </View>
                        <View style={styles.visualizedRow}>
                            <FontAwesomeIcon icon={faCalendarCheck} color={theme.colors.iconColor} />
                            <MyAppText style={styles.cardText}>{moment(event.time).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('occurrenceId')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.id}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    <View>
                        <View style={{ paddingBottom: 30 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('narrative')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{occurrence.narrative}</MyAppText>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={{ paddingBottom: 30, columnGap: 5 }}>
                        <MyAppText style={styles.cardBoldText}>{translate('individualData')}:</MyAppText>
                        <View style={{ flexDirection: 'row', columnGap: 5 }}>
                            <MyAppText style={styles.cardText}>CPF:</MyAppText>
                            <TextMask
                                style={[styles.cardText, { fontFamily: 'Open Sans' }]}
                                value={event.person?.document}
                                type={'cpf'}
                            />
                        </View>
                        <View style={{ flexDirection: 'row', columnGap: 5 }}>
                            <MyAppText style={styles.cardText}>{translate('personRegister')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{event.person?.register}</MyAppText>
                        </View>
                        <View style={{ flexDirection: 'row', columnGap: 5 }}>
                            <MyAppText style={styles.cardText}>{translate('registeredOn')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{moment(event.person?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                        </View>
                    </View>
                    <View>
                        <View style={{ rowGap: 5, paddingBottom: 30 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('additionalComments')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{event.person?.comment}</MyAppText>
                        </View>
                    </View>
                    <View>
                        <View style={{ rowGap: 5 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('arrestWarrants')}:</MyAppText>
                            {event.person?.lawsuits?.map((lawsuit) => (
                                <View key={lawsuit.id} style={{ paddingBottom: 20 }}>
                                    <PersonInfoInRow
                                        title={translate('situation')}
                                        desc={lawsuit.situation}
                                    />
                                    <PersonInfoInRow
                                        title={translate('arrestWarrantNumber')}
                                        desc={lawsuit.arrestWarrantNumber}
                                    />
                                    <PersonInfoInRow
                                        title={translate('shippingDate')}
                                        desc={lawsuit.shippingDate}
                                    />
                                    <PersonInfoInRow
                                        title={translate('expirationDate')}
                                        desc={lawsuit.expirationDate}
                                    />
                                    <PersonInfoInRow
                                        title={translate('lawsuitNumber')}
                                        desc={lawsuit.lawsuitNumber}
                                    />
                                    <PersonInfoInRow
                                        title={translate('prisonKind')}
                                        desc={lawsuit.prisonKind}
                                    />
                                    <PersonInfoInRow
                                        title={translate('magistrate')}
                                        desc={lawsuit.magistrate}
                                    />
                                    <PersonInfoInRow
                                        title={translate('issuingBody')}
                                        desc={lawsuit.issuingBody}
                                    />
                                    <PersonInfoInRow
                                        title={translate('county')}
                                        desc={lawsuit.lawsuitCity}
                                    />
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
            </View>
            <OccurrenceVideoModal
                occurrenceId={occurrence.id || 0}
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                occurrenceTime={new Date(event.time).getTime()}
                apiService={gcmAgentService}

            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    alertText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        fontWeight: 'bold',
    },
    alertName: {
        color: theme.colors.textColor,
        fontSize: 18,
        fontWeight: 'bold',
        paddingBottom: 8,
    },
    card: {
        gap: 20,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.cardBorder,
        backgroundColor: theme.colors.backgroundColor,
        padding: 14,
        display: 'flex',
        alignItems: 'flex-start',
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
    },
    cardText: {
        color: theme.colors.labelColor,
        fontSize: 12,
    },
    cardBoldText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14,
    },
    plateInfoText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 24,
    },
    marker: {
        borderRadius: 8,
        height: 16,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 8,
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11
    }),
    imageBox: {
        height: 314,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 30,
    },
    image: {
        height: 314,
        width: 311,
        objectFit: 'contain',
        borderRadius: 8,
    },
    watchVideoText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        textDecorationLine: 'underline',
    }
}));
