import React, { useState } from 'react';
import { translate } from '../../../services/translate';
import { Modal, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import { WindowInformation } from '../../../services/window-information';
import FormActions from '../../../components/formActions';
import { dispatchService } from '../../../services/central-api/dispatch';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import FormInput from '../../../components/formInput';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    unit?: PaginatedUnit;
}

function FinishTeamModal({ isModalVisible, setModalVisible, unit, onClose }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [reason, setReason] = useState<string>('');

    function isReasonInvalid(value: string) {
        return value == '';
    }
    async function confirmFinish() {
        try {
            if (unit?.id) {
                await dispatchService.finishUnit(unit.id, reason);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setReason('');
                setModalVisible(false);
            }
            onClose();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
                    <MyAppText>{`${translate('finishTeam')}: ${unit?.unitName?.name || ''}`}</MyAppText>
                    <MyAppText>{translate('finishReasonUnit')}:</MyAppText>
                    <FormInput
                        label={translate('reason')}
                        placeholder={translate('reason')}
                        value={reason}
                        onChangeText={setReason}
                        invalid={isReasonInvalid}
                        multiline={true}
                    />
                    <FormActions
                        onSubmit={async () => {
                            setModalVisible(false);
                            await confirmFinish();
                        }}
                        confirmText='finish'
                        onClose={() => {
                            setModalVisible(false);
                            setReason('');
                            onClose();
                        }}
                        disabled={!reason} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));


export default FinishTeamModal;
