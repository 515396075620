import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import 'react-calendar-timeline/lib/Timeline.css';
import Timeline, { TimelineMarkers, CustomMarker, DateHeader, TimelineHeaders } from 'react-calendar-timeline';
import moment from 'moment';
import 'moment/locale/pt-br';
import Toast from 'react-native-toast-message';
import { translate } from '../services/translate';
moment.locale('pt-br');

interface TimelineReference {
    start: number;
    finish: number;
}

const TEN_MINUTES = 10 * 60 * 1000;
const ONE_DAY = 24 * 60 * 60 * 1000;

interface TimelineWrapperProps {
    cursor: number,
    recordList: CameraRecordList,
    onRecordClick: (moment: number) => void;
}

export default function TimelineWrapper({ cursor, recordList, onRecordClick }: TimelineWrapperProps) {
    const [groupedRecordList, setGroupedRecordList] = useState<TimelineReference[]>([]);

    function groupRecordList(originalList: CameraRecord[]): TimelineReference[] {
        const grouped: TimelineReference[] = [];

        const groupDifference = 2000;

        if (originalList.length <= 1) {
            return originalList;
        }

        grouped.push({
            start: originalList[0].start,
            finish: originalList[0].finish
        });

        for (let i = 1; i < originalList.length; i++) {
            const previousItem = grouped.at(-1) || grouped[0];
            const currentItem = originalList[i];

            if (currentItem.start - previousItem.finish < groupDifference) {
                previousItem.finish = currentItem.finish;
            } else {
                grouped.push({
                    start: currentItem.start,
                    finish: currentItem.finish
                });
            }
        }

        return grouped;
    }

    useEffect(() => {
        try {
            setGroupedRecordList(groupRecordList(recordList.videos));
        } catch (error) {
            Toast.show({
                type: 'sentinelxError',
                text1: translate('cannotGetTimelineInformation'),
            });
        }
    }, []);

    const visibleTimeStart = new Date();
    visibleTimeStart.setHours(0, 0, 0, 0);
    const visibleTimeEnd = new Date();
    visibleTimeEnd.setHours(23, 59, 59, 999);

    return (
        <Timeline
            groups={[{
                id: 1,
                title: '',
                height: 50,
            }]}
            items={groupedRecordList.map((item, index) => ({
                id: index + 1,
                group: 1,
                title: '',
                start_time: item.start,
                end_time: item.finish,
                itemProps: {
                    style: {
                        marginTop: 10
                    }
                }
            }))}
            sidebarWidth={0}
            minZoom={TEN_MINUTES}
            maxZoom={ONE_DAY}
            defaultTimeStart={visibleTimeStart}
            defaultTimeEnd={visibleTimeEnd}
            canMove={false}
            canChangeGroup={false}
            canResize={false}
            traditionalZoom={true}
            dragSnap={1000}
            onItemSelect={(itemId, e, time) => {
                onRecordClick(time);
            }}
            timeSteps={{
                second: 10,
                minute: 10,
                hour: 1,
                day: 1,
                month: 1,
                year: 1
            }}
            selected={[]}
        >
            <TimelineHeaders>
                <DateHeader style={styles.headerTop} unit='primaryHeader' />
                <DateHeader style={styles.headerBottom} />

            </TimelineHeaders>
            <TimelineMarkers>
                <CustomMarker date={cursor}>
                    {({ styles }) => (
                        <div style={{ ...styles, zIndex: 100, backgroundColor: 'red' }} />
                    )}
                </CustomMarker>
            </TimelineMarkers>
        </Timeline>
    );
}

const styles = StyleSheet.create({
    headerTop: {
        backgroundColor: '#000028',
    },
    headerBottom: {
        backgroundColor: '#e7e7e7',
        color: '#000000'
    }
});
