import React, { useEffect, useState } from 'react';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';
import { dispatchService } from '../../../services/central-api/dispatch';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    actingBodyId?: number;
    disabled: boolean;
    zIndex: number;
    authenticatedUser?: AuthenticatedUser;
}

function UnitNamesDropdown({ value, setValue, actingBodyId, disabled, zIndex, authenticatedUser }: Props) {
    const [selectableUnitNames, setSelectableUnitNames] = useState<{ label: string, value: number; }[]>([]);

    async function getUnitNames(actingBodyId?: number) {
        try {
            const unitNames = await dispatchService.getUnitNames(actingBodyId);
            setSelectableUnitNames([
                ...unitNames.map((role) => {
                    return { value: role.id, label: role.name };
                })
            ]);

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if ((authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units) && !actingBodyId) {
            setSelectableUnitNames([]);
            return;
        }

        getUnitNames(actingBodyId);
    }, [actingBodyId]);

    return (
        <FilterDropdown
            label={translate('name')}
            items={selectableUnitNames}
            multiple={false}
            setValue={setValue}
            value={value}
            disabled={disabled}
            zIndex={zIndex}
        />
    );
}

export default UnitNamesDropdown;
