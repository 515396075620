import { BaseService, ClientError, ExtendableError, InvalidValue, NotFound, Unauthorized, UnexpectedError } from './base-service';

export class AlreadyActiveUnitWithName extends ExtendableError {
    actingBody: string | undefined;
    constructor(code: string, actingBody: string) {
        super(code);
        this.actingBody = actingBody;
    }
}


export class GcmAgentService extends BaseService {
    /**
     *
     * @throws {ClientError} CantFinishConcludedSituation
     * @throws {InvalidValue}
     */
    async finishOccurrence({ occurrenceId, report, category, subCategory }: { occurrenceId: number; report: string; category?: number; subCategory?: number; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/finish`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ report, category, subCategory })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createOccurrenceAttachment({ occurrenceId, file }: { occurrenceId: number; file: File; }): Promise<OccurrenceReport> {
        const token = await this.getToken();
        const formData = new FormData();
        formData.append('file', file, file.name);

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/attachment`, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: formData,
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async addOccurrenceReport({ occurrenceId, report }: { occurrenceId: number; report: string; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/report`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ report })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }

            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getOccurrenceVideoUrl(occurrenceId: number): Promise<VideoUrl> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/video-url`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgentUnits(): Promise<SimplifiedUnit[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/agent-units-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAssignments(): Promise<DropdownResource[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/agent-assignments`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgentActiveUnit(): Promise<{ unit: SimplifiedUnit | null; assignment: Assignment; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/agent-active-unit`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUnitDisplaceReasonById(displaceReasonId: number): Promise<DisplaceReason> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/displace-reason/${displaceReasonId}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceReports(occurrenceId: number): Promise<OccurrenceReport[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/report-historic`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {ClientError} RequiredToFinishCurrentUnit | AlreadyActiveUnitWithName
     * @returns
     */
    async setAgentActiveUnit(params: { unitId: number, assignmentId: number; }) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/set-agent-unit`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            } else if (resJson.code == 'AlreadyActiveUnitWithName') {
                throw new AlreadyActiveUnitWithName(resJson.code, resJson.actingBody);
            }

            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async setAgentActiveAssignmentUnit(params: { assignmentId: number; }) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/set-agent-assignment`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    /**
     *
     * @returns
     * @throws {ClientError} CannotExitUnitWithOccurrences
     */
    async resetAgentActiveUnit(reason?: string) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/reset-agent-unit`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ reason })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async updateOccurrence(occurrence: {
        id: number;
        involvedVehicles?: InvolvedVehicle[];
        involvedPeople?: InvolvedPerson[];
        involvedObjects?: InvolvedObject[];
    }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrence.id}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(occurrence)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async findPersonFromDocument(document: string): Promise<PersonFromDocument> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-from-document/${document}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findPersonFromNameAndMotherName(name: string, motherName: string): Promise<PersonFromMotherName[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-from-name${this.encodeQueryParams({ name, motherName })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findPersonFromNameAndBirthDate(name: string, birthDate: string): Promise<PersonFromBirthDate[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-from-name-and-birth-date${this.encodeQueryParams({ name, birthDate })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findWarrantFromDocument(document: string): Promise<BasicWarrantInformation[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-warrant-from-document/${document}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findWarrantFromNameAndMotherName(name: string, motherName: string): Promise<BasicWarrantInformation[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-warrant-from-name${this.encodeQueryParams({ name, motherName })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findWarrantFromNameAndBirthDate(name: string, birthDate: string): Promise<BasicWarrantInformation[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-warrant-from-name-and-birth-date${this.encodeQueryParams({ name, birthDate })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findWarrantMoreInformation(id: number): Promise<CompleteWarrantInformation> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-warrant-by-id/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findCarFromPlate(plate: string): Promise<CarFromPlate> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-car-from-plate/${plate}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }
}

export const gcmAgentService = new GcmAgentService();
