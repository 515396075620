import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import GroupModal from './GroupModal';
import { accessService } from '../../services/central-api/access';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';
import { AccessParamList } from '../../typings/Params';
import { RouteProp, useRoute } from '@react-navigation/native';
import MyDataTable from '../../components/MyDataTable';
import CustomButton from '../../components/CustomButton';
import FilterInput from '../../components/Filter/components/FilterInput';

export default function Groups({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<AccessParamList, 'Groups'>>();

    const [groups, setGroups] = useState<GroupData[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<GroupData>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [textFilter, setTextFilter] = useState(route.params.textFilter ?? '');
    const [totalItems, setTotalItems] = useState(0);
    const [filters, setFilters] = useState({
        page: Math.max(+route.params.page - 1, 0),
        textFilter,
        limit: Number(route.params.limit) || 25,
    });

    async function getGroups() {
        try {
            setIsLoading(true);
            const response = await accessService.getGroups(filters);
            setGroups(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function confirmDelete(group: GroupData) {
        try {
            if (group?.id) {
                await accessService.deleteGroup(group.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setDeleteModalVisible(false);
                getGroups();

            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        getGroups();
    }, []);

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            setFilters(old => ({
                ...old,
                textFilter
            }));

            navigation.setParams({
                textFilter
            });
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='groups' lateralMenu='access' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                            <FilterInput
                                label={translate('nameSearch')}
                                value={textFilter ?? ''}
                                onChange={text => {
                                    setTextFilter(text);
                                }}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={{ flex: 1 }} />

                        <View style={styles.buttonsContainer}>
                            <CustomButton
                                onPress={() => {
                                    setSelectedGroup(undefined);
                                    setModalVisible(true);
                                }}
                                text={translate('create')}
                                icon={faPlus}
                            />
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyDataTable
                                columns={[
                                    {
                                        name: translate('name'),
                                        selector: row => row.name,
                                        grow: 2,
                                        wrap: true
                                    },
                                    {
                                        name: translate('actingBody'),
                                        selector: row => row.actingBodyUnit?.actingBody?.name || '',
                                        grow: 2,
                                    },
                                    {
                                        name: translate('unit'),
                                        selector: row => row.actingBodyUnit?.name || '',
                                        grow: 2,
                                    },
                                    {
                                        name: translate('userCount'),
                                        selector: row => row.userCount || 0,
                                        center: true
                                    },
                                    {
                                        name: translate('cameraCount'),
                                        selector: row => row.cameraCount || 0,
                                        center: true
                                    },
                                    {
                                        name: translate('action'),
                                        button: true,
                                        cell: row =>
                                            <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                                <TouchableOpacity onPress={() => {
                                                    setSelectedGroup(row);
                                                    setModalVisible(true);
                                                }}>
                                                    <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => {
                                                    setSelectedGroup(row);
                                                    setDeleteModalVisible(true);
                                                }}>
                                                    <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                </TouchableOpacity>
                                            </View>
                                    }
                                ]}
                                data={groups}
                                progressPending={isLoading}
                            />
                        </View>
                        <Pagination
                            totalItems={totalItems}
                            currentPage={filters.page}
                            pageSize={filters.limit}
                            setPageSize={pageSize => {
                                setFilters(old => ({
                                    ...old,
                                    limit: pageSize,
                                    page: 0,
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    limit: pageSize,
                                    page: 0,
                                });
                            }}
                            setPage={page => {
                                setFilters(old => ({
                                    ...old,
                                    page
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    page: page + 1
                                });
                            }}
                        />
                    </View>

                    {/* Delete Modal */}
                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={String(selectedGroup?.name) || ''}
                        onSubmit={async () => {
                            if (selectedGroup) {
                                await confirmDelete(selectedGroup);
                            }
                        }}
                    />

                    {/* Create/Edit Modal */}
                    <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} >
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <GroupModal
                                    onClose={() => {
                                        setSelectedGroup(undefined);
                                        setModalVisible(false);
                                        getGroups();
                                    }}
                                    groupId={selectedGroup?.id}
                                />
                            </View>
                        </View>
                    </Modal>

                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            zIndex: 2,
            flexWrap: 'wrap',
            gap: 5,
        },
        buttonsContainer: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 10,
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        deleteButtonsView: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        deleteButtons: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 15,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 10,
            color: getThemedColor(theme, '#FFFFFF')
        },
        red: {
            backgroundColor: 'red',
        },
        cardList: {
            borderWidth: 1,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            // borderColor: theme.colors.dataTable.border,
            // backgroundColor: theme.colors.dataTable.background,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1,
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#888',
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
    });
}
