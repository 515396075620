import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../../services/central-api/dispatch';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
}

function OccurrenceTypeDropdown({ value, setValue, zIndex }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);

    async function getOccurrenceTypes() {
        try {
            const response = await dispatchService.getDropDownOccurrenceTypes();
            setItems(response.map(occurrenceType => ({
                label: occurrenceType.name, value: occurrenceType.id!
            })));
        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        getOccurrenceTypes();
    }, []);

    return (
        <FilterDropdown
            label={translate('occurrenceType')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default OccurrenceTypeDropdown;
