import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import {
    View,
    StyleSheet,
} from 'react-native';


import { translate } from '../../services/translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import EventList from '../../components/facial/EventList';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { facialService, GetFacialDetectionsParameters } from '../../services/central-api/facial';

interface LiveAlertsParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

export default function LiveAlerts({ navigation, authenticatedUser }: LiveAlertsParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const loaders = useRef({ didSelectedCamerasLoad: false });

    const [isCameraPickerOpen, setIsCameraPickerOpen] = useState(false);
    const [filters, setFilters] = useState<GetFacialDetectionsParameters>({
        page: 0,
        limit: 100,
        cameras: [],
        onlyWithPerson: true
    });
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const [selectedCameraFilter, setSelectedCameraFilter] = useState<string[]>([]);
    const [selectableCameraFilter, setSelectableCameraFilter] = useState<{ label: string, value: string; }[]>([]);

    async function getCameras() {
        try {
            const cameras = await facialService.getCameras();
            setSelectableCameraFilter(cameras.map((camera) => {
                return {
                    value: camera.id,
                    label: camera.title
                };
            }));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getCameras();
    }, []);

    useEffect(() => {
        if (!loaders.current.didSelectedCamerasLoad) {
            loaders.current.didSelectedCamerasLoad = true;
            return;
        }
        setFilters({
            ...filters,
            cameras: selectedCameraFilter,
        });
    }, [selectedCameraFilter]);

    useEffect(() => {
        getFacialDetections();
        const interval = setInterval(() => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('facial/live-alerts')) {
                return;
            }

            getFacialDetections();
        }, 60000);
        return () => clearInterval(interval);
    }, [filters]);

    const [isLoading, setIsLoading] = useState(false);
    const [occurrences, setOccurrences] = useState<FacialDetection[]>([]);

    async function getFacialDetections() {
        try {
            setIsLoading(true);

            const newOccurrences = await facialService.getDetections(filters);

            if (filters.page == 0) {
                occurrences.length = 0;
            }

            occurrences.push(...newOccurrences);
            setOccurrences(occurrences);
            forceUpdate();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='liveAlerts' lateralMenu='facial' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={{ minHeight: 65 }}>
                        <MyAppText style={styles.filterText}>{translate('cameras')}</MyAppText>
                        <MyDropDownPicker
                            open={isCameraPickerOpen}
                            value={selectedCameraFilter}
                            items={selectableCameraFilter}
                            setOpen={setIsCameraPickerOpen}
                            setValue={setSelectedCameraFilter}
                            multiple={true}
                            mode='BADGE'
                            searchable={true}
                            borderColor={getThemedColor(theme, '#888888')}
                        />
                    </View>
                    <EventList
                        events={occurrences}
                        navigation={navigation}
                        loadMore={() => setFilters({ ...filters, page: filters.page + 1 })}
                        hasMoreResults={false}
                        isLoading={isLoading}
                        isPolling={true}
                        page='liveAlerts'
                    />
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
    });
}
