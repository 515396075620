export default function getBestContrastColor(color: string) {
    const c = color.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rr gg bb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const lum = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    let contrast = '#000000';

    if (lum < 128) {
        contrast = '#FFFFFF';
    }

    return contrast;
}
