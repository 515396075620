import React, { useContext, useEffect, useState } from 'react';
import { Image, Platform, TouchableOpacity, View } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';

interface Props {
    photo?: string;
    setProfilePicture: React.Dispatch<React.SetStateAction<string>>;
}

function ProfilePicture({ photo = '', setProfilePicture }: Props) {
    const [image, setImage] = useState<string>('');
    const { theme } = useContext(ThemeContext);

    const { styles } = useStyles(stylesheet);

    useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                const libraryStatus = await ImagePicker.requestMediaLibraryPermissionsAsync();

                if (libraryStatus.status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }

                const cameraStatus = await ImagePicker.requestCameraPermissionsAsync();
                if (cameraStatus.status !== 'granted') {
                    alert('Sorry, we need camera permissions to make this work!');
                }
            }
        })();
    }, []);

    useEffect(() => {
        setImage(photo);
    }, [photo]);

    const pickImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.canceled) {
            setImage(result.assets[0].uri);
            setProfilePicture(result.assets[0].uri);
        }
    };

    return (
        <View style={styles.container}>
            {image ?
                <Image source={{ uri: image }} style={styles.image} />
                :
                <View style={styles.empty}>
                    <FontAwesomeIcon icon={faCircleUser} color={getThemedColor(theme, '#000000')} fontSize={200} />
                </View>
            }

            <TouchableOpacity onPress={pickImage} style={styles.button}>
                <MyAppText style={styles.text}>{translate('updatePictureButtonLabel')}</MyAppText>
            </TouchableOpacity>
        </View>
    );
}

const stylesheet = createStyleSheet((theme) => ({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    image: {
        width: 200,
        height: 200,
        marginBottom: 20,
        borderRadius: 100
    },

    empty: {
        marginBottom: 20,
    },

    button: {
        borderWidth: 0,
        borderRadius: 2,
        backgroundColor: theme.colors.primaryButton.background,
        width: 128,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center'
    },

    text: {
        color: theme.colors.color
    }
}));

export default ProfilePicture;
