import React from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { MyCheckbox } from '../../MyCheckbox';

interface Props {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
}

function FilterCheckBox({ label, value, onChange }: Props) {
    const { styles } = useStyles(stylesheet);

    return (
        <View style={styles.inputContainer}>
            <MyCheckbox
                checkBoxStyle={styles.checkbox}
                textStyle={styles.filterText}
                label={label}
                checked={value}
                setChecked={onChange}
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    inputContainer: {
        flexGrow: 1,
        minWidth: 200,
    },

    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
    checkbox: {
        width: 20,
        height: 20
    }
}));

export default FilterCheckBox;
