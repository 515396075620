import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import { accessService } from '../../services/central-api/access';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import MyPhoneInput from '../../components/MyPhoneInput';
import { WindowInformation } from '../../services/window-information';

export default function InstallationCompanyModal({ onClose, installationCompanyId }: { onClose: () => void, installationCompanyId?: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState<string>('');

    async function getInstallationCompany(id: number) {
        const installationCompany = await accessService.getInstallationCompany(id);
        setName(installationCompany.name);
        setPhone(installationCompany.phone);
    }

    async function loadPageInfo() {
        try {
            if (installationCompanyId) {
                await getInstallationCompany(installationCompanyId);
            }
        } catch (err) {
            console.error(err);
        }
    }

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isPhoneInvalid(value: string) {
        return value == '' || value.length < 10;
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    function isFormValid() {
        return !isNameInvalid(name) && !isPhoneInvalid(phone);
    }

    async function formSubmit() {
        try {
            if (installationCompanyId) {
                await accessService.updateInstallationCompany({
                    id: installationCompanyId,
                    name,
                    phone
                });
            } else {
                await accessService.createInstallationCompany({ name, phone });
            }
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
            <View style={{ rowGap: 10 }}>
                <FormInput
                    label={translate('name')}
                    placeholder={translate('name')}
                    value={name}
                    onChangeText={setName}
                    invalid={isNameInvalid}
                />
                <MyPhoneInput
                    label={translate('phone')}
                    placeholder={translate('phone')}
                    value={phone}
                    onChangeText={setPhone}
                    invalid={isPhoneInvalid}
                />
            </View>
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            minWidth: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            minHeight: 200,
            rowGap: 30
        },
    });
}
