import React, { useEffect, useState } from 'react';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import { TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import { useNavigationState } from '@react-navigation/native';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Tabs } from '../../../components/Tabs';
import DetectionDetails from './DetectionDetails';
import Detections from './Detections';
import DetectionRoutes from './DetectionRoutes';
import GoBackModal from './GoBackModal';

interface DetectionProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params: {
            detectionId: string;
            page: 'liveEvents' | 'liveAlerts' | 'search' | 'map';
        };
    };
}

function DetectionDetailPageLayout({ navigation, route, authenticatedUser, children }: DetectionProps & { children: React.ReactNode | undefined; }) {
    if (route.params.page === 'map') {
        return <DefaultPageLayout navigation={navigation} selectedMenu={route.params.page} lateralMenu={'cameras'} authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
            {children}
        </DefaultPageLayout>;
    }
    return <DefaultPageLayout navigation={navigation} selectedMenu={route.params.page} lateralMenu={'lpr'} authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
        {children}
    </DefaultPageLayout>;
}

export default function Detection({ navigation, route, authenticatedUser }: DetectionProps) {
    const { styles, theme } = useStyles(styleSheet);

    const [detection, setDetection] = useState<LprDetection>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [detectionRoutes, setDetectionRoutes] = useState<{ name: string; cameras: CameraMap[]; }[]>([]);

    const index = useNavigationState(state => state);

    async function getDetection(detectionId: string) {
        try {
            const detection = await licensePlateRecognitionService.getLprDetection(Number(detectionId));
            setDetection(detection);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getDetection(route.params.detectionId);
    }, [route]);


    if (!detection) {
        return <DefaultPageContainer>
            <DetectionDetailPageLayout navigation={navigation} route={route} authenticatedUser={authenticatedUser}>
                <></>
            </DetectionDetailPageLayout>
        </DefaultPageContainer>;
    }

    function goBack() {
        if (index.routes.length > 1) {
            return navigation.goBack();
        } else {
            if (route.params.page == 'liveAlerts') {
                window.location.href = '/lpr/live-alerts';
            }
            if (route.params.page == 'liveEvents') {
                window.location.href = '/lpr/live-events';
            }
            if (route.params.page == 'search') {
                window.location.href = '/lpr/search';
            }
            if (route.params.page == 'map' && detection) {
                window.location.href = `/cameras/${detection.camera.id}`;
            }
        }
    }

    return (
        <DefaultPageContainer>
            <DetectionDetailPageLayout navigation={navigation} route={route} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <TouchableOpacity onPress={() => {
                            if (detectionRoutes.length) {
                                setIsModalVisible(true);
                            } else {
                                goBack();
                            }
                        }}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.iconColor} />
                        </TouchableOpacity>
                        <MyAppText style={styles.headerText}>
                            {translate('detection')}
                        </MyAppText>
                    </View>
                    <View style={styles.cardList}>
                        <Tabs tabs={[{
                            key: 'vehicleData',
                            label: translate('vehicleData')
                        }, {
                            key: 'detections',
                            label: translate('detections')
                        }, {
                            key: 'routes',
                            label: translate('routes')
                        }]}>
                            {({ selectedTab }) => {
                                if (selectedTab == 'vehicleData') {
                                    return (<DetectionDetails navigation={navigation} detection={detection} onSave={() => getDetection(route.params.detectionId)} />);
                                } else if (selectedTab == 'detections') {
                                    return (<Detections setDetectionRoutes={setDetectionRoutes} detectionRoutes={detectionRoutes} detection={detection} />);
                                } else if (selectedTab == 'routes') {
                                    return (<DetectionRoutes detectionRoutes={detectionRoutes} />);
                                }
                            }}
                        </Tabs>
                    </View>
                    <GoBackModal
                        isModalVisible={isModalVisible}
                        setModalVisible={setIsModalVisible}
                        onConfirm={() => goBack()}
                    />
                </View>
            </DetectionDetailPageLayout>
        </DefaultPageContainer >
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    header: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'center'
    },
    headerText: {
        fontSize: 24,
        color: theme.colors.labelColor,
        fontWeight: 'bold'
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        padding: 15,
        flex: 1,
    }
}));
