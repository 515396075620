import React, { useMemo } from 'react';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';

interface Props {
    value: CameraType | 'all';
    setValue: React.Dispatch<React.SetStateAction<CameraType | 'all'>>;
    zIndex: number;
}

function CameraTypeDropdown({ value, setValue, zIndex }: Props) {
    const items = useMemo(() => [
        'all',
        'ptz',
        'lpr',
        'pinned',
        'panoramic'
    ].map(type => {
        return { label: translate(type), value: type };
    }), []);

    return (
        <FilterDropdown
            label={translate('cameraType')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default CameraTypeDropdown;
