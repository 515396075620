import React, { useEffect, useState } from 'react';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';
import { dispatchService } from '../../../services/central-api/dispatch';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    actingBodyId: number | null;
    disabled: boolean;
    zIndex: number;
}

function EquipmentsDropdown({ value, setValue, actingBodyId, disabled, zIndex }: Props) {
    const [selectableEquipments, setSelectableEquipments] = useState<{ label: string, value: number; }[]>([]);

    async function getEquipments({ actingBodyId }: { actingBodyId: number; }) {
        try {
            const equipments = await dispatchService.getSimplifiedEquipments(actingBodyId);
            setSelectableEquipments(equipments.map((item: DropdownResource) => {
                return {
                    label: item.name, value: item.id
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId) {
            getEquipments({ actingBodyId });
        }
    }, [actingBodyId]);

    return (
        <FilterDropdown
            label={translate('equipments')}
            items={selectableEquipments}
            multiple={true}
            setValue={setValue}
            value={value}
            disabled={disabled}
            zIndex={zIndex}
            mode='BADGE'
        />
    );
}

export default EquipmentsDropdown;
