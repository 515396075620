import React, { useCallback, useContext, useState } from 'react';
import { TouchableOpacity, View, StyleSheet, ActivityIndicator, FlatList, ScrollView, TextInput } from 'react-native';
import MyAppText from './MyAppText';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from './DeleteModal';
import { HexColorPicker } from 'react-colorful';
import { translate } from '../services/translate';
import CustomButton from './CustomButton';

interface TagsParameters {
    tags: Tag[];
    setTags: (value: Tag[]) => void;
    getTags: () => Promise<void>;
    deleteTag: (tag: Tag) => Promise<void>;
    createTag: (params: CreateTag) => Promise<Tag>;
    updateTag: (id: number, params: UpdateTag) => Promise<void>;
    loading: boolean;
    hasRequiredPermission?: boolean;
    associatedLabel?: string;
}

export function TagPageContent({ tags, setTags, getTags, loading, deleteTag, createTag, updateTag, hasRequiredPermission = false, associatedLabel }: TagsParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [tagToDelete, setTagToDelete] = useState<Tag & { dirty?: boolean; activated?: boolean; }>();
    const [, setColor] = useState('#aabbcc');
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);


    function addItem() {
        setTags(tags.concat({
            name: '',
            color: `#${Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0')}`
        }));
    }

    function renderItem({ item }: { item: Tag & { dirty?: boolean; activated?: boolean; }; }) {
        return (
            <View style={{ flexDirection: 'column', rowGap: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 8 }}>
                    <TouchableOpacity
                        style={styles.circle}
                        disabled={!hasRequiredPermission}
                        onPress={async () => {
                            item.activated = !item.activated;
                            forceUpdate();
                        }}>
                        <View style={[styles.circle, { backgroundColor: item.color }]}></View>
                    </TouchableOpacity>

                    <TextInput
                        editable={hasRequiredPermission}
                        style={styles.input}
                        placeholder=''
                        value={item.name}
                        onChangeText={(value) => {
                            item.dirty = true;
                            item.name = value;
                            forceUpdate();
                        }}
                    />

                    <TouchableOpacity onPress={() => {
                        if (item.id) {
                            setTagToDelete(item);
                            setIsDeleteModalVisible(true);
                        } else {
                            setTags(tags.filter(tag => tag.name !== item.name || tag.color !== item.color));
                        }
                    }} disabled={!hasRequiredPermission}>
                        <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                    </TouchableOpacity>

                    {item.dirty ?
                        <TouchableOpacity disabled={!hasRequiredPermission} onPress={async () => {
                            try {
                                if (!item.id) {
                                    const newTag = await createTag({ name: item.name, color: item.color });
                                    item.id = newTag.id;
                                } else {
                                    await updateTag(item.id, { name: item.name, color: item.color });
                                }
                            } catch (err) {
                                console.error(err);
                            } finally {
                                item.dirty = false;
                                item.activated = false;
                                forceUpdate();
                                if (!tags.some(tag => !tag.id)) {
                                    await getTags();
                                }
                            }
                        }}>
                            <FontAwesomeIcon icon={faCheck} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                        </TouchableOpacity> : null
                    }

                    {item.id && associatedLabel ? <MyAppText>{associatedLabel}: {item.associatedCount ?? 0} </MyAppText> : null}
                </View >

                {item.activated ?
                    <HexColorPicker color={item.color} onChange={(newColor: string) => {
                        setColor(newColor);
                        item.color = newColor;
                        item.dirty = true;
                    }} /> : null
                }
            </View >
        );
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ justifyContent: 'flex-end' }}>
                    {hasRequiredPermission
                        ? <CustomButton onPress={() => addItem()} text={translate('create')} icon={faPlus} />
                        : null
                    }
                </View>
            </View>
            <View style={styles.cardList}>
                <ScrollView>
                    {loading ?
                        <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                        : <FlatList contentContainerStyle={{ rowGap: 10, flex: 1, padding: 24 }} renderItem={renderItem} data={tags} />
                    }
                </ScrollView>
            </View>
            <DeleteModal onSubmit={async () => {
                try {
                    if (tagToDelete?.id) {
                        await deleteTag(tagToDelete);
                        await getTags();
                        tagToDelete.dirty = false;
                        forceUpdate();
                    }
                } catch (err) {
                    console.error(err);
                }
            }} isModalVisible={isDeleteModalVisible} itemName={tagToDelete?.name} setModalVisible={() => setIsDeleteModalVisible(false)} />
        </View>);
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 20,
            flex: 1,
        },
        input: {
            height: 32,
            width: 300,
            borderColor: getThemedColor(theme, '#58595B'),
            borderRadius: 4,
            borderWidth: 1,
            padding: 8,
            fontFamily: 'Open Sans',
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
        },
        circle: {
            borderRadius: 30,
            height: 16,
            width: 16,
        },
        newButton: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            columnGap: 5
        },
        newButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
    });
}
