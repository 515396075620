import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import ActingBodiesCommandsDropdown from '../../../components/Dropdowns/ActingBodiesCommandsDropdown';

interface Props {
    filters: DispatchParamList['TeamNames'];
    setFilters: React.Dispatch<React.SetStateAction<DispatchParamList['TeamNames']>>;
    authenticatedUser?: AuthenticatedUser;
    textFilter: string;
    setTextFilter: (value: string) => void;
}

function Filter({ authenticatedUser, filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<DispatchParamList, 'TeamNames'>>>();

    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [actingBodyCommandIds, setActingBodyCommandIds] = useState<number[]>(filters.actingBodyCommandIds ?? []);

    const handleCleanFilter = () => {
        setTextFilter('');
        setActingBodyId(0);
        setActingBodyCommandIds([]);
    };

    const handleFilter = () => {
        navigation.setParams({
            ...filters,
            page: 1,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
        });

        setFilters({
            ...filters,
            page: 1,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_unit_names) &&
                <ActingBodiesDropdown includeAllOption={true} value={actingBodyId} setValue={setActingBodyId} zIndex={3} />
            }

            <ActingBodiesCommandsDropdown
                value={actingBodyCommandIds}
                actingBodyId={(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_unit_names) ? actingBodyId : authenticatedUser?.actingBodyUnit.actingBodyId ?? 0}
                setValue={setActingBodyCommandIds}
                zIndex={2}
            />

        </Filters>
    );
}


export default Filter;
