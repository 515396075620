import React, { useEffect, useRef, useState } from 'react';
import { Animated, ImageSourcePropType, Pressable, ScrollView, Text, View } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import { WindowInformation } from '../services/window-information';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { faChevronLeft, faChevronRight, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../services/translate';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../typings/Params';
import UserInformationModal from './UserInformationModal';

interface Item {
    icon: IconDefinition;
    action?: () => void;
    text: string;
    selected: boolean;
    style?: React.CSSProperties;
    children?: Item[];
}

interface Props {
    items: Item[];
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    authenticatedUser?: AuthenticatedUser;
    goBack: () => void;
    topImage?: ImageSourcePropType;
    collapsedTopImage?: ImageSourcePropType;
}

const EXPANDED_SIDEBAR_WIDTH = 250;
const SHORTENED_SIDEBAR_WIDTH = 64;

const EXPANDED_LEFT_POS_BUTTON = 235;
const SHORTENED_LEFT_POS_BUTTON = 50;


function LateralMenuV2({ items, open, authenticatedUser, goBack, setOpen, topImage, collapsedTopImage }: Props) {
    const { styles, theme } = useStyles(stylesheet);

    const [showUserDropdown, setShowUserDropdown] = useState(false);

    const windowInfo = WindowInformation();

    const navigation = useNavigation<NavigationProp<RootStackParamList>>();

    //Desktop animations
    const animationSidebar = useRef(new Animated.Value(open ? EXPANDED_SIDEBAR_WIDTH : SHORTENED_SIDEBAR_WIDTH)).current;
    const animationButton = useRef(new Animated.Value(open ? EXPANDED_LEFT_POS_BUTTON : SHORTENED_LEFT_POS_BUTTON)).current;

    const onExpandSidebar = (width: number) => {
        Animated.timing(animationSidebar, {
            toValue: width,
            duration: 200,
            useNativeDriver: true
        }).start();
    };

    const onExpandButton = (left: number) => {
        Animated.timing(animationButton, {
            toValue: left,
            duration: 200,
            useNativeDriver: true
        }).start();
    };

    //Mobile animations
    const animationSidebarMobile = useRef(new Animated.Value(0)).current;
    const animationTransXMobile = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(animationSidebarMobile, {
            toValue: open ? EXPANDED_SIDEBAR_WIDTH : 0,
            duration: 200,
            useNativeDriver: true
        }).start();

        Animated.timing(animationTransXMobile, {
            toValue: open ? 0 : -EXPANDED_SIDEBAR_WIDTH,
            duration: 200,
            useNativeDriver: true
        }).start();
    }, [open]);

    return (
        <Animated.View
            style={[
                windowInfo.isMobile ? styles.mobileSidebar : styles.sidebar,
                windowInfo.isMobile ? {
                    transform: [{
                        translateX: animationTransXMobile
                    }], width: animationSidebarMobile
                } : { width: animationSidebar }
            ]}
        >
            {windowInfo.isMobile && (
                <Pressable onPress={() => setShowUserDropdown(true)} style={{ width: '100%', paddingLeft: 24 }}>
                    <View style={styles.userContainer}>
                        <View style={styles.userFrame}>
                            <FontAwesomeIcon color='#ffffff' icon={faUser} />
                        </View>

                        <Text numberOfLines={1} style={{ color: '#ffffff' }}>{authenticatedUser?.name}</Text>
                    </View>

                    <View style={styles.divider} />
                </Pressable>
            )}

            <View style={{
                paddingTop: 15,
                width: '100%',
                paddingBottom: windowInfo.isMobile ? 15 : 0
            }}>
                <View style={{
                    height: 24,
                    width: '100%',
                }}>
                    {
                        topImage && collapsedTopImage ?
                            open ? <img src={topImage as string} style={styles.imageContent('expanded')} />
                                : <img src={collapsedTopImage as string} style={styles.imageContent('collapsed')} />
                            : null
                    }
                </View>
            </View>

            <ScrollView
                style={[styles.itemContainer, { width: '90%', flex: 1, paddingBottom: 10 }]}
                contentContainerStyle={{ gap: 5, flex: 1 }}
            >
                {items.map(item => {
                    return (
                        <Pressable
                            onPress={() => {
                                item.action?.();
                            }}
                            key={item.text}
                            style={{ width: '100%' }}
                        >
                            <Hoverable>
                                {({ hovered }) => (
                                    <View
                                        style={[
                                            styles.itemButton(hovered, item.selected),
                                            open ? { paddingLeft: 15 } : { justifyContent: 'center' }
                                        ]}
                                    >
                                        <FontAwesomeIcon color={item.selected ? theme.colors.sidebar.item.textSelected : theme.colors.sidebar.item.text} icon={item.icon} width={16} />

                                        {open &&
                                            <Text style={styles.itemText(item.selected)}>{item.text}</Text>
                                        }
                                    </View>
                                )}
                            </Hoverable>
                        </Pressable>
                    );
                })}
            </ScrollView>

            <View style={[styles.itemContainer, { width: '90%', paddingBottom: 10 }]}>
                <Pressable
                    onPress={goBack}
                    style={{ width: '100%' }}
                >
                    <Hoverable>
                        {({ hovered }) => (
                            <View
                                style={[
                                    styles.itemButton(hovered, false),
                                    open ? { paddingLeft: 15 } : { justifyContent: 'center' }
                                ]}
                            >
                                <FontAwesomeIcon color={theme.colors.sidebar.item.text} icon={faRightFromBracket} transform={{ rotate: 180 }} />

                                {open &&
                                    <Text style={styles.itemText(false)}>{translate('goBack')}</Text>
                                }
                            </View>
                        )}
                    </Hoverable>
                </Pressable>
            </View>

            {!windowInfo.isMobile &&
                <Animated.View style={[styles.expandableButton, { left: animationButton }]}>
                    <Pressable
                        onPress={() => {
                            onExpandButton(!open ? EXPANDED_LEFT_POS_BUTTON : SHORTENED_LEFT_POS_BUTTON);
                            onExpandSidebar(!open ? EXPANDED_SIDEBAR_WIDTH : SHORTENED_SIDEBAR_WIDTH);
                            setOpen(!open);
                        }}
                        style={{ width: '100%', aspectRatio: 1, justifyContent: 'center' }}
                    >
                        <FontAwesomeIcon color='#FFFFFF' size='xs' icon={open ? faChevronLeft : faChevronRight} />
                    </Pressable>
                </Animated.View>
            }

            <UserInformationModal acceptedTermsOfUse={authenticatedUser?.acceptedTermsOfUse || false}
                showDropDown={showUserDropdown} setShowDropDown={setShowUserDropdown} navigation={navigation} />
        </Animated.View>
    );
}

const stylesheet = createStyleSheet((theme) => ({
    sidebar: {
        backgroundColor: theme.colors.sidebar.background,
        alignItems: 'center',
        width: EXPANDED_SIDEBAR_WIDTH,
        position: 'relative',
        flex: 1,
        gap: 20,
        paddingVertical: 10
    },

    mobileSidebar: {
        backgroundColor: theme.colors.sidebar.background,
        alignItems: 'center',
        width: EXPANDED_SIDEBAR_WIDTH,
        position: 'absolute',
        zIndex: 10,
        height: '100%'
    },

    expandableButton: {
        width: 26,
        aspectRatio: 1,
        backgroundColor: theme.colors.sidebar.background,
        borderRadius: 50,
        position: 'absolute',
        top: 10,
        zIndex: 200,
        elevation: 200,
    },

    divider: {
        borderBottomColor: '#3C3C3D',
        borderBottomWidth: 1,
        paddingBottom: 15,
        width: 144,
    },
    logo: {
        height: 29,
        width: 144,
        resizeMode: 'contain'
    },

    itemContainer: {
        alignContent: 'center',
        gap: 10,
    },

    itemText: (selected: boolean) => ({
        color: selected ? theme.colors.sidebar.item.textSelected : theme.colors.sidebar.item.text,
        fontSize: 16,
        fontWeight: '600'
    }),

    itemButton: (hovered: boolean, selected: boolean) => ({
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10,
        height: 40,
        borderRadius: 10,
        backgroundColor: selected ? theme.colors.sidebar.item.selectedBackground : (hovered ? theme.colors.sidebar.item.hoverBackground : undefined)
    }),

    userFrame: {
        width: 32,
        aspectRatio: 1,
        borderRadius: 50,
        backgroundColor: '#CCCCCC',
        justifyContent: 'center',
        alignItems: 'center',
    },

    userName: {
        color: theme.colors.textColor
    },

    userContainer: {
        paddingTop: 15,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        width: '100%',
    },

    imageContent: (type: 'collapsed' | 'expanded') => {
        return {
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'left center',
            paddingLeft: type === 'collapsed' ? 21 : 25
        };
    }
}));


export default LateralMenuV2;
