import React, { useMemo } from 'react';
import { translate } from '../../../services/translate';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';

interface Props {
    value: TriggerType | 'all' | 'manual';
    setValue: React.Dispatch<React.SetStateAction<TriggerType | 'all' | 'manual'>>;
    zIndex: number;
}

function TypeDropdown({ value, setValue, zIndex }: Props) {
    const items = useMemo(() => [
        'all',
        'face_detected',
        'plate_detected',
        'irregular_vehicle',
        'area_invasion',
        'mp_plate_detected',
        'mp_face_detected',
        'alarm_center',
        'manual',
        'guardian_app'
    ].map(triggerType => {
        return { label: translate(triggerType), value: triggerType };
    }), []);

    return (
        <FilterDropdown
            label={translate('type')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default TypeDropdown;
