import React, { useContext, useEffect, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import { Level } from '@tiptap/extension-heading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faBold, faChevronDown, faHeading, faItalic, faListNumeric, faListUl, faParagraph, faRedo, faStrikethrough, faUnderline, faUndo } from '@fortawesome/free-solid-svg-icons';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';
import '../styles/richtext-editor.css';
import MyAppText from './MyAppText';
import { translate } from '../services/translate';

type iconType = 'bold' | 'italic' | 'strike' | 'underline' | 'orderedList' | 'bulletList' | 'paragraph' | 'heading' | { textAlign: 'left' | 'right' | 'center' | 'justify'; };

const headings: Level[] = [1, 2, 3, 4, 5, 6];

function getIconColor(icon: iconType, headingLevel?: number) {
    const { editor } = useCurrentEditor();
    const { theme } = useContext(ThemeContext);

    if (!editor) {
        return '';
    }

    return (icon !== 'heading' ? editor.isActive(icon) : editor.isActive('heading', { level: headingLevel }))
        ? getThemedColor(theme, '#FFFFFF')
        : getThemedColor(theme, '#222222');
}

function getIconBackgroundColor(icon: iconType, headingLevel?: number) {
    const { editor } = useCurrentEditor();
    const { theme } = useContext(ThemeContext);

    if (!editor) {
        return;
    }

    return {
        backgroundColor: (icon !== 'heading' ? editor.isActive(icon) : editor.isActive('heading', { level: headingLevel }))
            ? getThemedColor(theme, '#58595B')
            : getThemedColor(theme, '#FFFFFF')
    };
}

function MenuBar({ theme }: { theme: Theme; }) {
    const { editor } = useCurrentEditor();
    const [alignModalVisible, setAlignModalVisible] = useState(false);
    const [formatModalVisible, setFormatModalVisible] = useState(false);

    if (!editor) {
        return null;
    }

    const styles = getStyles(theme);


    return (<div id='richTextEditorMenuView'>
        <View style={styles.menuView} >
            <TouchableOpacity
                onPress={() => editor.chain().focus().undo().run()}
                style={styles.button}
                disabled={!editor.can()
                    .chain()
                    .focus()
                    .undo()
                    .run()}
            >
                <FontAwesomeIcon color={!editor.can()
                    .chain()
                    .focus()
                    .undo()
                    .run() ? getThemedColor(theme, '#CCCCCC') : getThemedColor(theme, '#222222')} icon={faUndo} />
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => editor.chain().focus().redo().run()}
                style={styles.button}
                disabled={!editor.can()
                    .chain()
                    .focus()
                    .redo()
                    .run()}
            >
                <FontAwesomeIcon color={!editor.can()
                    .chain()
                    .focus()
                    .redo()
                    .run() ? getThemedColor(theme, '#CCCCCC') : getThemedColor(theme, '#222222')} icon={faRedo} />
            </TouchableOpacity>

            {/* Format text */}
            <View>
                {formatModalVisible ? <View style={{
                    position: 'absolute',
                    backgroundColor: getThemedColor(theme, '#FFFFFF'),
                    borderColor: getThemedColor(theme, '#58595B'),
                    borderWidth: 1,
                    width: 130,
                    top: 30,
                    padding: 5,
                    gap: 3
                }}>
                    <TouchableOpacity
                        style={[styles.row, getIconBackgroundColor('paragraph')]}
                        onPress={() => {
                            editor.chain().focus().setParagraph().run();
                            setFormatModalVisible(false);
                        }}
                    >
                        <FontAwesomeIcon color={getIconColor('paragraph')} icon={faParagraph} />
                        <MyAppText style={{ color: getIconColor('paragraph') }}>{translate('paragraph')}</MyAppText>
                    </TouchableOpacity>

                    {headings.map((heading) => (
                        <TouchableOpacity
                            key={`option-${heading}`}
                            style={[styles.row, getIconBackgroundColor('heading', heading)]}
                            onPress={() => {
                                editor.chain().focus().toggleHeading({ level: heading }).run();
                                setFormatModalVisible(false);
                            }}
                        >
                            <FontAwesomeIcon fontSize={18 - (heading * 2)} color={getIconColor('heading', heading)} icon={faHeading} />
                            <MyAppText style={{ color: getIconColor('heading', heading) }}>{translate('heading')} {heading}</MyAppText>
                        </TouchableOpacity>
                    ))}

                </View> : null}
                <TouchableOpacity
                    onPress={() => setFormatModalVisible(!formatModalVisible)}
                    style={[styles.button, styles.centeredRow]}
                >
                    {editor.isActive('paragraph') ? <MyAppText>{translate('paragraph')}</MyAppText> : null}
                    {headings.map((heading) => editor.isActive('heading', { level: heading }) ? <MyAppText key={`format-${heading}`}>{translate('heading')} {heading}</MyAppText> : null)}
                    <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faChevronDown} fontSize={12} />
                </TouchableOpacity>
            </View>
            <TouchableOpacity
                onPress={() => editor.chain().focus().toggleBold().run()}
                style={[styles.button, getIconBackgroundColor('bold')]}
            >
                <FontAwesomeIcon color={getIconColor('bold')} icon={faBold} />
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => editor.chain().focus().toggleItalic().run()}
                style={[styles.button, getIconBackgroundColor('italic')]}
            >
                <FontAwesomeIcon color={getIconColor('italic')} icon={faItalic} />
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => editor.chain().focus().toggleUnderline().run()}
                style={[styles.button, getIconBackgroundColor('underline')]}
            >
                <FontAwesomeIcon color={getIconColor('underline')} icon={faUnderline} />
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => editor.chain().focus().toggleStrike().run()}
                style={[styles.button, getIconBackgroundColor('strike')]}
            >
                <FontAwesomeIcon color={getIconColor('strike')} icon={faStrikethrough} />
            </TouchableOpacity>

            {/* Text Align */}
            <View>
                {alignModalVisible ? <View style={{
                    position: 'absolute',
                    backgroundColor: getThemedColor(theme, '#FFFFFF'),
                    borderColor: getThemedColor(theme, '#58595B'),
                    borderWidth: 1,
                    top: 30,
                    padding: 5,
                    gap: 3
                }}>
                    <TouchableOpacity
                        style={[styles.row, getIconBackgroundColor({ textAlign: 'left' })]}
                        onPress={() => {
                            editor.chain().focus().setTextAlign('left').run();
                            setAlignModalVisible(false);
                        }}
                    >
                        <FontAwesomeIcon color={getIconColor({ textAlign: 'left' })} icon={faAlignLeft} />
                        <MyAppText style={{ color: getIconColor({ textAlign: 'left' }) }}>{translate('left')}</MyAppText>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[styles.row, getIconBackgroundColor({ textAlign: 'center' })]}
                        onPress={() => {
                            editor.chain().focus().setTextAlign('center').run();
                            setAlignModalVisible(false);
                        }}
                    >
                        <FontAwesomeIcon color={getIconColor({ textAlign: 'center' })} icon={faAlignCenter} />
                        <MyAppText style={{ color: getIconColor({ textAlign: 'center' }) }}>{translate('center')}</MyAppText>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[styles.row, getIconBackgroundColor({ textAlign: 'right' })]}
                        onPress={() => {
                            editor.chain().focus().setTextAlign('right').run();
                            setAlignModalVisible(false);
                        }}
                    >
                        <FontAwesomeIcon color={getIconColor({ textAlign: 'right' })} icon={faAlignRight} />
                        <MyAppText style={{ color: getIconColor({ textAlign: 'right' }) }}>{translate('right')}</MyAppText>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[styles.row, getIconBackgroundColor({ textAlign: 'justify' })]}
                        onPress={() => {
                            editor.chain().focus().setTextAlign('justify').run();
                            setAlignModalVisible(false);
                        }}
                    >
                        <FontAwesomeIcon color={getIconColor({ textAlign: 'justify' })} icon={faAlignJustify} />
                        <MyAppText style={{ color: getIconColor({ textAlign: 'justify' }) }}>{translate('justify')}</MyAppText>
                    </TouchableOpacity>
                </View> : null}
                <TouchableOpacity
                    onPress={() => setAlignModalVisible(!alignModalVisible)}
                    style={[styles.button, styles.centeredRow]}
                >
                    {editor.isActive({ textAlign: 'left' })
                        ? <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faAlignLeft} />
                        : null
                    }
                    {editor.isActive({ textAlign: 'center' })
                        ? <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faAlignCenter} />
                        : null
                    }
                    {editor.isActive({ textAlign: 'right' })
                        ? <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faAlignRight} />
                        : null
                    }
                    {editor.isActive({ textAlign: 'justify' })
                        ? <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faAlignJustify} />
                        : null
                    }
                    <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faChevronDown} fontSize={12} />

                </TouchableOpacity>
            </View>
            <TouchableOpacity
                onPress={() => editor.chain().focus().toggleBulletList().run()}
                style={[styles.button, getIconBackgroundColor('bulletList')]}
            >
                <FontAwesomeIcon color={getIconColor('bulletList')} icon={faListUl} />
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => editor.chain().focus().toggleOrderedList().run()}
                style={[styles.button, getIconBackgroundColor('orderedList')]}
            >
                <FontAwesomeIcon color={getIconColor('orderedList')} icon={faListNumeric} />
            </TouchableOpacity>

        </View>
    </div>);
}

interface RichTextEditorProps {
    text?: string,
    onChangeText: (value: string) => void,
}

export function RichTextEditor({ text, onChangeText }: RichTextEditorProps) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    // i know this looks ugly as hell
    // but it's a tweak to make the component 'flex-able'
    // otherwise it won't respect its parent size
    function updateSize(interval?: NodeJS.Timeout) {

        const elements = document.getElementsByClassName('ProseMirror');
        if (elements.length && elements[0].parentElement) {
            const richTextEditorMenuView = document.getElementById('richTextEditorMenuView');
            if (richTextEditorMenuView) {
                elements[0].parentElement.style.height = `calc(100% - ${richTextEditorMenuView.clientHeight}px)`;
            } else {
                elements[0].parentElement.style.height = '100%';
            }
            clearInterval(interval);
        }
    }

    function handleResizeWindow() {
        setTimeout(updateSize, 100);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResizeWindow);
        return () => window.removeEventListener('resize', handleResizeWindow);
    }, []);

    if (text === undefined) {
        return <></>;
    }

    return (<View style={styles.editorView}>
        <EditorProvider
            onCreate={() => {
                const interval = setInterval(() => {
                    updateSize(interval);
                }, 10);
            }}

            onUpdate={({ editor }) => {
                onChangeText(editor.getHTML());
            }}
            editorProps={{
                attributes: {
                    class: theme === 'light' ? 'richtext-editor-light' : 'richtext-editor-dark'
                }
            }}
            slotBefore={<MenuBar theme={theme} />}
            extensions={[
                StarterKit,
                Underline,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
            ]} content={text}>
            <></>
        </EditorProvider>
    </View>);
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        editorView: {
            borderColor: getThemedColor(theme, '#58595B'),
            borderWidth: 1,
            flex: 1
        },
        menuView: {
            borderColor: getThemedColor(theme, '#58595B'),
            borderBottomWidth: 1,
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 40,
            flexWrap: 'wrap',
            padding: 7,
            zIndex: 20
        },
        button: {
            paddingVertical: 3,
            paddingHorizontal: 6,
            borderRadius: 3
        },
        centeredRow: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 5,
            padding: 3,
            borderRadius: 3
        }
    });
}
