import React, { useState, useEffect, useContext, useRef } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import ThemeContext from '../context/Theme';
import { translate } from '../services/translate';
import getThemedColor from '../services/get-themed-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons';
import MyAppText from './MyAppText';
import FormInput from './formInput';
import { cameraService } from '../services/central-api/cameras';
import Toast from 'react-native-toast-message';
import { MaskService } from 'react-native-masked-text';
import { ONLINE_AGENT_TIME_MINUTES, TeamNameCategory } from '../services/central-api/dispatch';
import { WindowInformation } from '../services/window-information';

interface MarkerVehiclePos {
    id: number,
    marker: google.maps.Marker;
}

export default function Map({
    dynamicMarker,
    pinnedMarker,
    dynamicPosition,
    markerChanged,
    pinClick,
    cameraPositions,
    agentPositions,
    occurrencePositions,
    pointsOfInterestPositions,
    polygons,
    draggable = true,
    mapControls = true,
    onClick,
    refreshPointsOfInterest,
    canManagePointsOfInterest,
    showMapPoi = false,
    shouldOmitUnitsAndAgents = true
}: {
    dynamicMarker?: LatLng;
    pinnedMarker?: LatLng;
    dynamicPosition?: LatLng;
    markerChanged?: (pos: LatLng) => void;
    pinClick?: (marker: google.maps.Marker) => void;
    cameraPositions?: CameraMap[];
    agentPositions?: UnitMapPosition[];
    occurrencePositions?: OccurrencePosition[],
    pointsOfInterestPositions?: PointOfInterest[],
    polygons?: MapPolygon[],
    draggable?: boolean;
    mapControls?: boolean;
    onClick?: (pos: LatLng) => void;
    refreshPointsOfInterest?: () => Promise<void>;
    canManagePointsOfInterest?: boolean,
    showMapPoi?: boolean;
    shouldOmitUnitsAndAgents?: boolean,
}) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [map, setMap] = useState<google.maps.Map>();
    const [maps] = useState<typeof google.maps>(window?.google?.maps);
    const [marker, setMarker] = useState<Marker>();
    const [infoWindow] = useState(new maps.InfoWindow());
    const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
    const [markerAgentList] = useState<MarkerVehiclePos[]>([]);
    const [reloadBounds, setReloadBounds] = useState<boolean>(false);
    const [isFirstPoiLoad, setIsFirstPoiLoad] = useState<boolean>(true);
    const [isFirstCameraLoad, setIsFirstCameraLoad] = useState<boolean>(true);
    const [isFirstAgentLoad, setIsFirstAgentLoad] = useState<boolean>(true);
    const [isFirstOccurrenceLoad, setIsFirstOccurrenceLoad] = useState<boolean>(true);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [cameraMarkerClusterer, setCameraMarkerClusterer] = useState<MarkerClusterer>();
    const [pointOfInterestMarkerClusterer, setPointOfInterestMarkerClusterer] = useState<MarkerClusterer>();
    const [occurrencesMarkerClusterer, setOccurrencesMarkerClusterer] = useState<MarkerClusterer>();
    const [agentMarkerClusterer, setAgentMarkerClusterer] = useState<MarkerClusterer>();
    const [cameraMarkerSpiderfier, setCameraMarkerSpiderfier] = useState<OverlappingMarkerSpiderfier>();
    const [pointOfInterestId, setPointOfInterestId] = useState<number>(0);
    const [pointOfInterestName, setPointOfInterestName] = useState<string>('');
    const [pointOfInterestDescription, setPointOfInterestDescription] = useState<string | undefined>('');
    const [pointOfInterestLatitude, setPointOfInterestLatitude] = useState<number>();
    const [pointOfInterestLongitude, setPointOfInterestLongitude] = useState<number>();
    const [drawnPolygons, setDrawPolygons] = useState<{ polygons: google.maps.Polygon[]; infoWindows: google.maps.InfoWindow[]; }>({ polygons: [], infoWindows: [] });
    const loaders = useRef({
        didAgentsLoad: false,
        didOccurrencesLoad: false,
        didCamerasLoad: false,
        didPointsOfInterestLoad: false,
    });

    const windowInfo = WindowInformation();

    function castToInt(value: string | number): number {
        if (typeof value === 'number') {
            return value;
        }
        if (value) {
            value = value.replace(',', '.');
        }
        return Number(value);
    }

    function agentPositionIcon(theme: Theme, category: string) {
        const categoryIcon = ['light_vehicle', 'heavy_vehicle'].includes(category) ? 'vehicle-pin' : category == 'motorcycle' ? 'motorcycle-pin' : 'user-agent';
        return require(`../../assets/${categoryIcon}-${theme}.svg`);
    }

    function manageAgentPositionsLoad() {
        try {
            if (!loaders.current.didAgentsLoad) {
                loaders.current.didAgentsLoad = true;
                return;
            }
            if (!map || !maps || agentPositions === undefined || !bounds || !agentMarkerClusterer) {
                return;
            }

            const localMarkers = [];
            for (const agentPosition of agentPositions) {
                if (agentPosition.latitude && agentPosition.longitude && (moment(agentPosition.positionUpdatedAt).isAfter(moment().subtract(ONLINE_AGENT_TIME_MINUTES, 'minutes')) || !shouldOmitUnitsAndAgents)) {
                    const markerRef = markerAgentList.find((obj: MarkerVehiclePos) => {
                        return obj.id === agentPosition.id;
                    });
                    if (markerRef) {
                        markerRef.marker.setPosition({
                            lat: castToInt(agentPosition.latitude),
                            lng: castToInt(agentPosition.longitude)
                        });
                        localMarkers.push(markerRef.marker);
                    } else {

                        const marker = new maps.Marker({
                            map,
                            position: {
                                lat: castToInt(agentPosition.latitude),
                                lng: castToInt(agentPosition.longitude)
                            },
                            icon: {
                                url: agentPositionIcon(theme, agentPosition.user.unit.unitName.category),
                                scaledSize: new maps.Size(30, 30)
                            }
                        });

                        const pos = marker.getPosition();
                        if (pos) {
                            bounds.extend(pos);
                        }

                        if (agentPosition.id) {
                            let content = '';
                            const maskedValue = MaskService.toMask('cel-phone', agentPosition.user.phone ?? '', {
                                maskType: 'BRL',
                                withDDD: true,
                                dddMask: '(99) '
                            });

                            if ([TeamNameCategory.LIGHT_VEHICLE, TeamNameCategory.HEAVY_VEHICLE, TeamNameCategory.MOTORCYCLE]
                                .includes(agentPosition.user.unit.unitName.category)) {
                                content += `${translate('team')}: ${agentPosition.user.unit.unitName.name}<br/>`;
                                content += `${maskedValue}<br/>`;
                            } else if (agentPosition.user.warName || agentPosition.user.name) {
                                content += `${agentPosition.user.warName || agentPosition.user.name} - ${maskedValue}<br/>`;
                                content += `${translate('team')}: ${agentPosition.user.unit.unitName.name}<br/>`;
                            }

                            if (agentPosition.positionUpdatedAt) {
                                content += `<br/>${translate('lastUpdate')}:</br>`;
                                content += moment(agentPosition.positionUpdatedAt).format('DD/MM/YYYY HH:mm:ss');
                            }

                            maps.event.addListener(marker, 'click', function () {
                                if (pinClick) {
                                    pinClick(marker);
                                }
                                infoWindow.close();
                                infoWindow.setContent(content);
                                infoWindow.open(map, marker);
                            });

                            markerAgentList.push({ id: agentPosition.id, marker: marker });
                            localMarkers.push(marker);
                        }
                    }
                }
            }

            agentMarkerClusterer.clearMarkers();
            agentMarkerClusterer.addMarkers(localMarkers);

            if (isFirstAgentLoad && localMarkers.length) {
                setIsFirstAgentLoad(false);
                setReloadBounds(!reloadBounds);
            }
        } catch (e) {
            console.error(e);
        }
    }

    function manageOccurrencesLoad() {
        try {
            if (!loaders.current.didOccurrencesLoad) {
                loaders.current.didOccurrencesLoad = true;
                return;
            }
            if (!map || !maps || occurrencePositions === undefined || !bounds || !occurrencesMarkerClusterer) {
                return;
            }

            const localMarkers = [];
            for (const occurrence of occurrencePositions) {
                if (occurrence.latitude && occurrence.longitude) {
                    const marker = new maps.Marker({
                        map,
                        position: {
                            lat: castToInt(occurrence.latitude),
                            lng: castToInt(occurrence.longitude)
                        },
                        icon: {
                            url: require('../../assets/pin_event.svg'),
                            scaledSize: new maps.Size(30, 30)
                        }
                    });

                    maps.event.addListener(marker, 'click', function () {
                        if (pinClick) {
                            pinClick(marker);
                        }
                        infoWindow.close();
                        infoWindow.setContent(String(occurrence?.id));
                        infoWindow.open(map, marker);
                    });

                    const pos = marker.getPosition();
                    if (pos) {
                        bounds.extend(pos);
                    }
                    localMarkers.push(marker);
                }
            }

            occurrencesMarkerClusterer.clearMarkers();
            occurrencesMarkerClusterer.addMarkers(localMarkers);

            if (isFirstOccurrenceLoad && localMarkers.length) {
                setIsFirstOccurrenceLoad(false);
                setReloadBounds(!reloadBounds);
            }
        } catch (e) {
            console.error(e);
        }

    }

    function managePointsOfInterestLoad() {
        try {
            if (!loaders.current.didPointsOfInterestLoad) {
                loaders.current.didPointsOfInterestLoad = true;
                return;
            }
            if (!map || !maps || pointsOfInterestPositions === undefined || !pointOfInterestMarkerClusterer || !bounds) {
                return;
            }

            const localMarkers = [];
            for (const pointOfInterest of pointsOfInterestPositions) {
                const marker: Marker = new maps.Marker({
                    map,
                    position: {
                        lat: castToInt(pointOfInterest.latitude),
                        lng: castToInt(pointOfInterest.longitude)
                    },
                    icon: {
                        url: require('../../assets/pin_point_of_interest.svg'),
                        scaledSize: new maps.Size(30, 30)
                    },
                    draggable
                });

                maps.event.addListener(marker, 'click', function () {
                    setModalVisible(false);
                    setTimeout(() => {
                        setPointOfInterestId(pointOfInterest.id);
                        setPointOfInterestName(pointOfInterest.name);
                        setPointOfInterestDescription(pointOfInterest.description);
                        setModalVisible(true);
                    }, 200);
                });

                maps.event.addListener(marker, 'dragend', function () {
                    const pos = marker.getPosition();
                    setPointOfInterestId(pointOfInterest.id);
                    setPointOfInterestName(pointOfInterest.name);
                    setPointOfInterestDescription(pointOfInterest.description);
                    if (pos) {
                        setPointOfInterestLatitude(pos.lat());
                        setPointOfInterestLongitude(pos.lng());
                    }
                    setModalVisible(true);
                });

                const pos = marker.getPosition();
                if (pos) {
                    bounds.extend(pos);
                }
                localMarkers.push(marker);
            }
            pointOfInterestMarkerClusterer.clearMarkers();
            pointOfInterestMarkerClusterer.addMarkers(localMarkers);

            if (isFirstPoiLoad && localMarkers.length) {
                setIsFirstPoiLoad(false);
                setReloadBounds(!reloadBounds);
            }
        } catch (e) {
            console.error(e);
        }

    }

    function manageCamerasLoad() {
        try {
            if (!loaders.current.didCamerasLoad) {
                loaders.current.didCamerasLoad = true;
                return;
            }
            if (!map || !maps || cameraPositions === undefined || !cameraMarkerClusterer || !bounds) {
                return;
            }

            const localMarkers = [];
            for (const camera of cameraPositions) {
                if (camera.latitude && camera.longitude) {
                    const marker: Marker = new maps.Marker({
                        map,
                        position: {
                            lat: castToInt(camera.latitude),
                            lng: castToInt(camera.longitude)
                        },
                        icon: {
                            url: camera.thirdPartyCamera && camera.hasLpr ?
                                require(`../../assets/lpr_third_party.svg`) :
                                camera.thirdPartyCamera && !camera.hasLpr ?
                                    require(`../../assets/pinned_third_party.svg`) :
                                    require(`../../assets/${camera.type}.svg`),
                            scaledSize: new maps.Size(50, 50)
                        },
                    });
                    marker.metadata = { camera };
                    localMarkers.push(marker);

                    if (cameraMarkerSpiderfier) {
                        maps.event.addListener(marker, 'spider_click', function () {
                            if (pinClick) {
                                pinClick(marker);
                            }
                        });

                        cameraMarkerSpiderfier.addMarker(marker, () => { });
                    }

                    const pos = marker.getPosition();

                    if (pos) {
                        bounds.extend(pos);
                    }
                }
            }

            cameraMarkerClusterer.clearMarkers();
            cameraMarkerClusterer.addMarkers(localMarkers);

            if (isFirstCameraLoad && localMarkers.length) {
                setIsFirstCameraLoad(false);
                setReloadBounds(!reloadBounds);
            }
        } catch (e) {
            console.error(e);
        }
    }

    function manageDynamicMarkerLoad() {
        try {
            if (!map || !maps || !dynamicMarker) return;

            if (!marker) {
                const mapMarker = new maps.Marker({
                    map: map,
                    position: new maps.LatLng(dynamicMarker.lat, dynamicMarker.lng),
                    draggable
                });

                maps.event.addListener(mapMarker, 'dragend', function () {

                    const pos = mapMarker.getPosition();

                    if (markerChanged && pos) {
                        markerChanged({ lat: pos.lat(), lng: pos.lng() });
                    }
                });
                setMarker(mapMarker);
            } else {
                const latLng = new maps.LatLng(dynamicMarker.lat, dynamicMarker.lng);
                marker.setPosition(latLng);
            }
            if (marker) {
                const pos = marker.getPosition();
                if (pos) {
                    map.setCenter(pos);
                }
                map.setZoom(16);
            }

        } catch (e) {
            console.error(e);
        }
    }

    function managePinnedMarkerLoad() {
        try {
            if (!map || !maps || !pinnedMarker) return;

            if (!marker) {
                const mapMarker = new maps.Marker({
                    map: map,
                    position: new maps.LatLng(pinnedMarker.lat, pinnedMarker.lng),
                });
                setMarker(mapMarker);
            } else {
                const latLng = new maps.LatLng(pinnedMarker.lat, pinnedMarker.lng);
                marker.setPosition(latLng);
            }
        } catch (e) {
            console.error(e);
        }
    }

    function drawPolygons() {
        try {
            if (!map || !maps || !polygons) return;

            // will ensure all the infoWindows are closed
            for (const infoWindow of drawnPolygons.infoWindows) {
                infoWindow.close();
            }
            // will remove any drawn polygon on the map
            for (const polygon of drawnPolygons.polygons) {
                polygon.setMap(null);
            }

            const mapPolygons: google.maps.Polygon[] = [];
            const polygonsInfoWindow: google.maps.InfoWindow[] = [];
            for (const polygon of polygons) {
                const mapPolygon = new maps.Polygon({
                    paths: polygon.points,
                    strokeColor: polygon.color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: polygon.color,
                    fillOpacity: 0.35,
                });
                mapPolygon.setMap(map);
                mapPolygons.push(mapPolygon);
                const infoWindow = new maps.InfoWindow({
                    content: `${polygon.name}<br/>`
                });
                polygonsInfoWindow.push(infoWindow);
                mapPolygon.addListener('click', function (event: google.maps.MapMouseEvent) {
                    infoWindow.setPosition(event.latLng);
                    infoWindow.open(map);
                });
            }
            setDrawPolygons({ polygons: mapPolygons, infoWindows: polygonsInfoWindow });
        } catch (e) {
            console.error(e);
        }
    }

    function manageDynamicPositionChange() {
        if (map && dynamicPosition) {
            map.setCenter(dynamicPosition);
            map.setZoom(16);
        }
    }

    useEffect(manageAgentPositionsLoad, [map, maps, agentPositions, isFirstAgentLoad, reloadBounds, bounds, loaders, agentMarkerClusterer, markerAgentList]);
    useEffect(manageOccurrencesLoad, [map, maps, occurrencePositions, isFirstOccurrenceLoad, reloadBounds, bounds, loaders, occurrencesMarkerClusterer]);
    useEffect(managePointsOfInterestLoad, [map, maps, pointsOfInterestPositions, isFirstPoiLoad, reloadBounds, bounds, loaders, pointOfInterestMarkerClusterer]);
    useEffect(manageCamerasLoad, [map, maps, cameraPositions, isFirstCameraLoad, reloadBounds, bounds, loaders, cameraMarkerClusterer, cameraMarkerSpiderfier]);
    useEffect(manageDynamicMarkerLoad, [dynamicMarker, map, maps]);
    useEffect(managePinnedMarkerLoad, [pinnedMarker, map, maps]);
    useEffect(drawPolygons, [drawnPolygons, polygons, map, maps]);
    useEffect(manageDynamicPositionChange, [dynamicPosition, map]);

    useEffect(() => {
        if (!map || !bounds) {
            return;
        }

        map.fitBounds(bounds);
    }, [reloadBounds, bounds, map]);

    useEffect(() => {
        if (!map || !marker) return;

        const pos = marker.getPosition();
        if (pos) {
            map.setCenter(pos);
        }
        map.setZoom(16);
    }, [marker]);



    return (
        <View style={styles.mapContainer}>
            <GoogleMapReact
                defaultCenter={{
                    lat: -23.533773,
                    lng: -46.625290
                }}
                draggable={draggable}
                defaultZoom={16}
                onClick={onClick}
                options={() => ({
                    streetViewControl: mapControls,
                    fullscreenControl: false,
                    zoomControl: mapControls,
                    styles: theme === 'light' || theme == 'lightAlt' ? [{
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [showMapPoi ? {} : { visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        stylers: [{ visibility: 'off' }],
                    }] : [
                        { featureType: 'poi', stylers: [showMapPoi ? {} : { visibility: 'off' }] },
                        { featureType: 'transit', stylers: [{ visibility: 'off' }] },
                        { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
                        { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
                        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
                        {
                            featureType: 'administrative.locality',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#d59563' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#38414e' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#212a37' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#9ca5b3' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry',
                            stylers: [{ color: '#746855' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#1f2835' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#f3d19c' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#17263c' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#515c6d' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.stroke',
                            stylers: [{ color: '#17263c' }],
                        },
                    ]
                })}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }: { map: google.maps.Map, maps: typeof google.maps; }) => {
                    setBounds(new maps.LatLngBounds());
                    setMap(map);
                    setCameraMarkerClusterer(new MarkerClusterer({ map, markers: [], algorithm: new SuperClusterAlgorithm({ minPoints: 2 }) }));
                    setPointOfInterestMarkerClusterer(new MarkerClusterer({ map, markers: [] }));
                    setOccurrencesMarkerClusterer(new MarkerClusterer({ map, markers: [], algorithm: new SuperClusterAlgorithm({ minPoints: 2 }) }));
                    setCameraMarkerSpiderfier(new OverlappingMarkerSpiderfier(map, {
                        legWeight: 8,
                        markersWontMove: true,
                        basicFormatEvents: true
                    }));
                    setAgentMarkerClusterer(new MarkerClusterer({ map, markers: [], algorithm: new SuperClusterAlgorithm({ minPoints: 2 }) }));
                }}>
            </GoogleMapReact>
            {pointsOfInterestPositions ?
                <Modal transparent={true} visible={isModalVisible} animationType='slide' onRequestClose={() => setModalVisible(false)} >
                    <View style={styles.modalContainer}>
                        <View style={styles.centeredView}>
                            <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : {}]}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: getThemedColor(theme, '#58595B') }}>{translate('pointOfInterest')}</MyAppText>
                                    <TouchableOpacity onPress={() => setModalVisible(false)}>
                                        <FontAwesomeIcon icon={faClose} fontSize={20} style={{ color: getThemedColor(theme, '#58595B') }} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{ gap: 5 }}>
                                    <FormInput label={translate('name')} invalid={() => false} disabled={!canManagePointsOfInterest} value={pointOfInterestName} onChangeText={setPointOfInterestName} />
                                    <FormInput numberOfLines={!canManagePointsOfInterest ? 5 : 3} multiline={true} label={translate('description')} disabled={!canManagePointsOfInterest} invalid={() => false} value={pointOfInterestDescription || ''} onChangeText={setPointOfInterestDescription} />
                                </View>
                                {canManagePointsOfInterest ?
                                    <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                        <TouchableOpacity style={[styles.button, styles.buttonDelete]} onPress={async () => {
                                            try {
                                                await cameraService.deletePointOfInterest(pointOfInterestId);
                                                if (refreshPointsOfInterest) {
                                                    await refreshPointsOfInterest();
                                                }

                                                Toast.show({
                                                    type: 'sentinelxSuccess',
                                                    text1: translate('ActionSuccessfully'),
                                                });
                                            } catch (error) {
                                                Toast.show({
                                                    type: 'sentinelxError',
                                                    text1: translate('unexpectedError'),
                                                });
                                            } finally {
                                                setModalVisible(false);
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faTrash} style={{ color: getThemedColor(theme, '#FFFFFF') }} fontSize={20} />
                                            <MyAppText style={styles.buttonTextDelete}>{translate('delete')}</MyAppText>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={[styles.button, styles.buttonSave]} onPress={async () => {
                                            try {
                                                if (pointOfInterestLatitude && pointOfInterestLongitude) {
                                                    await cameraService.updatePointOfInterest({
                                                        id: pointOfInterestId,
                                                        name: pointOfInterestName,
                                                        description: pointOfInterestDescription,
                                                        latitude: pointOfInterestLatitude,
                                                        longitude: pointOfInterestLongitude
                                                    });
                                                }
                                                if (refreshPointsOfInterest) {
                                                    await refreshPointsOfInterest();
                                                }
                                                Toast.show({
                                                    type: 'sentinelxSuccess',
                                                    text1: translate('ActionSuccessfully'),
                                                });
                                            } catch (error) {
                                                Toast.show({
                                                    type: 'sentinelxError',
                                                    text1: translate('unexpectedError'),
                                                });
                                            } finally {
                                                setModalVisible(false);
                                            }
                                        }}>
                                            <MyAppText style={styles.buttonTextSave}>{translate('save')}</MyAppText>
                                        </TouchableOpacity>
                                    </View> : null}
                            </View>
                        </View>
                    </View>
                </Modal> : null}
        </View >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        mapContainer: {
            flex: 1
        },
        body: {
            padding: 20,
            gap: 10,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            position: 'absolute',
            height: 315,
            width: 300,
            bottom: -315,
            alignSelf: 'center'
        },
        button: {
            borderRadius: 4,
            borderWidth: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5,
            height: 40,
            flex: 1,
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonSave: {
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonTextSave: {
            fontSize: 16,
            color: getThemedColor(theme, '#FFFFFF'),
        },
        buttonDelete: {
            borderColor: getThemedColor(theme, '#CCCCCC'),
            backgroundColor: '#FE0127',
        },
        buttonTextDelete: {
            fontSize: 16,
            color: getThemedColor(theme, '#FFFFFF')
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        formContainer: {
            minWidth: 350,
            maxWidth: 500,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            minHeight: 200,
            rowGap: 30
        },
    });
}
