import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import PlatesModal from './PlatesModal';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { licensePlateRecognitionService } from '../../services/central-api/license-plate-recognition';
import { ClientError } from '../../services/central-api/base-service';
import { MyCheckbox } from '../../components/MyCheckbox';
import MyDataTable from '../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { LPRParamList } from '../../typings/Params';

export default function Plates({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const route = useRoute<RouteProp<LPRParamList, 'Plates'>>();

    const [filters, setFilters] = useState<{ page: number; limit: number; }>({
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);

    const [plates, setPlates] = useState<PlateData[]>([]);
    const [selectedPlate, setSelectedPlate] = useState<PlateData>();
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [onlyActiveFilter, setOnlyActiveFilter] = useState(route.params.onlyActive);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function getPlates() {
        try {
            setIsLoading(true);
            const response = await licensePlateRecognitionService.getPlates({ ...filters, onlyActive: onlyActiveFilter });
            setPlates(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getPlates();
    }, []);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='plates' lateralMenu='lpr' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <View style={{ flexDirection: 'row', gap: 10 }}>
                                <MyCheckbox
                                    style={{ height: 40 }}
                                    label={translate('onlyActive')}
                                    checked={onlyActiveFilter}
                                    setChecked={checked => {
                                        setOnlyActiveFilter(checked);

                                        navigation.setParams({
                                            ...route.params,
                                            onlyActive: checked
                                        });
                                    }}
                                />
                                <TouchableOpacity onPress={() => {
                                    setSelectedPlate(undefined);
                                    setModalVisible(true);
                                }} style={styles.new}>
                                    <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                    <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyDataTable
                                columns={[
                                    {
                                        name: translate('plates'),
                                        selector: row => row.plate,
                                        grow: 0
                                    },
                                    {
                                        name: translate('description'),
                                        selector: row => row.description,
                                        grow: 1,
                                        wrap: true
                                    },
                                    {
                                        name: translate('action'),
                                        button: true,
                                        cell: row =>
                                            <View style={{ flexDirection: 'row', gap: 10 }}>
                                                <TouchableOpacity onPress={() => {
                                                    setSelectedPlate(row);
                                                    setModalVisible(true);
                                                }}>
                                                    <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => {
                                                    setSelectedPlate(row);
                                                    setDeleteModalVisible(true);
                                                }}>
                                                    <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                </TouchableOpacity>
                                            </View>
                                    }
                                ]}
                                data={plates}
                                progressPending={isLoading}
                            />
                        </View>
                        <Pagination
                            totalItems={totalItems}
                            currentPage={filters.page}
                            pageSize={filters.limit}
                            setPageSize={pageSize => {
                                setFilters(old => ({
                                    ...old,
                                    limit: pageSize,
                                    page: 0,
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    limit: pageSize,
                                    page: 0,
                                });
                            }}
                            setPage={page => {
                                setFilters(old => ({
                                    ...old,
                                    page
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    page: page + 1
                                });
                            }}
                        />
                    </View>

                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={selectedPlate?.plate || ''}
                        onSubmit={async () => {
                            try {
                                if (!selectedPlate?.id) {
                                    return;
                                }
                                await licensePlateRecognitionService.deletePlate(selectedPlate.id || 0);
                                Toast.show({
                                    type: 'sentinelxSuccess',
                                    text1: translate('ActionSuccessfully'),
                                });

                                getPlates();
                            } catch (err) {
                                if (err instanceof ClientError) {
                                    return Toast.show({
                                        type: 'sentinelxError',
                                        text1: translate(err.message),
                                    });
                                }

                                console.error(err);
                                Toast.show({
                                    type: 'sentinelxError',
                                    text1: translate('unexpectedError'),
                                });
                            }
                        }}
                    />

                    {/* create/edit plate modal */}
                    <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <PlatesModal
                                    onClose={() => {
                                        setSelectedPlate(undefined);
                                        setModalVisible(false);
                                        getPlates();
                                    }}
                                    plateData={selectedPlate}
                                />
                            </View>
                        </View>
                    </Modal>

                </View>
            </DefaultPageLayout >
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            gap: 10
        },
        cardList: {
            borderWidth: 1,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            // borderColor: theme.colors.dataTable.border,
            // backgroundColor: theme.colors.dataTable.background,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1,
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
}
