import React, { useContext, useState, } from 'react';
import { ScrollView, View, StyleSheet, Image, TouchableOpacity, Modal } from 'react-native';
import MyAppText from '../../MyAppText';


import ThemeContext from '../../../context/Theme';
import getThemedColor from '../../../services/get-themed-color';
import { Hoverable } from 'react-native-web-hover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faLocationDot, faSearchPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { WindowInformation } from '../../../services/window-information';
import { translate } from '../../../services/translate';
import moment from 'moment';

export default function OccurrenceFormMpFaceDetected({
    resume
}: {
    resume: MpFaceDetectedResumeData;
}) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const windowInfo = WindowInformation();

    return (
        <ScrollView>
            <View style={{ gap: 5 }}>
                <View>
                    <View style={{ flexDirection: 'row' }}>
                        <MyAppText style={styles.nameText}>
                            {resume.name}
                        </MyAppText>
                    </View>
                </View >
                <Hoverable>
                    {({ hovered }) => (<>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
                            <Image
                                style={{
                                    width: 540,
                                    height: 360,
                                    resizeMode: 'contain',
                                    borderRadius: 5,
                                    overflow: 'hidden',
                                }}
                                source={{ uri: `${resume.imageUrl}` }}
                            />
                        </View>
                        {hovered &&
                            <View style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#00000080',
                            }}>
                                <TouchableOpacity
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center'
                                    }}
                                    onPress={async () => {
                                        setIsModalVisible(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                                </TouchableOpacity>
                            </View>
                        }
                    </>)}
                </Hoverable>
                <MyAppText style={styles.confidenceText}>
                    {resume.confidence ?
                        translate('confidence') + ': ' + (Number(resume.confidence) * 100).toFixed(0) + '%'
                        : null
                    }
                </MyAppText>
                <View style={{ gap: 3 }}>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('document')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.document}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('birth')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.birth}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('motherName')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.motherName}
                        </MyAppText>
                    </View>
                </View>
                {(resume.historic) ?
                    <View style={{ gap: 3 }}>
                        <MyAppText style={styles.plateText}>
                            {translate('description')}
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.historic}
                        </MyAppText>
                    </View>
                    : null}
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('visualizedOn')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(new Date(resume.time)).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.location}
                        </MyAppText>
                    </View>
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('systemData')}
                    </MyAppText>
                    {(resume.occurrenceNumber) ?
                        <View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={styles.detectionInfoText}>
                                    {translate('occurrenceNumber')}:
                                </MyAppText>
                                <MyAppText style={styles.detectionInfoText}>
                                    {resume.occurrenceNumber}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={styles.detectionInfoText}>
                                    {translate('policeReportYear')}:
                                </MyAppText>
                                <MyAppText style={styles.detectionInfoText}>
                                    {resume.policeReportYear}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={styles.detectionInfoText}>
                                    {translate('policeReportLocation')}:
                                </MyAppText>
                                <MyAppText style={styles.detectionInfoText}>
                                    {resume.occurrenceMunicipality} / {resume.occurrenceFederativeUnit}
                                </MyAppText>
                            </View>
                        </View>
                        : null}
                    {(resume.occurrenceDate) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('occurrenceDate')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                {moment(new Date(resume.occurrenceDate)).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        : null}
                    {(resume.occurrenceUnit) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('occurrenceUnit')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                {resume.occurrenceUnit}
                            </MyAppText>
                        </View>
                        : null}
                    {(resume.situation) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('situation')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                {resume.situation}
                            </MyAppText>
                        </View>
                        : null}
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('state')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.state}
                        </MyAppText>
                    </View>
                </View>
            </View>
            {/* Zoom image modal */}
            <Modal
                animationType='fade'
                transparent={true}
                visible={isModalVisible}
                onRequestClose={() => setIsModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : null]}>
                            <View style={{ alignItems: 'flex-end' }}>
                                <TouchableOpacity
                                    style={{ width: 20, height: 20 }}
                                    onPress={() => setIsModalVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={getThemedColor(theme, '#58595B')} />
                                </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'row', gap: 15 }} >
                                <View style={{ flex: 1 }}>
                                    <Image
                                        style={{
                                            width: 1080,
                                            height: 720,
                                            resizeMode: 'contain',
                                            borderRadius: 5,
                                            overflow: 'hidden'
                                        }}
                                        source={{ uri: `${resume.imageUrl}` }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </ScrollView >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            padding: 16,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            borderWidth: 1,
        },
        nameText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 20
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199',
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            padding: 30,
        },
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        detectionInfoText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        plateText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 14
        },
        confidenceText: {
            color: getThemedColor(theme, '#888888'),
            fontSize: 14,
            paddingVertical: 10
        },
    });
}
