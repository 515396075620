import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal, ActivityIndicator, DimensionValue } from 'react-native';
import MyAppText from '../../MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import { WindowInformation } from '../../../services/window-information';
import Mustache from 'mustache';
import { DispatchService } from '../../../services/central-api/dispatch';
import { GcmAgentService } from '../../../services/central-api/gcmAgent';

interface OccurrenceVideoModalParameters {
    occurrenceId: number;
    /**
     * time value in milliseconds since midnight, January 1, 1970 UTC
     */
    occurrenceTime: number;
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    apiService: DispatchService | GcmAgentService;
}

export default function OccurrenceVideoModal({ occurrenceId, occurrenceTime, isModalVisible, setModalVisible, apiService }: OccurrenceVideoModalParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const videoRef = useRef<HTMLVideoElement>(null);
    const windowInfo = WindowInformation();
    const [videoUrl, setVideoUrl] = useState<VideoUrl>();
    const [videoHeight, setVideoHeight] = useState<DimensionValue>('0%');
    const [isVideoPaused, setVideoPaused] = useState(false);
    const start = occurrenceTime - 15000;
    const finish = occurrenceTime + 15000;

    async function delay(msTime: number) {
        return new Promise((resolve) => setTimeout(resolve, msTime));
    }

    async function validLastVideo(index: number, firstVideo: number, lastVideo: number, videoUrl: VideoUrl) {
        const video = videoRef.current;

        if (!video) {
            return;
        }

        while ((video.currentTime * 1000) + videoUrl.videos[index].start <= finish) {
            await delay(1000);
        }
        setVideo(firstVideo, firstVideo, lastVideo, videoUrl);

    }

    function setVideo(index: number, firstVideo: number, lastVideo: number, videoUrl: VideoUrl) {
        const video = videoRef.current;

        if (!video) {
            return;
        }

        let startTime = 0;

        if (index == firstVideo) {
            startTime = (start - videoUrl.videos[index].start) / 1000;
        }

        video.src = Mustache.render(videoUrl.videoTemplate, videoUrl.videos[index]);
        video.currentTime = startTime;

        function loadNextVideoCallback() {
            video?.removeEventListener('ended', loadNextVideoCallback);
            if (index !== lastVideo) {
                setVideo(index + 1, firstVideo, lastVideo, videoUrl);
            }
        }

        if (index == lastVideo) {
            validLastVideo(lastVideo, firstVideo, lastVideo, videoUrl);
        } else {
            video.addEventListener('ended', loadNextVideoCallback);
        }
    }

    async function getVideoUrl() {
        try {
            let videoUrl = await apiService.getOccurrenceVideoUrl(occurrenceId);
            while (!videoUrl.videos.length) {
                await delay(5000);
                videoUrl = await apiService.getOccurrenceVideoUrl(occurrenceId);
            }
            setVideoUrl(videoUrl);

            let firstVideo = 0;
            let lastVideo = 0;

            for (const [index, video] of videoUrl.videos.entries()) {
                if (start >= video.start && start <= video.finish) {
                    firstVideo = index;
                }
                if (finish >= video.start && finish <= video.finish) {
                    lastVideo = index;
                }
            }

            setVideoHeight('100%');
            setVideo(firstVideo, firstVideo, lastVideo, videoUrl);

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (!isModalVisible) {
            setVideoHeight('0%');
            setVideoPaused(false);
            setVideoUrl(undefined);
            return;
        }

        if (videoUrl?.videos.length) {
            return;
        }

        getVideoUrl();
        if (!videoUrl?.videos.length) {
            const interval = setInterval(() => {
                getVideoUrl();
            }, 20000);
            return () => clearInterval(interval);
        }
    }, [videoUrl, isModalVisible]);

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                        <View style={{ alignItems: 'flex-end' }}>
                            <TouchableOpacity style={{ width: 20, height: 20 }} onPress={() => setModalVisible(false)}>
                                <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={getThemedColor(theme, '#58595B')} />
                            </TouchableOpacity>
                        </View>
                        <View style={{ height: videoHeight == '0%' ? '0%' : undefined }}>
                            <video ref={videoRef} disablePictureInPicture={true} style={{ height: String(videoHeight), width: '100%', objectFit: 'fill' }} autoPlay muted >
                            </video>
                            <TouchableOpacity
                                style={{ position: 'absolute', width: '100%', height: videoHeight, zIndex: 4, flexDirection: 'column', justifyContent: 'space-between', padding: 5 }}
                                onPress={async () => {
                                    const video = videoRef.current;

                                    if (!video) {
                                        return;
                                    }

                                    if (isVideoPaused) {
                                        await video.play();
                                        setVideoPaused(false);
                                    } else {
                                        video.pause();
                                        setVideoPaused(true);
                                    }

                                }}>
                            </TouchableOpacity>
                        </View>
                        {!videoUrl?.videos.length ?
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', rowGap: 10 }}>
                                <ActivityIndicator size='large' color={getThemedColor(theme, '#000000')} />
                                <MyAppText style={styles.videoNotReadyText}>{translate('videoNotReady')}</MyAppText>
                                <MyAppText style={styles.videoNotReadyText}>{translate('waitAMinute')}...</MyAppText>
                            </View>
                            : null
                        }
                    </View>
                </View>
            </View>
        </Modal>
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        videoNotReadyText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            padding: 30,
        },
    });
}
