import React, { useContext, useEffect, useRef } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import MyAppText from '../MyAppText';
import ThemeContext from '../../context/Theme';
import { translate } from '../../services/translate';
import getThemedColor from '../../services/get-themed-color';
import { faCalendarCheck, faLocationDot, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export function IrregularVehicleOccurrence({ occurrence, event, navigation, imageUrl }: { occurrence: Occurrence, event: LprDetection; navigation: Navigation; imageUrl: string; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 4;

            const rect = {
                minX: Infinity,
                maxX: 0,
                minY: Infinity,
                maxY: 0
            };

            if (event.platePosition) {
                for (const position of event.platePosition) {
                    if (position.x < rect.minX) {
                        rect.minX = position.x;
                    }

                    if (position.x > rect.maxX) {
                        rect.maxX = position.x;
                    }

                    if (position.y < rect.minY) {
                        rect.minY = position.y;
                    }

                    if (position.y > rect.maxY) {
                        rect.maxY = position.y;
                    }
                }

                ctx.beginPath();
                ctx.moveTo(rect.minX, rect.minY);
                ctx.lineTo(rect.minX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.minY);
                ctx.closePath();
                ctx.stroke();
            }

        };

        image.src = imageUrl;
    });

    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyAppText style={{ fontSize: 16, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                            {translate('nProtocol')}: {occurrence?.id}
                        </MyAppText>
                        <MyAppText> </MyAppText>
                        <MyAppText style={{ fontSize: 14, color: getThemedColor(theme, '#888888') }}>
                            ({translate('team')} {translate(occurrence.OccurrenceUnits?.length && occurrence.OccurrenceUnits[0].OccurrenceUnit.isStarter ? 'starter' : 'support').toLowerCase()})
                        </MyAppText>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={getThemedColor(theme, '#222222')} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertText}>
                    {translate('irregular_vehicle')}:
                </MyAppText>
                <MyAppText style={{ fontSize: 14, fontWeight: 'bold' }}>{translate('plate')}</MyAppText>
                <MyAppText style={styles.alertName}>{event.plate}</MyAppText>
                <TransformWrapper>
                    <TransformComponent>
                        <canvas style={{ width: '100%' }} ref={canvasRef}></canvas>
                    </TransformComponent>
                </TransformWrapper>
            </View>
            <View style={styles.card}>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.cardBoldText}>
                        {translate('vehicleData')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.cardText}>
                            {translate('brand')}:
                        </MyAppText>
                        <MyAppText style={styles.cardText}>
                            {event['vehicleDescription.brand']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.cardText}>
                            {translate('model')}:
                        </MyAppText>
                        <MyAppText style={styles.cardText}>
                            {event['vehicleDescription.model']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.cardText}>
                            {translate('color')}:
                        </MyAppText>
                        <MyAppText style={styles.cardText}>
                            {event['vehicleDescription.color']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.cardText}>
                            {translate('vehicleType')}:
                        </MyAppText>
                        <MyAppText style={styles.cardText}>
                            {event['vehicleDescription.type']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.cardText}>
                            {translate('origin')}:
                        </MyAppText>
                        <MyAppText style={styles.cardText}>
                            {event['vehicleDescription.city']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.cardText}>
                            {translate('year')}:
                        </MyAppText>
                        <MyAppText style={styles.cardText}>
                            {event['vehicleDescription.modelYear']}
                        </MyAppText>
                    </View>
                </View>
                <View>
                    <View style={{ gap: 5 }}>
                        <MyAppText style={styles.cardBoldText}>{translate('visualizedOn')}</MyAppText>
                        <View style={styles.visualizedRow}>
                            <FontAwesomeIcon icon={faLocationDot} style={{ color: getThemedColor(theme, '#58595B') }} />
                            <MyAppText style={styles.cardText}>{event.camera.title}</MyAppText>
                        </View>
                        <View style={styles.visualizedRow}>
                            <FontAwesomeIcon icon={faCalendarCheck} style={{ color: getThemedColor(theme, '#58595B') }} />
                            <MyAppText style={styles.cardText}>{moment(event.time).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('occurrenceId')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.id}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    <View>
                        <View style={{ paddingBottom: 30 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('narrative')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{occurrence.narrative}</MyAppText>
                        </View>
                    </View>
                </View>
                <View style={{ paddingBottom: 30 }}>
                    <MyAppText style={styles.cardBoldText}>{translate('situation')}:</MyAppText>
                    <MyAppText style={styles.cardText}>
                        {event.violation == 'on_phone' ? translate('onPhone') : null}
                        {event.violation == 'without_belt' ? translate('withoutBelt') : null}
                    </MyAppText>
                </View>
            </View>
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        alertText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            fontWeight: 'bold',
        },
        alertName: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 18,
            fontWeight: 'bold',
            paddingBottom: 8
        },
        card: {
            gap: 20,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#EEEEEE'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            padding: 14,
            display: 'flex',
            alignItems: 'flex-start',
        },
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        cardText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        cardBoldText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 14
        },
        plateInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 24
        }
    });
}
