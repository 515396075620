import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { DashBoardParamList, DispatchParamList } from '../../../typings/Params';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { useState } from 'react';
import TypeDropdown from './TypeDropdown';
import PriorityDropdown from './PriorityDropdown';
import StatusDropdown from './StatusDropdown';
import OccurrenceTypeDropdown from './OccurrenceTypeDropdown';
import UnitsDropdown from './UnitsDropdown';
import TagsDropdown from './TagsDropdown';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import GenericUserDropdown from '../../../components/Dropdowns/GenericUserDropdown';
import moment from 'moment';
import ActingBodiesCommandsDropdown from '../../../components/Dropdowns/ActingBodiesCommandsDropdown';
import FilterDatePicker from '../../../components/Filter/components/FilterDateInput';

interface Props {
    filters: DispatchParamList['Occurrences'] | DashBoardParamList['DashboardOccurrences'];
    setFilters: React.Dispatch<React.SetStateAction<DispatchParamList['Occurrences'] | DashBoardParamList['DashboardOccurrences']>>;
    textFilter: string;
    setTextFilter: React.Dispatch<React.SetStateAction<string>>;
    authenticatedUser?: AuthenticatedUser;
}

function OccurrencesFilter({ filters, setFilters, textFilter, setTextFilter, authenticatedUser }: Props) {
    const navigation = useNavigation<NavigationProp<DispatchParamList, 'Occurrences'>>();

    const [selectedTriggerTypeFilter, setSelectedTriggerTypeFilter] = useState<TriggerType | 'all' | 'manual'>(filters.triggerType ?? 'all');
    const [selectedPriorityFilter, setSelectedPriorityFilter] = useState<OccurrencePriorities | 'all'>(filters.priority ?? 'all');
    const [selectedSituationFilter, setSelectedSituationFilter] = useState<OccurrenceSituation | 'all'>(filters.situation ?? 'all');
    const [selectedOccurrenceTypeFilter, setSelectedOccurrenceTypeFilter] = useState<number>(filters.occurrenceTypeId ?? 0);
    const [selectedUnitsIds, setSelectedUnitsIds] = useState<number[]>(filters.unitIds ?? []);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>(filters.tags ?? []);
    const [selectedActingBodyId, setSelectedActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [selectedAssumedBy, setSelectedAssumedBy] = useState<number>(filters.assumedBy ?? 0);
    const [selectedTransferredBy, setSelectedTransferredBy] = useState<number>(filters.transferredBy ?? 0);
    const [selectedReceivedBy, setSelectedReceivedBy] = useState<number>(filters.receivedBy ?? 0);
    const [selectedFinishedBy, setSelectedFinishedBy] = useState<number>(filters.finishedBy ?? 0);
    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.beginIn ? new Date(filters.beginIn) : null);
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.beginIn ? moment(filters.beginIn).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? new Date(filters.endIn) : null);
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');
    const [actingBodyCommandIds, setActingBodyCommandIds] = useState<number[]>(filters.actingBodyCommandIds ?? []);

    const handleCleanFilter = () => {
        setTextFilter('');
        setSelectedOccurrenceTypeFilter(0);
        setSelectedActingBodyId(0);
        setSelectedAssumedBy(0);
        setSelectedTransferredBy(0);
        setSelectedReceivedBy(0);
        setSelectedFinishedBy(0);
        setSelectedPriorityFilter('all');
        setSelectedSituationFilter('all');
        setSelectedTriggerTypeFilter('all');
        setSelectedUnitsIds([]);
        setSelectedTagsIds([]);
        setBeginInDateFilter(null);
        setBeginInHourFilter('');
        setEndInDateFilter(null);
        setEndInHourFilter('');
        setActingBodyCommandIds([]);
    };

    const handleFilter = () => {
        const formatDateTime = (date: Date | null, time: string) =>
            date ? moment(`${moment(date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm:ss').valueOf() : undefined;
        const beginIn = formatDateTime(beginInDateFilter, beginInHourFilter ? `${beginInHourFilter}:00` : '00:00:00');
        const endIn = formatDateTime(endInDateFilter, endInHourFilter ? `${endInHourFilter}:59` : '23:59:59');

        setFilters({
            ...filters,
            situation: selectedSituationFilter !== 'all' ? selectedSituationFilter : undefined,
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            unitIds: selectedUnitsIds.length ? selectedUnitsIds : undefined,
            textFilter: textFilter,
            page: 0,
            tags: selectedTagsIds.length ? selectedTagsIds : undefined,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            assumedBy: selectedAssumedBy !== 0 ? selectedAssumedBy : undefined,
            transferredBy: selectedTransferredBy !== 0 ? selectedTransferredBy : undefined,
            receivedBy: selectedReceivedBy !== 0 ? selectedReceivedBy : undefined,
            finishedBy: selectedFinishedBy !== 0 ? selectedFinishedBy : undefined,
            beginIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
        });

        navigation.setParams({
            situation: selectedSituationFilter !== 'all' ? selectedSituationFilter : undefined,
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            unitIds: selectedUnitsIds.length ? selectedUnitsIds : undefined,
            textFilter: textFilter,
            page: 1,
            tags: selectedTagsIds.length ? selectedTagsIds : undefined,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            assumedBy: selectedAssumedBy !== 0 ? selectedAssumedBy : undefined,
            transferredBy: selectedTransferredBy !== 0 ? selectedTransferredBy : undefined,
            receivedBy: selectedReceivedBy !== 0 ? selectedReceivedBy : undefined,
            finishedBy: selectedFinishedBy !== 0 ? selectedFinishedBy : undefined,
            beginIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
        });

    };

    function getActingBodyId() {
        if (!authenticatedUser || !authenticatedUser.actingBodyUnit.actingBodyId) {
            return 0;
        }

        if (authenticatedUser.isAdmin || authenticatedUser.permissions.dispatch_manage_all_acting_bodies_occurrences) {
            return selectedActingBodyId;
        } else {
            return authenticatedUser.actingBodyUnit.actingBodyId;
        }
    }

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('searchOccurrence')}
                placeholder={translate('typeToSearch')}
                value={textFilter}
                onChange={text => {
                    setTextFilter(text);
                }}
            />

            <FilterDatePicker
                date={beginInDateFilter}
                onChangeDate={setBeginInDateFilter}
                time={beginInHourFilter}
                onChangeTime={setBeginInHourFilter}
                label={translate('createdAt')}
                zIndex={15}
            />

            <FilterDatePicker
                date={endInDateFilter}
                onChangeDate={setEndInDateFilter}
                time={endInHourFilter}
                onChangeTime={setEndInHourFilter}
                label={translate('to')}
                zIndex={14}
            />

            <TypeDropdown value={selectedTriggerTypeFilter} setValue={setSelectedTriggerTypeFilter} zIndex={13} />

            <PriorityDropdown value={selectedPriorityFilter} setValue={setSelectedPriorityFilter} zIndex={12} />

            <StatusDropdown value={selectedSituationFilter} setValue={setSelectedSituationFilter} zIndex={11} />

            <OccurrenceTypeDropdown value={selectedOccurrenceTypeFilter} setValue={setSelectedOccurrenceTypeFilter} zIndex={10} />

            <UnitsDropdown value={selectedUnitsIds} setValue={setSelectedUnitsIds} zIndex={9} />

            <TagsDropdown value={selectedTagsIds} setValue={setSelectedTagsIds} zIndex={8} />

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrences) &&
                <ActingBodiesDropdown value={selectedActingBodyId} setValue={setSelectedActingBodyId} zIndex={7} />
            }
            <ActingBodiesCommandsDropdown
                value={actingBodyCommandIds}
                actingBodyId={getActingBodyId()}
                setValue={setActingBodyCommandIds}
                zIndex={6}
            />
            <GenericUserDropdown
                value={selectedAssumedBy}
                setValue={setSelectedAssumedBy}
                actingBodyId={getActingBodyId()}
                zIndex={5}
                label='assumedBy' />
            <GenericUserDropdown
                value={selectedTransferredBy}
                setValue={setSelectedTransferredBy}
                actingBodyId={getActingBodyId()}
                zIndex={4}
                label='transferredBy' />
            <GenericUserDropdown
                value={selectedReceivedBy}
                setValue={setSelectedReceivedBy}
                actingBodyId={getActingBodyId()}
                zIndex={3}
                label='receivedBy' />
            <GenericUserDropdown
                value={selectedFinishedBy}
                setValue={setSelectedFinishedBy}
                actingBodyId={getActingBodyId()}
                zIndex={2}
                label='finishedBy' />
        </Filters>
    );
}

export default OccurrencesFilter;
