import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import Toast from 'react-native-toast-message';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { gcmAgentService } from '../../../services/central-api/gcmAgent';
import MyAppText from '../../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ScrollView } from 'react-native-web-hover';
import { NotFound } from '../../../services/central-api/base-service';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    warrantId: number;
    onClose: () => void;
}

export default function WarrantMoreInformationModal({ warrantId, isModalVisible, setModalVisible, onClose }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);

    const [warrantInformation, setWarrantInformation] = useState<CompleteWarrantInformation>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function loadPageInfo() {
        try {
            setIsLoading(true);
            const response = await gcmAgentService.findWarrantMoreInformation(warrantId);
            setWarrantInformation(response);
        } catch (err) {
            if (err instanceof NotFound) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate('warrantNotFound'),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    function keyValue(key: string, value: string | boolean | number, isDate?: boolean) {
        if (value == '') {
            return;
        } else if (typeof value == 'boolean') {
            return <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {translate(value ? 'yes' : 'no')}</MyAppText>;
        } else if (isDate) {
            return <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {moment(value).format('DD/MM/YYYY')}</MyAppText>;
        }
        return <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {value}</MyAppText>;
    }

    useEffect(() => {
        if (warrantId !== 0) {
            loadPageInfo();
        }
    }, [warrantId]);

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.container}>
                <View style={styles.formContainer}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <MyAppText style={{ fontWeight: 'bold', fontSize: 18 }}>{translate('detailedWarrant')}</MyAppText>
                        <FontAwesomeIcon icon={faX} color={theme.colors.iconColor} fontSize={20} cursor='pointer' onClick={() => {
                            setModalVisible(false);
                            onClose();
                        }} />
                    </View>
                    {isLoading ?
                        <ActivityIndicator size='large' color={theme.colors.iconColor} /> :
                        warrantInformation ?
                            <ScrollView contentContainerStyle={{ gap: 5 }} key={warrantInformation.id}>
                                <>{keyValue('name', warrantInformation.nome)}</>
                                <>{keyValue('nickname', warrantInformation.alcunha)}</>
                                <>{keyValue('father', warrantInformation.pai)}</>
                                <>{keyValue('mother', warrantInformation.mae)}</>
                                <>{keyValue('birth', warrantInformation.dataNascimento, true)}</>
                                <>{keyValue('profession', warrantInformation.profissao)}</>
                                <>{keyValue('email', warrantInformation.email)}</>
                                <>{keyValue('sexualOrientation', warrantInformation.orientacaoSexual)}</>
                                <>{keyValue('pregnant', warrantInformation.flagGravidez)}</>
                                <>{keyValue('lactating', warrantInformation.flagLactante)}</>
                                <>{keyValue('physicallyDisabled', warrantInformation.flgDeficienteFisico)}</>
                                <>{keyValue('createdAt', warrantInformation.dataCriacao, true)}</>
                                <>{keyValue('mostRecent', warrantInformation.flgMaisRecente)}</>
                                <>{keyValue('gender', warrantInformation.sexo)}</>
                                <>{keyValue('education', warrantInformation.escolaridade)}</>
                                <>{keyValue('maritalStatus', warrantInformation.estadoCivil)}</>
                                <>{keyValue('race', warrantInformation.corRaca)}</>
                                <>{keyValue('drugDependent', warrantInformation.flagDependenteQuimico)}</>
                                <>{keyValue('country', warrantInformation.pais)}</>
                                <>{keyValue('employmentType', warrantInformation.tipoEmprego)}</>
                                <>{keyValue('twinBrother', warrantInformation.flgIrmaoGemeo)}</>
                                <>{keyValue('municipality', `${warrantInformation.municipio}/${warrantInformation.uf}`)}</>
                                <>{keyValue('type', warrantInformation.tipoBuscaCPF)}</>
                                <View style={styles.cardBodySplitter}>
                                    <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('person')}</MyAppText>
                                    <>{keyValue('active', warrantInformation.pessoa.flgAtivo)}</>
                                    <>{keyValue('individualNumber', warrantInformation.pessoa.numeroIndividuo)}</>
                                    <>{keyValue('justification', warrantInformation.pessoa.justificativa)}</>
                                    <>{keyValue('status', warrantInformation.pessoa.statusPessoa)}</>
                                    <>{keyValue('warrantExpirationDate', warrantInformation.pessoa.dataVencimentosMandados, true)}</>
                                    <>{keyValue('custodyState', warrantInformation.pessoa.ufCustodia)}</>
                                    <>{keyValue('custodyMunicipality', warrantInformation.pessoa.municipioCustodia)}</>
                                    <>{keyValue('arrestWarrantExpirationDate', warrantInformation.pessoa.dataExpiracaoMandadoPrisao, true)}</>
                                    <>{keyValue('registrationDate', warrantInformation.pessoa.dataCadastro, true)}</>
                                    <>{keyValue('lastEditDate', warrantInformation.pessoa.dataUltimaEdicao, true)}</>
                                </View>
                                <View style={styles.cardBodySplitter}>
                                    <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('signsAndMarks')}</MyAppText>
                                    <>{keyValue('otherDescription', warrantInformation.sinaisMarcas.descricaoOutros)}</>
                                    <>{keyValue('height', warrantInformation.sinaisMarcas.altura)}</>
                                    <>{keyValue('beard', warrantInformation.sinaisMarcas.barba)}</>
                                    <>{keyValue('mustache', warrantInformation.sinaisMarcas.bigode)}</>
                                    <>{keyValue('mouth', warrantInformation.sinaisMarcas.boca)}</>
                                    <>{keyValue('bodyType', warrantInformation.sinaisMarcas.biotipo)}</>
                                    <>{keyValue('hairType', warrantInformation.sinaisMarcas.tipoCabelo)}</>
                                    <>{keyValue('eyeColor', warrantInformation.sinaisMarcas.corOlhos)}</>
                                    <>{keyValue('eyeShape', warrantInformation.sinaisMarcas.formatoOlhos)}</>
                                    <>{keyValue('skinColor', warrantInformation.sinaisMarcas.corPele)}</>
                                    <>{keyValue('lips', warrantInformation.sinaisMarcas.labios)}</>
                                    <>{keyValue('nose', warrantInformation.sinaisMarcas.nariz)}</>
                                    <>{keyValue('ears', warrantInformation.sinaisMarcas.orelhas)}</>
                                    <>{keyValue('neck', warrantInformation.sinaisMarcas.pescoco)}</>
                                    <>{keyValue('face', warrantInformation.sinaisMarcas.rosto)}</>
                                    <>{keyValue('eyebrows', warrantInformation.sinaisMarcas.sobrancelhas)}</>
                                    <>{keyValue('forehead', warrantInformation.sinaisMarcas.testa)}</>
                                </View>
                                {
                                    warrantInformation.documento.length ? <View style={styles.cardBodySplitter}>
                                        <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('document')}</MyAppText>
                                        {
                                            warrantInformation.documento.map(document => (
                                                <View key={document.id} style={{ gap: 5 }}>
                                                    <>{keyValue('document', document.numeroDocumento)}</>
                                                    <>{keyValue('documentType', document.tipoDocumento)}</>
                                                    <>{keyValue('documentPhoto', document.fotoDocumento)}</>
                                                    <>{keyValue('photoContentType', document.tipoConteudoFoto)}</>
                                                    <>{keyValue('otherNames', document.outrosNomes)}</>
                                                    <>{keyValue('issuerText', document.textoOrgaoEmissor)}</>
                                                    <>{keyValue('state', document.uf)}</>
                                                </View>
                                            ))
                                        }
                                    </View> : null
                                }
                                {
                                    warrantInformation.certidaoCumprimentomandadoPrisao.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('arrestedWarrantCertificate')}</MyAppText>
                                            {
                                                warrantInformation.certidaoCumprimentomandadoPrisao.map(certificate => (
                                                    <View key={certificate.id} style={{ gap: 5 }}>
                                                        <>{keyValue('createdAt', certificate.dataCriacao, true)}</>
                                                        <>{keyValue('processNumber', certificate.numeroProcesso)}</>
                                                        <>{keyValue('pieceNumber', certificate.numeroPeca)}</>
                                                        <>{keyValue('cancellationJustificationText', certificate.textoJustificativaCancelamento)}</>
                                                        <>{keyValue('serverConfirmationDate', certificate.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('issuanceDate', certificate.dataExpedicao, true)}</>
                                                        <>{keyValue('conclusionDate', certificate.dataConclusao, true)}</>
                                                        <>{keyValue('complianceDate', certificate.dataCumprimento, true)}</>
                                                        <>{keyValue('responsibleForArrestDescription', certificate.descricaoResponsavelPrisao)}</>
                                                        <>{keyValue('complianceText', certificate.textoCumprimento)}</>
                                                        <>{keyValue('observationText', certificate.textoObservacao)}</>
                                                        <>{keyValue('custodyLocationDescription', certificate.decricaoLocalCustodia)}</>
                                                        <>{keyValue('state', certificate.estado)}</>
                                                        <>{keyValue('municipality', certificate.municipio)}</>
                                                        <>{keyValue('country', certificate.pais)}</>
                                                        <>{keyValue('establishment', certificate.estabelecimento)}</>
                                                        <>{keyValue('warrantSequenceNumber', certificate.seqMandadoPrisao)}</>
                                                    </View>
                                                ))
                                            }
                                        </View> : null
                                }
                                {
                                    warrantInformation.alvaraSoltura.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('releaseWarrant')}</MyAppText>
                                            {
                                                warrantInformation.alvaraSoltura.map(as => (
                                                    <View key={as.id} style={{ gap: 5 }}>
                                                        <>{keyValue('createdAt', as.dataCriacao, true)}</>
                                                        <>{keyValue('arrestDate', as.dataPrisao, true)}</>
                                                        <>{keyValue('complianceDescription', as.descricaoCumprimento)}</>
                                                        <>{keyValue('custodyLocationDescription', as.descricaoLocalCustodia)}</>
                                                        <>{keyValue('decisionSummaryDescription', as.descricaoSinteseDecisao)}</>
                                                        <>{keyValue('custodyEstablishment', as.estabelecimentoCustodia)}</>
                                                        <>{keyValue('hasOtherMeasures', as.flgPossuiOutrasMedidas)}</>
                                                        <>{keyValue('isHouseArrest', as.flgPrisaoDomiciliar)}</>
                                                        <>{keyValue('isReleaseGranted', as.flgSolturaConcedida)}</>
                                                        <>{keyValue('warrantIssuanceReason', as.motivoExpedicaoALvara)}</>
                                                        <>{keyValue('custodyMunicipality', as.municipioCustodia)}</>
                                                        <>{keyValue('pieceNumber', as.numeroPeca)}</>
                                                        <>{keyValue('numeroProcesso', as.numeroProcesso)}</>
                                                        <>{keyValue('observations', as.observacoes)}</>
                                                        <>{keyValue('judicialBody', as.orgaoJudiciario)}</>
                                                        <>{keyValue('pieceStatus', as.statusPeca)}</>
                                                        <>{keyValue('releaseWarrantType', as.tipoAlvaraSoltura)}</>
                                                        <>{keyValue('custodyState', as.ufCustodia)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.guiaInternacao.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('hospitalizationGuide')}</MyAppText>
                                            {
                                                warrantInformation.guiaInternacao.map(gi => (
                                                    <View key={gi.id} style={{ gap: 5 }}>
                                                        <>{keyValue('minimumInternmentYears', gi.anoPrazoMinimoInternacao)}</>
                                                        <>{keyValue('imposedConditions', gi.condicoesImpostas)}</>
                                                        <>{keyValue('doctorCrm', gi.crmMedico)}</>
                                                        <>{keyValue('conclusionDate', gi.dataConclusao, true)}</>
                                                        <>{keyValue('serverConfirmationDate', gi.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('createdAt', gi.dataCriacao, true)}</>
                                                        <>{keyValue('reportIssuanceDate', gi.dataEmissaoLaudo, true)}</>
                                                        <>{keyValue('issuanceDate', gi.dataExpedicao, true)}</>
                                                        <>{keyValue('endSuspension366Date', gi.dataFimSuspensao366, true)}</>
                                                        <>{keyValue('endSuspension89Date', gi.dataFimSuspensao89, true)}</>
                                                        <>{keyValue('offenseDate', gi.dataInfracao, true)}</>
                                                        <>{keyValue('startSuspension366Date', gi.dataInicioSuspensao366, true)}</>
                                                        <>{keyValue('startSuspension89Date', gi.dataInicioSuspensao89, true)}</>
                                                        <>{keyValue('rulingPublicationDate', gi.dataPublicacaoAcordao, true)}</>
                                                        <>{keyValue('pronouncementPublicationDate', gi.dataPublicacaoPronuncia, true)}</>
                                                        <>{keyValue('sentencePublicationDate', gi.dataPublicacaoSentenca, true)}</>
                                                        <>{keyValue('complaintReceiptDate', gi.dataRecebimentoDenuncia, true)}</>
                                                        <>{keyValue('judgmentDefenseTransitDate', gi.dataTransitoJulgadoDefesa, true)}</>
                                                        <>{keyValue('judgmentProsecutionTransitDate', gi.dataTransitoJulgadoMinisterioPublico, true)}</>
                                                        <>{keyValue('offenseLocationDescription', gi.descricaoLocalOcorrenciaInfracao)}</>
                                                        <>{keyValue('minimumInternmentDays', gi.diasPrazoMinimoInternacao)}</>
                                                        <>{keyValue('complianceLocation', gi.localCumprimento)}</>
                                                        <>{keyValue('minimumInternmentMonths', gi.mesPrazoMinimoInternacao)}</>
                                                        <>{keyValue('curatorName', gi.nomeCurador)}</>
                                                        <>{keyValue('defenseAttorneyName', gi.nomeDefensor)}</>
                                                        <>{keyValue('organizationName', gi.nomeOrgao)}</>
                                                        <>{keyValue('pieceNumber', gi.numeroPeca)}</>
                                                        <>{keyValue('processNumber', gi.numeroProcesso)}</>
                                                        <>{keyValue('totalDetractionDays', gi.numeroTotalDiasDetracaoPena)}</>
                                                        <>{keyValue('minimumInternmentPeriod', gi.prazoMinimoInternacao)}</>
                                                        <>{keyValue('cancellationJustificationText', gi.textoJustificativaCancelamento)}</>
                                                        <>{keyValue('observationsText', gi.textoObservacoes)}</>
                                                        <>{keyValue('otherProcessesText', gi.textoOutrosProcessos)}</>
                                                        <>{keyValue('suspensionArticle366Text', gi.textoSuspensaoArt366Cpp)}</>
                                                        <>{keyValue('internmentGuideType', gi.tipoGuiaInternacao)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.contramandado.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('countermand')}</MyAppText>
                                            {
                                                warrantInformation.contramandado.map(c => (
                                                    <View key={c.dataConclusao} style={{ gap: 5 }}>
                                                        <>{keyValue('conclusionDate', c.dataConclusao, true)}</>
                                                        <>{keyValue('serverConfirmationDate', c.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('createdAt', c.dataCriacao, true)}</>
                                                        <>{keyValue('issuanceDate', c.dataExpedicao, true)}</>
                                                        <>{keyValue('prosecutorPrisonExpirationDate', c.dataExpiracaoPrisaoMP, true)}</>
                                                        <>{keyValue('warrantExpirationDate', c.dataVencimentoMandados, true)}</>
                                                        <>{keyValue('complianceDescription', c.descricaoCumprimento)}</>
                                                        <>{keyValue('issuanceReasonDescription', c.descricaoMotivoExpedicao)}</>
                                                        <>{keyValue('observationDescription', c.descricaoObservacao)}</>
                                                        <>{keyValue('decisionSummaryDescription', c.descricaoSinteseDecisao)}</>
                                                        <>{keyValue('countermandTypeDescription', c.descricaoTipoContramandado)}</>
                                                        <>{keyValue('measureTypeDescription', c.descricaoTipoMedida)}</>
                                                        <>{keyValue('pieceTypeDescription', c.descricaoTipoPeca)}</>
                                                        <>{keyValue('hasOtherMeasures', c.flgOutrasMedidas)}</>
                                                        <>{keyValue('isHouseArrest', c.flgPrisaoDomiciliar)}</>
                                                        <>{keyValue('pieceNumber', c.numeroPeca)}</>
                                                        <>{keyValue('processNumber', c.numeroProcesso)}</>
                                                        <>{keyValue('warrantSequence', c.seq_mandado)}</>
                                                        <>{keyValue('pieceSequence', c.seq_peca)}</>
                                                        <>{keyValue('cancellationJustificationText', c.textoJustificativaCancelamento)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.guiaRecolhimento.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('arrestedWarrantCertificate')}</MyAppText>
                                            {
                                                warrantInformation.guiaRecolhimento.map(gr => (
                                                    <View key={gr.id} style={{ gap: 5 }}>
                                                        <>{keyValue('conclusionDate', gr.dataConclusao, true)}</>
                                                        <>{keyValue('relatedPieceConclusionDate', gr.dataConclusaoPecaRelacionada, true)}</>
                                                        <>{keyValue('serverConfirmationDate', gr.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('createdAt', gr.dataCriacao, true)}</>
                                                        <>{keyValue('relatedPieceCreationDate', gr.dataCriacaoPecaRelacionada, true)}</>
                                                        <>{keyValue('relatedPieceIssuanceDate', gr.dataExpedicaPecaRelacionada, true)}</>
                                                        <>{keyValue('issuanceDate', gr.dataExpedicao, true)}</>
                                                        <>{keyValue('endSuspension366Date', gr.dataFimSuspensao366, true)}</>
                                                        <>{keyValue('endSuspension89Date', gr.dataFimSuspensao89, true)}</>
                                                        <>{keyValue('offenseDate', gr.dataInfracao, true)}</>
                                                        <>{keyValue('startSuspension366Date', gr.dataInicioSuspensao366, true)}</>
                                                        <>{keyValue('startSuspension89Date', gr.dataInicioSuspensao89, true)}</>
                                                        <>{keyValue('accordPublicationDate', gr.dataPublicacaoAcordao, true)}</>
                                                        <>{keyValue('pronouncementPublicationDate', gr.dataPublicacaoPronuncia, true)}</>
                                                        <>{keyValue('sentencePublicationDate', gr.dataPublicacaoSentenca, true)}</>
                                                        <>{keyValue('complaintReceiptDate', gr.dataRecebimentoDenunciaQueixa, true)}</>
                                                        <>{keyValue('defenseJudgmentDate', gr.dataTransitoJulgadoDefesa, true)}</>
                                                        <>{keyValue('prosecutionJudgmentDate', gr.dataTransitoJulgadoMinisterioPublico, true)}</>
                                                        <>{keyValue('offenseLocationDescription', gr.descricaoLocalOcorrenciaDelito)}</>
                                                        <>{keyValue('currentConvictLocationDescription', gr.descricaoLocalSituacaoAtualCondenado)}</>
                                                        <>{keyValue('additionalInformationDescription', gr.descricaoOutrasInformacoes)}</>
                                                        <>{keyValue('otherProcessesDescription', gr.descricaoOutrosProcessos)}</>
                                                        <>{keyValue('recidivismDescription', gr.descricaoReincidencia)}</>
                                                        <>{keyValue('establishment', gr.estabelecimento)}</>
                                                        <>{keyValue('city', gr.municipio)}</>
                                                        <>{keyValue('offenseCity', gr.municipioOcorrencia)}</>
                                                        <>{keyValue('defenderName', gr.nomeDefensor)}</>
                                                        <>{keyValue('pieceNumber', gr.numeroPeca)}</>
                                                        <>{keyValue('relatedPieceNumber', gr.numeroPecaRelacionada)}</>
                                                        <>{keyValue('processNumber', gr.numeroProcesso)}</>
                                                        <>{keyValue('relatedPieceProcessNumber', gr.numeroProcessoPecaRelacionada)}</>
                                                        <>{keyValue('totalYearsSentenceImposed', gr.numeroTotalAnoPenaImpostaProcesso)}</>
                                                        <>{keyValue('totalDaysDeducted', gr.numeroTotalDiasDetracaoPena)}</>
                                                        <>{keyValue('totalFineDays', gr.numeroTotalDiasMulta)}</>
                                                        <>{keyValue('totalDaysSentenceImposed', gr.numeroTotalDiasPenaImpostaProcesso)}</>
                                                        <>{keyValue('totalMonthsSentenceImposed', gr.numeroTotalMesPenaImpostaProcesso)}</>
                                                        <>{keyValue('relatedPieceAgency', gr.orgaoPecaRelacionada)}</>
                                                        <>{keyValue('courtAgency', gr.orgaoTribunal)}</>
                                                        <>{keyValue('prisonRegime', gr.regimePrisional)}</>
                                                        <>{keyValue('relatedPieceStatus', gr.statusPecaRelacionada)}</>
                                                        <>{keyValue('cancellationJustificationText', gr.textoJustificativaCancelamento)}</>
                                                        <>{keyValue('guideType', gr.tipoGuia)}</>
                                                        <>{keyValue('pieceType', gr.tipoPeca)}</>
                                                        <>{keyValue('relatedPieceType', gr.tipoPecaRelacionada)}</>
                                                        <>{keyValue('state', gr.uf)}</>
                                                        <>{keyValue('establishmentState', gr.ufEstabelecimento)}</>
                                                        <>{keyValue('offenseState', gr.ufOcorrencia)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.certidaoExtincaoPunibilidade.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('certificateExtinctionPunishment')}</MyAppText>
                                            {
                                                warrantInformation.certidaoExtincaoPunibilidade.map(c => (
                                                    <View key={c.dataConclusao} style={{ gap: 5 }}>
                                                        <>{keyValue('conclusionDate', c.dataConclusao, true)}</>
                                                        <>{keyValue('serverConfirmationDate', c.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('createdAt', c.dataCriacao, true)}</>
                                                        <>{keyValue('issuanceDate', c.dataExpedicao, true)}</>
                                                        <>{keyValue('establishment', c.estabelecimento)}</>
                                                        <>{keyValue('state', c.estado)}</>
                                                        <>{keyValue('city', c.municipio)}</>
                                                        <>{keyValue('pieceNumber', c.numeroPeca)}</>
                                                        <>{keyValue('processNumber', c.numeroProcesso)}</>
                                                        <>{keyValue('complianceText', c.textoCumprimento)}</>
                                                        <>{keyValue('cancellationJustificationText', c.textoJustificativaCancelamento)}</>
                                                        <>{keyValue('custodyLocationText', c.textoLocalCustodia)}</>
                                                        <>{keyValue('observationText', c.textoObservacao)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.certidaoCumpriMentoMandadoInternacao.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('certificateExecutionIncarcerationWarrant')}</MyAppText>
                                            {
                                                warrantInformation.certidaoCumpriMentoMandadoInternacao.map(c => (
                                                    <View key={c.id} style={{ gap: 5 }}>
                                                        <>{keyValue('conclusionDate', c.dataConclusao, true)}</>
                                                        <>{keyValue('serverConfirmationDate', c.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('createdAt', c.dataCriacao, true)}</>
                                                        <>{keyValue('complianceDate', c.dataExpedicao, true)}</>
                                                        <>{keyValue('complianceDescription', c.descricaoCumprimento)}</>
                                                        <>{keyValue('internmentLocationDescription', c.descricaoLocalInternacao)}</>
                                                        <>{keyValue('internmentResponsibleDescription', c.descricaoResponsavelInternacao)}</>
                                                        <>{keyValue('state', c.estado)}</>
                                                        <>{keyValue('city', c.municipio)}</>
                                                        <>{keyValue('pieceNumber', c.numeroPeca)}</>
                                                        <>{keyValue('processNumber', c.numeroProcesso)}</>
                                                        <>{keyValue('internmentOrderSeq', c.seqMandadoInternacao)}</>
                                                        <>{keyValue('cancellationJustificationText', c.textoJustificativaCancelamento)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.mandadoInternacao.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('incarcerationWarrant')}</MyAppText>
                                            {
                                                warrantInformation.mandadoInternacao.map(mi => (
                                                    <View key={mi.id} style={{ gap: 5 }}>
                                                        <>{keyValue('conclusionDate', mi.dataConclusao, true)}</>
                                                        <>{keyValue('serverConfirmationDate', mi.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('createdAt', mi.dataCriacao, true)}</>
                                                        <>{keyValue('issuanceDate', mi.dataExpedicao, true)}</>
                                                        <>{keyValue('expirationDate', mi.dataValidade, true)}</>
                                                        <>{keyValue('complianceDescription', mi.descricaoCumprimento)}</>
                                                        <>{keyValue('occurrenceLocationDescription', mi.descricaoLocalOcorrencia)}</>
                                                        <>{keyValue('observationDescription', mi.descricaoObservacao)}</>
                                                        <>{keyValue('decisionSummaryDescription', mi.descricaoSinteseDecisao)}</>
                                                        <>{keyValue('internmentType', mi.especieInternacao)}</>
                                                        <>{keyValue('internmentOrderLink', mi.linkMandadoInternacao)}</>
                                                        <>{keyValue('pieceNumber', mi.numeroPeca)}</>
                                                        <>{keyValue('minimumInternmentPeriodYear', mi.numeroPrazoMinimoInternacaoAno)}</>
                                                        <>{keyValue('minimumInternmentPeriodDay', mi.numeroPrazoMinimoInternacaoDia)}</>
                                                        <>{keyValue('minimumInternmentPeriodMonth', mi.numeroPrazoMinimoInternacaoMes)}</>
                                                        <>{keyValue('processNumber', mi.numeroProcesso)}</>
                                                        <>{keyValue('sentenceDurationYear', mi.numeroTempoPenaAno)}</>
                                                        <>{keyValue('sentenceDurationDay', mi.numeroTempoPenaDia)}</>
                                                        <>{keyValue('sentenceDurationMonth', mi.numeroTempoPenaMes)}</>
                                                        <>{keyValue('prisonRegime', mi.regimePrisional)}</>
                                                        <>{keyValue('prisonOrderConversionSeq', mi.seqMandadoPrisaoConversao)}</>
                                                        <>{keyValue('cancellationJustificationText', mi.textoJustificativaCancelamento)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.certidaoArquivamentoGuia.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('certificateFilingGuide')}</MyAppText>
                                            {
                                                warrantInformation.certidaoArquivamentoGuia.map(c => (
                                                    <View key={c.id} style={{ gap: 5 }}>
                                                        <>{keyValue('conclusionDate', c.dataConclusao, true)}</>
                                                        <>{keyValue('serverConfirmationDate', c.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('createdAt', c.dataCriacao, true)}</>
                                                        <>{keyValue('issuanceDate', c.dataExpedicao, true)}</>
                                                        <>{keyValue('archivingReason', c.motivoArquivamento)}</>
                                                        <>{keyValue('pieceNumber', c.numeroPeca)}</>
                                                        <>{keyValue('processNumber', c.numeroProcesso)}</>
                                                        <>{keyValue('complianceText', c.textoCumprimento)}</>
                                                        <>{keyValue('cancellationJustificationText', c.textoJustificativaCancelamento)}</>
                                                        <>{keyValue('observationText', c.textoObservacao)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.foto.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('photo')}</MyAppText>
                                            {
                                                warrantInformation.foto.map(f => (
                                                    <View key={f.id} style={{ gap: 5 }}>
                                                        <>{keyValue('photoDate', f.dataFoto, true)}</>
                                                        <>{keyValue('isMainPhoto', f.flgFotoPrincipal)}</>
                                                        <>{keyValue('photo', f.foto)}</>
                                                        <>{keyValue('photoOrigin', f.origemFoto)}</>
                                                        <>{keyValue('photoContentType', f.tipoConteudoFoto)}</>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    warrantInformation.mandadoPrisao.length ?
                                        <View style={styles.cardBodySplitter}>
                                            <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('arrestWarrants')}</MyAppText>
                                            {
                                                warrantInformation.mandadoPrisao.map(mp => (
                                                    <View key={mp.dataCriacao} style={{ gap: 5 }}>
                                                        <>{keyValue('serverRole', mp.cargoServidor)}</>
                                                        <>{keyValue('conclusionDate', mp.dataConclusao, true)}</>
                                                        <>{keyValue('serverConfirmationDate', mp.dataConfirmacaoServidor, true)}</>
                                                        <>{keyValue('createdAt', mp.dataCriacao, true)}</>
                                                        <>{keyValue('issueDate', mp.dataExpedicao, true)}</>
                                                        <>{keyValue('prisonMandateExpirationDate', mp.dataExpiracaoPrisaoMandadoPrisao, true)}</>
                                                        <>{keyValue('offenseDate', mp.dataInfracao, true)}</>
                                                        <>{keyValue('arrestDate', mp.dataPrisao, true)}</>
                                                        <>{keyValue('validityDate', mp.dataValidade, true)}</>
                                                        <>{keyValue('warrantExpirationDate', mp.dataVencimentoMandados, true)}</>
                                                        <>{keyValue('complianceDescription', mp.descricaoCumprimento)}</>
                                                        <>{keyValue('justificationDescription', mp.descricaoJustificativa)}</>
                                                        <>{keyValue('incidentLocationDescription', mp.descricaoLocalOcorrencia)}</>
                                                        <>{keyValue('prisonType', mp.especiePrisao)}</>
                                                        <>{keyValue('prisonMandateLink', mp.linkMandadoPrisao)}</>
                                                        <>{keyValue('custodyMunicipality', mp.municipioCustodia)}</>
                                                        <>{keyValue('prisonEstablishmentName', mp.nomeEstabelecimentoPrisional)}</>
                                                        <>{keyValue('judgeName', mp.nomeMagistrado)}</>
                                                        <>{keyValue('serverName', mp.nomeServidor)}</>
                                                        <>{keyValue('documentNumber', mp.numeroPeca)}</>
                                                        <>{keyValue('prisonDeadlineNumber', mp.numeroPrazoPrisao)}</>
                                                        <>{keyValue('caseNumber', mp.numeroProcesso)}</>
                                                        <>{keyValue('observation', mp.observacao)}</>
                                                        <>{keyValue('judiciaryBody', mp.orgaoJudiciario)}</>
                                                        <>{keyValue('prisonRegime', mp.regimePrisional)}</>
                                                        <>{keyValue('documentSequence', mp.seqPeca)}</>
                                                        <>{keyValue('confidentiality', mp.sigilo)}</>
                                                        <>{keyValue('decisionSummary', mp.sinteseDecisao)}</>
                                                        <>{keyValue('status', mp.status)}</>
                                                        <>{keyValue('penaltyTimeYear', mp.tempoPenaAno)}</>
                                                        <>{keyValue('penaltyTimeDay', mp.tempoPenaDia)}</>
                                                        <>{keyValue('penaltyTimeMonth', mp.tempoPenaMes)}</>
                                                        <>{keyValue('cancellationJustificationText', mp.textoJustificativaCancelamento)}</>
                                                        <>{keyValue('documentType', mp.tipoPeca)}</>
                                                        <>{keyValue('custodyState', mp.ufCustodia)}</>
                                                        {mp.tipificacaoPenal.length ?
                                                            <View style={[styles.cardBodySplitter]}>
                                                                <MyAppText style={{ fontWeight: 'bold', fontSize: 14 }}>{translate('penalClassification')}</MyAppText>
                                                                {mp.tipificacaoPenal.map(tp => (
                                                                    <View style={{ paddingLeft: 10 }} key={tp.id}>
                                                                        <>{keyValue('categoryDescription', tp.descricaoCategoriaTipificacaoPenal)}</>
                                                                        <>{keyValue('isChildOrAdolescent', tp.flgCriancaAdolescente)}</>
                                                                        <>{keyValue('isAttemptedCrime', tp.flgCrimeTentado)}</>
                                                                        <>{keyValue('isElderly', tp.flgIdoso)}</>
                                                                        <>{keyValue('isWoman', tp.flgMulher)}</>
                                                                        <>{keyValue('isDomesticViolence', tp.flgViolenciaDomestica)}</>
                                                                        <>{keyValue('categoryName', tp.nomeCategoriaTipificacaoPenal)}</>
                                                                        <>{keyValue('categoryLabel', tp.rotuloCategoria)}</>
                                                                        <>{keyValue('penalClassificationLabel', tp.rotuloTipificacaoPenal)}</>
                                                                    </View>
                                                                ))}
                                                            </View> : null
                                                        }
                                                    </View>
                                                ))
                                            }
                                        </View>
                                        : null
                                }
                            </ScrollView> : null
                    }
                </View >
            </View>
        </Modal >
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25,
        width: '95%',
        height: '90%'
    },
    cardBodySplitter: {
        paddingVertical: 10
    }
}));
