import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    View,
    TouchableOpacity,
    TextInput,
    ScrollView,
    ActivityIndicator,
    Image,
    StyleSheet,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
} from 'react-native';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

import personDefaultPicture from '../../../assets/person-default-picture.png';
import { translate } from '../../services/translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { Pagination } from '../../components/Pagination';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { facialService, GetFacialPeopleParameters } from '../../services/central-api/facial';
import { MyCheckbox } from '../../components/MyCheckbox';
import { RouteProp, useRoute } from '@react-navigation/native';
import { FacialParamList } from '../../typings/Params';

interface PeopleListParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

export default function PeopleList({ navigation, authenticatedUser }: PeopleListParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const loaders = useRef({ didPageLoad: false, didFilterNameLoad: false, didFileLoad: false });

    const route = useRoute<RouteProp<FacialParamList, 'PeopleList'>>();

    const [loading, setLoading] = useState(false);
    const [people, setPeople] = useState<FacialPerson[]>([]);
    const [filterName, setFilterName] = useState(route.params?.name ?? '');
    const [onlyActiveFilter, setOnlyActiveFilter] = useState(route.params?.onlyActive);

    const [filters, setFilters] = useState<GetFacialPeopleParameters & { photo?: File; }>({
        page: Math.max(route.params?.page - 1, 0),
        name: filterName,
        limit: Number(route.params.limit) || 25,
        onlyActive: onlyActiveFilter,
        photo: undefined
    });
    const [totalItems, setTotalItems] = useState(0);
    const [showPagination, setShowPagination] = useState(true);

    const [file, setFile] = useState<File | undefined>(filters.photo);

    useEffect(() => {
        if (!loaders.current.didFilterNameLoad) {
            loaders.current.didFilterNameLoad = true;
            return;
        }
        if (filterName == '' && file) {
            return;
        }
    }, [filterName, onlyActiveFilter]);

    useEffect(() => {
        getPeople(filters);
    }, [filters]);

    useEffect(() => {
        if (!loaders.current.didFileLoad) {
            loaders.current.didFileLoad = true;
            return;
        }
        if (file === undefined && filterName) {
            return;
        }
        setFilterName('');
        setFilters({ ...filters, name: '', photo: file, page: 0, onlyActive: onlyActiveFilter });
    }, [file, onlyActiveFilter]);


    async function getPeople(filters: GetFacialPeopleParameters & { photo?: File; }) {
        try {
            setLoading(true);

            if (filters.photo) {
                const response = await facialService.findPeopleByPicture({
                    photo: filters.photo,
                    onlyActive: onlyActiveFilter
                });
                setPeople(response.people);
                setShowPagination(false);
            } else {
                const response = await facialService.getFacialPeople(filters);
                setPeople(response.rows);
                setTotalItems(response.count);
                setShowPagination(true);
            }
        } catch (err) {
            // TODO: add proper error treatment
            console.error(err);
        } finally {
            setLoading(false);
        }

    }

    function handleFilters() {
        setFilters({
            ...filters,
            name: filterName,
            onlyActive: onlyActiveFilter,
            page: 0
        });

        navigation.setParams({
            name: filterName,
            onlyActive: onlyActiveFilter,
            page: 1
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='register' lateralMenu='facial' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ maxWidth: 300, minWidth: 150 }}>
                            <MyAppText style={styles.filterText}>{translate('searchByName')}</MyAppText>
                            <TextInput
                                style={[
                                    styles.filterInput,
                                    { width: 300 },
                                    filterName == '' ? { color: getThemedColor(theme, '#CBCBCB') } : undefined
                                ]}
                                value={filterName}
                                onChangeText={setFilterName}
                                onKeyPress={handleKeyDown}
                                placeholder={translate('typeToSearch')}
                            />
                        </View>
                        <View style={{ minWidth: 200 }}>
                            <MyAppText style={styles.filterText}>{translate('searchByPicture')}</MyAppText>
                            <TouchableOpacity
                                style={{
                                    borderWidth: 1,
                                    borderColor: getThemedColor(theme, '#888888'),
                                    borderRadius: 2,
                                    backgroundColor: getThemedColor(theme, '#FFFFFF'),
                                    width: 200,
                                    height: 40,
                                    justifyContent: 'center',
                                }}
                            >
                                <label style={{ width: '100%', height: '100%', display: 'grid', justifyContent: 'center' }}>
                                    <input
                                        style={{ display: 'none' }}
                                        type='file'
                                        onChange={(e) => {
                                            if (!e.target.files) {
                                                return;
                                            }
                                            const file = e.target.files[0];
                                            setFile(file);
                                        }}
                                        accept='image/*'
                                    />
                                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <FontAwesomeIcon icon={faFileUpload} color={getThemedColor(theme, '#222222')} />
                                        <MyAppText style={{ color: getThemedColor(theme, '#222222'), alignSelf: 'center', fontSize: 16, marginLeft: 10 }}>
                                            {translate('chooseFile')}
                                        </MyAppText>
                                    </View>
                                </label>
                            </TouchableOpacity>
                        </View>
                        <View style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                            <MyCheckbox
                                style={{ height: 40 }}
                                label={translate('onlyActive')}
                                checked={onlyActiveFilter}
                                setChecked={value => {
                                    setOnlyActiveFilter(value);
                                }}
                            />
                        </View>
                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.newButton} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newButtonText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => {
                                navigation.navigate('PersonForm');
                            }} style={styles.newButton}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newButtonText}>{translate('newRegister')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={[styles.cardList, { borderRadius: !showPagination ? 8 : undefined }]}>
                            <ScrollView>
                                <View style={{ gap: 10 }}>
                                    {loading ?
                                        <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                                        : <View style={[{
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            columnGap: 14
                                        }]}>
                                            {people.map((person) =>
                                                <TouchableOpacity
                                                    key={JSON.stringify(person)}
                                                    onPress={async () => {
                                                        navigation.navigate('PersonForm', { id: person.id });
                                                    }}
                                                >
                                                    <View style={[{
                                                        width: 150,
                                                        height: 250,
                                                        borderRadius: 5,
                                                        rowGap: 10
                                                    }]}>
                                                        <Image
                                                            style={{
                                                                height: 168, width: 144,
                                                                borderRadius: 8,
                                                                overflow: 'hidden',
                                                            }}
                                                            source={person.facePictures.length ? { uri: person.facePictures[0].imageUrl } : personDefaultPicture}
                                                        />
                                                        <View>
                                                            <MyAppText style={{ color: getThemedColor(theme, '#222222'), fontWeight: '500', fontSize: 14 }}>
                                                                {person.name}
                                                            </MyAppText>
                                                            <MyAppText style={{ color: getThemedColor(theme, '#222222'), fontWeight: '500', fontSize: 9 }}>
                                                                {translate('addedOn')} {moment(person.createdAt ? new Date(person.createdAt) : undefined).format('DD/MM/YYYY HH:mm')}
                                                            </MyAppText>
                                                            <MyAppText style={{ color: getThemedColor(theme, '#222222'), fontWeight: '500', fontSize: 11 }}>
                                                                {person.isActive ? translate('active') : translate('inactive')}
                                                            </MyAppText>
                                                        </View>
                                                    </View>
                                                </TouchableOpacity>
                                            )}
                                        </View>
                                    }
                                </View>
                            </ScrollView>
                        </View>
                        {showPagination
                            ? <Pagination
                                totalItems={totalItems}
                                currentPage={filters.page}
                                pageSize={filters.limit}
                                setPageSize={pageSize => {
                                    setFilters(old => ({
                                        ...old,
                                        limit: pageSize,
                                        page: 0,
                                    }));

                                    navigation.setParams({
                                        ...route.params,
                                        limit: pageSize,
                                        page: 0,
                                    });
                                }}
                                setPage={page => {
                                    setFilters(old => ({
                                        ...old,
                                        page
                                    }));

                                    navigation.setParams({
                                        ...route.params,
                                        page: page + 1
                                    });
                                }}
                            />
                            : null
                        }
                    </View>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            flexWrap: 'wrap',
            gap: 10
        },
        cardList: {
            borderWidth: 1,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            // borderColor: theme.colors.dataTable.border,
            // backgroundColor: theme.colors.dataTable.background,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1,
        },
        newButton: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },

        buttonsContainer: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            marginTop: 20
        }
    });
}
