import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import ActingBodiesDropdown from './ActingBodiesDropdown';

interface Props {
    filters: DispatchParamList['Assignments'];
    setFilters: React.Dispatch<React.SetStateAction<DispatchParamList['Assignments']>>;
    authenticatedUser?: AuthenticatedUser;
    textFilter: string;
    setTextFilter: (value: string) => void;
}

function Filter({ authenticatedUser, filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<DispatchParamList, 'Assignments'>>>();

    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);

    const handleCleanFilter = () => {
        setTextFilter('');
        setActingBodyId(0);
    };

    const handleFilter = () => {
        navigation.setParams({
            ...filters,
            page: 1,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
        });

        setFilters({
            ...filters,
            page: 1,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_assignments) &&
                <ActingBodiesDropdown includeAllOption={true} value={actingBodyId} setValue={setActingBodyId} zIndex={2} />
            }

        </Filters>
    );
}


export default Filter;
