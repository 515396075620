import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';

interface Props {
    filters: DispatchParamList['OccurrenceTypes'];
    setFilters: React.Dispatch<React.SetStateAction<DispatchParamList['OccurrenceTypes']>>;
    authenticatedUser?: AuthenticatedUser;
    textFilter: string;
    setTextFilter: (value: string) => void;
}

function Filter({ authenticatedUser, filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<DispatchParamList, 'OccurrenceTypes'>>>();

    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [isActive, setIsActive] = useState<boolean>(filters.isActive);

    const handleCleanFilter = () => {
        setTextFilter('');
        setActingBodyId(0);
        setIsActive(false);
    };

    const handleFilter = () => {
        navigation.setParams({
            ...filters,
            page: 1,
            isActive,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
        });

        setFilters({
            ...filters,
            page: 1,
            isActive,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />

            <FilterCheckBox
                label={translate('active')}
                value={isActive}
                onChange={setIsActive}
            />

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrence_types) &&
                <ActingBodiesDropdown includeAllOption={true} value={actingBodyId} setValue={setActingBodyId} zIndex={2} />
            }

        </Filters>
    );
}


export default Filter;
