import React, { createContext, useContext, useState } from 'react';

type AuthContextType = {
    user?: AuthenticatedUser;
    setUser: React.Dispatch<React.SetStateAction<AuthenticatedUser | undefined>>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be inside AuthProvider');
    }

    return context;
}


const AuthProvider = (props: { children: React.ReactNode; }): React.ReactElement => {
    const [user, setUser] = useState<AuthenticatedUser | undefined>(undefined);

    return <AuthContext.Provider {...props} value={{ user, setUser }} />;
};


export { AuthProvider, useAuth };