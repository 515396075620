import React from 'react';
import LateralMenu from '../LateralMenu';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_events.png';
import collapsedLogo from '../../../assets/events_icon.png';

export type SelectedMenu = 'events';

interface EventLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}

export default function EventLateralMenu({ navigation, selectedMenu, open, authenticatedUser, setOpen }: EventLateralMenuProps) {
    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faIdBadge,
                text: translate('events'),
                action: () => navigation.navigate('Events', { screen: 'Events' }),
                selected: selectedMenu == 'events'
            }]}
            goBack={() => navigation.navigate('Menu')}
        />
    );
}
