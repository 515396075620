import React, { useRef } from 'react';
import { View } from 'react-native';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../services/translate';
import { dispatchService } from '../services/central-api/dispatch';
import CustomButton from './CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface FileUploadProps {
    occurrenceId: number;
    callback: (occurrenceReport: OccurrenceReport) => void;
}

export default function FileUploader({ occurrenceId, callback }: FileUploadProps) {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { styles } = useStyles(stylesheet);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <input
                type='file'
                style={{ display: 'none' }}
                onChange={async (e) => {
                    try {
                        if (!e.target.files) {
                            return;
                        }

                        const file = e.target.files[0];
                        const occurrenceReport = await dispatchService.createOccurrenceAttachment(occurrenceId, file);
                        callback(occurrenceReport);
                    } catch (err) {
                        console.error(err);
                    }
                }}
                ref={(input) => (fileInputRef.current = input)}
            />
            <View style={styles.buttonContainer}>
                <CustomButton text={translate('attach')} icon={faUpload} onPress={handleUploadClick} />
            </View>
        </>
    );
}
const stylesheet = createStyleSheet(() => ({
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }
}));

