import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import GoogleMapReact from 'google-map-react';
import { createStyleSheet, UnistylesRuntime, useStyles } from 'react-native-unistyles';

export default function RouteMap({ cameraPositions }: { cameraPositions: CameraMap[]; }) {
    const { styles } = useStyles(styleSheet);

    const [map, setMap] = useState<google.maps.Map>();
    const [maps] = useState<typeof google.maps>(window?.google?.maps);

    function loadRouteInfo() {
        try {
            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer({ map: map });
            directionsService.route(
                {
                    origin: { lat: Number(cameraPositions[0].latitude), lng: Number(cameraPositions[0].longitude) },
                    destination: { lat: Number(cameraPositions[cameraPositions.length - 1].latitude), lng: Number(cameraPositions[cameraPositions.length - 1].longitude) },
                    waypoints: cameraPositions.slice(1, -1).map(position => ({
                        location: { lat: Number(position.latitude), lng: Number(position.longitude) },
                        stopover: true,
                    })),
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        directionsRenderer.setDirections(response);
                    } else {
                        console.error(`Directions request failed due to ${status}`);
                    }
                }
            );
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(loadRouteInfo, [map, maps, cameraPositions]);

    return (
        <View style={styles.mapContainer}>
            <GoogleMapReact
                defaultCenter={{
                    lat: -23.533773,
                    lng: -46.625290
                }}
                draggable={true}
                defaultZoom={11}
                options={() => ({
                    streetViewControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                    styles: UnistylesRuntime.themeName === 'light' || UnistylesRuntime.themeName == 'lightAlt' ? [{
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        stylers: [{ visibility: 'off' }],
                    }] : [
                        { featureType: 'poi', stylers: [{ visibility: 'off' }] },
                        { featureType: 'transit', stylers: [{ visibility: 'off' }] },
                        { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
                        { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
                        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
                        {
                            featureType: 'administrative.locality',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#d59563' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#38414e' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#212a37' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#9ca5b3' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry',
                            stylers: [{ color: '#746855' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#1f2835' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#f3d19c' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#17263c' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#515c6d' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.stroke',
                            stylers: [{ color: '#17263c' }],
                        },
                    ]
                })}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }: { map: google.maps.Map; }) => setMap(map)}>
            </GoogleMapReact>
        </View >
    );
}


const styleSheet = createStyleSheet(() => ({
    mapContainer: {
        flex: 1
    }
}));

