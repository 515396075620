import React, { useContext, useEffect, useState } from 'react';
import { View, Modal, TouchableOpacity } from 'react-native';
import moment from 'moment';
import ThemeContext from '../../../context/Theme';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { accessService } from '../../../services/central-api/access';
import { cameraService } from '../../../services/central-api/cameras';
import Toast from 'react-native-toast-message';
import { WindowInformation } from '../../../services/window-information';
import { ClientError } from '../../../services/central-api/base-service';
import { Tabs } from '../../../components/Tabs';
import { ScrollView } from 'react-native-web-hover';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { SelectableItem, SelectableList } from '../../../components/SelectableList';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import '../../../styles/time-picker.css';
import MyAppText from '../../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    alertGroupId?: number;
}

export default function UpsertAlertGroupModal({ onClose, alertGroupId, isModalVisible, setModalVisible }: ModalProps) {
    const { theme } = useContext(ThemeContext);
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [times, setTimes] = useState<AlertGroupTimes>({});

    const [displayFilter, setDisplayFilter] = useState<string[]>([]);
    const [selectableCameras, setSelectableCameras] = useState<SelectableItem[]>([]);
    const [selectedCameras, setSelectedCameras] = useState<SelectableItem[]>([]);

    function isNameInvalid(value: string) {
        return !value || value == '';
    }

    function isFormValid() {
        if (isNameInvalid(name)) {
            return false;
        }

        return Object.values(times).every(dayTimes =>
            dayTimes.every(interval =>
                moment(interval.start, 'HH:mm').isSameOrBefore(moment(interval.end, 'HH:mm'), 'minute')
            )
        );
    }

    function clearValues() {
        setName('');
        setDescription('');
        setTimes({});
        setSelectedCameras([]);
    }

    async function getAlertGroup(alertGroupId: number) {
        const alertGroup = await cameraService.getAlertGroup(alertGroupId);
        setName(alertGroup.name);
        setDescription(alertGroup.description);
        setTimes(alertGroup.times);

        const selectedCameras = alertGroup.cameras.map((camera) => {
            return {
                id: camera.id,
                name: camera.title
            };
        });
        setSelectedCameras(selectedCameras);

        return alertGroup;
    }

    async function loadPageInfo() {
        try {
            const cameras = await accessService.getAllCameras({});

            const groupCamerasIds: Record<string, boolean> = {};
            if (alertGroupId) {
                const alertGroup = await getAlertGroup(alertGroupId);
                alertGroup.cameras.forEach((camera) => groupCamerasIds[camera.id] = true);
            }

            setSelectableCameras(cameras
                .filter((camera) => !groupCamerasIds[camera.id])
                .map((camera) => {
                    return {
                        id: camera.id || 0,
                        name: camera.title
                    };
                }));

            setDisplayFilter(cameras.map(camera => {
                return camera.id;
            }));
        } catch (error) {
            console.error(error);

            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        if (isModalVisible) {
            loadPageInfo();
        }
    }, [isModalVisible]);

    async function formSubmit() {
        try {
            if (alertGroupId) {
                await cameraService.updateAlertGroup({
                    id: alertGroupId,
                    name,
                    description,
                    times,
                    cameras: selectedCameras.map(c => String(c.id)),
                });
            } else {
                await cameraService.createAlertGroup({
                    name,
                    description,
                    times,
                    cameras: selectedCameras.map(c => String(c.id)),
                });
            }

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            onClose();
            clearValues();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function addDayInterval(weekDay: number) {
        if (!Array.isArray(times[weekDay])) {
            times[weekDay] = [];
        }

        times[weekDay].push({ start: '', end: '' });
        setTimes({ ...times });
    }

    function renderDayIntervals(weekDay: number) {
        const dayTimes = times[weekDay] ?? [];

        return dayTimes.map((_, index) => {
            return (
                <View key={index} style={styles.timeIntervalContainer}>
                    <View style={styles.timeIntervalContent}>
                        <MyAppText>{translate('start')}</MyAppText>
                        <TimePicker
                            disableClock={true}
                            format='HH:mm'
                            locale='pt-BR'
                            maxDetail='minute'
                            className={theme === 'light' ? 'react-time-picker__wrapper-light' : 'react-time-picker__wrapper-dark'}
                            value={times[weekDay][index].start}
                            onChange={(value) => {
                                if (value) {
                                    times[weekDay][index].start = value;
                                    setTimes({ ...times });
                                }
                            }}
                        />
                    </View>
                    <View style={styles.timeIntervalContent}>
                        <MyAppText>{translate('end')}</MyAppText>
                        <TimePicker
                            disableClock={true}
                            format='HH:mm'
                            locale='pt-BR'
                            maxDetail='minute'
                            className={theme === 'light' ? 'react-time-picker__wrapper-light' : 'react-time-picker__wrapper-dark'}
                            value={times[weekDay][index].end}
                            onChange={(value) => {
                                if (value) {
                                    times[weekDay][index].end = value;
                                    setTimes({ ...times });
                                }
                            }}
                        />
                    </View>
                    <TouchableOpacity style={styles.removeIntervalButton} onPress={() => {
                        times[weekDay].splice(index, 1);
                        setTimes({ ...times });
                    }}>
                        <MyAppText>
                            <FontAwesomeIcon fontSize={20} icon={faXmark} />
                        </MyAppText>
                    </TouchableOpacity>
                </View >
            );
        });
    }

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '30%' }]}>
                    <Tabs tabs={[{
                        key: 'details',
                        label: translate('details')
                    }, {
                        key: 'cameras',
                        label: translate('cameras')
                    }, {
                        key: 'silence',
                        label: translate('silence')
                    }]}>
                        {({ selectedTab }) => {
                            if (selectedTab == 'details') {
                                return (<>
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={name}
                                        onChangeText={setName}
                                        invalid={isNameInvalid}
                                    />
                                    <FormInput
                                        label={translate('description')}
                                        placeholder={translate('description')}
                                        value={description}
                                        onChangeText={setDescription}
                                        invalid={() => false}
                                    />
                                </>);
                            } else if (selectedTab == 'cameras') {
                                return <View style={{ rowGap: 10, flex: 1 }}>
                                    <SelectableList
                                        displayFilter={displayFilter}
                                        selectableLabel={translate('cameras')}
                                        selectableContent={selectableCameras}
                                        selectedLabel={translate('participants')}
                                        selectedContent={selectedCameras}
                                    />
                                </View>;
                            } else if (selectedTab == 'silence') {
                                return <ScrollView style={{ rowGap: 10, flex: 1 }}>
                                    <View style={{ paddingBottom: 15 }}>
                                        <MyAppText style={styles.silenceTabDescription}>{translate('silenceTabDescription')}</MyAppText>
                                    </View>
                                    <View>
                                        <MyAppText style={[styles.weekDayText, { paddingTop: 0 }]}>{translate('monday')}</MyAppText>
                                        {renderDayIntervals(1)}
                                        <TouchableOpacity style={styles.addIntervalButton} onPress={() => addDayInterval(1)}>
                                            <MyAppText>+ {translate('addInterval')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        <MyAppText style={styles.weekDayText}>{translate('tuesday')}</MyAppText>
                                        {renderDayIntervals(2)}
                                        <TouchableOpacity style={styles.addIntervalButton} onPress={() => addDayInterval(2)}>
                                            <MyAppText>+ {translate('addInterval')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        <MyAppText style={styles.weekDayText}>{translate('wednesday')}</MyAppText>
                                        {renderDayIntervals(3)}
                                        <TouchableOpacity style={styles.addIntervalButton} onPress={() => addDayInterval(3)}>
                                            <MyAppText>+ {translate('addInterval')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        <MyAppText style={styles.weekDayText}>{translate('thursday')}</MyAppText>
                                        {renderDayIntervals(4)}
                                        <TouchableOpacity style={styles.addIntervalButton} onPress={() => addDayInterval(4)}>
                                            <MyAppText>+ {translate('addInterval')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        <MyAppText style={styles.weekDayText}>{translate('friday')}</MyAppText>
                                        {renderDayIntervals(5)}
                                        <TouchableOpacity style={styles.addIntervalButton} onPress={() => addDayInterval(5)}>
                                            <MyAppText>+ {translate('addInterval')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        <MyAppText style={styles.weekDayText}>{translate('saturday')}</MyAppText>
                                        {renderDayIntervals(6)}
                                        <TouchableOpacity style={styles.addIntervalButton} onPress={() => addDayInterval(6)}>
                                            <MyAppText>+ {translate('addInterval')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        <MyAppText style={styles.weekDayText}>{translate('sunday')}</MyAppText>
                                        {renderDayIntervals(0)}
                                        <TouchableOpacity style={styles.addIntervalButton} onPress={() => addDayInterval(0)}>
                                            <MyAppText>+ {translate('addInterval')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                </ScrollView>;
                            }
                        }}
                    </Tabs>

                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        height: '70%',
        borderRadius: 2,
        padding: 20,
        minHeight: 520,
        justifyContent: 'space-between',
        rowGap: 10,
        backgroundColor: theme.colors.backgroundColor,
    },
    weekDayText: {
        fontSize: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontWeight: 'bold'
    },
    timeIntervalContainer: {
        gap: 5,
        flexDirection: 'row'
    },
    timeIntervalContent: {
        width: 125
    },
    addIntervalButton: {
        width: 250,
        paddingTop: 10
    },
    removeIntervalButton: {
        paddingBottom: 4,
        paddingLeft: 4,
        justifyContent: 'flex-end',
    },
    silenceTabDescription: {
        fontSize: 12
    }
}));
