import React, { useEffect } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData, TouchableOpacity, View } from 'react-native';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import { useState } from 'react';
import MyDataTable from '../../../components/MyDataTable';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { RouteProp, useRoute } from '@react-navigation/native';
import { GuardianAppParamList } from '../../../typings/Params';
import { guardianAppService } from '../../../services/central-api/guardian-app';
import EditModal from './EditModal';
import DeleteModal from '../../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { ClientError, GenericPaginatedParameters } from '../../../services/central-api/base-service';
import { TextMask } from 'react-native-masked-text';
import FilterInput from '../../../components/Filter/components/FilterInput';
import CustomButton from '../../../components/CustomButton';

interface Props {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

function GuardianAppUsers({ navigation, authenticatedUser }: Props) {
    const { styles, theme } = useStyles(styleSheet);

    const [data, setData] = useState<GuardianWoman[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const [selectedGuardianWoman, setSelectedGuardianWoman] = useState<number | null>(null);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedGuardianWomanToDelete, setSelectedGuardianWomanToDelete] = useState<GuardianWoman | null>(null);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    const route = useRoute<RouteProp<GuardianAppParamList, 'Users'>>();

    const [filters, setFilters] = useState<GenericPaginatedParameters>({
        page: Math.max(route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    const getGuardianWoman = async () => {
        try {
            setIsLoading(true);
            const data = await guardianAppService.getPaginatedGuardianWoman(filters);
            setTotalItems(data.count);
            setData(data.rows);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    async function confirmDelete(guardianWoman: GuardianWoman) {
        try {
            await guardianAppService.deleteGuardianWoman(guardianWoman.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setDeleteModalVisible(false);
            getGuardianWoman();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setSelectedGuardianWoman(null);
        }
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    useEffect(() => {
        getGuardianWoman();
    }, []);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='users' lateralMenu='guardianApp' contentContainerStyle={{ padding: 10, rowGap: 15 }} authenticatedUser={authenticatedUser}>
                <View style={styles.headerContent}>

                    <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                        <FilterInput
                            label={translate('searchRegisteredWoman')}
                            value={textFilter ?? ''}
                            onChange={text => {
                                setTextFilter(text);
                            }}
                            placeholder={translate('typeToSearch')}
                            onKeyPress={handleKeyDown}
                        />
                    </View>

                    <View style={{ flex: 1 }}></View>

                    <View style={styles.buttonsContainer}>
                        <CustomButton
                            icon={faPlus}
                            onPress={() => setModalVisible(true)}
                            text={translate('create')}
                        />
                    </View>
                </View>

                <View style={{ flex: 1 }}>
                    <View style={styles.cardList}>
                        <MyDataTable
                            progressPending={isLoading}
                            columns={[
                                {
                                    name: 'ID',
                                    selector: row => row.id,
                                    grow: 0
                                },
                                {
                                    name: translate('phone'),
                                    cell: row => <View style={{ width: '100%' }}>
                                        <TextMask
                                            style={{ color: theme.colors.textColor, fontFamily: 'Open Sans' }}
                                            type={'cel-phone'}
                                            value={row.phone} />
                                    </View>,
                                    wrap: true,
                                    minWidth: '150px',
                                    maxWidth: '150px'
                                },
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    wrap: true,
                                },
                                {
                                    name: translate('address'),
                                    selector: row => row.address,
                                    wrap: true,
                                },
                                {
                                    name: translate('actions'),
                                    cell: row => (
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedGuardianWoman(row.id);
                                                setModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedGuardianWomanToDelete(row);
                                                setDeleteModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity>
                                        </View>
                                    ),
                                    wrap: true,
                                    button: true
                                }
                            ]}
                            data={data}
                        />
                    </View>
                    <Pagination
                        totalItems={totalItems}
                        currentPage={filters.page}
                        pageSize={filters.limit}
                        setPageSize={pageSize => {
                            setFilters(old => ({
                                ...old,
                                limit: pageSize,
                                page: 0,
                            }));

                            navigation.setParams({
                                ...route.params,
                                limit: pageSize,
                                page: 0,
                            });
                        }}
                        setPage={page => {
                            setFilters(old => ({
                                ...old,
                                page
                            }));

                            navigation.setParams({
                                ...route.params,
                                page: page + 1
                            });
                        }}
                    />
                </View>

                <EditModal
                    guardianWomanId={selectedGuardianWoman}
                    isModalVisible={isModalVisible}
                    setModalVisible={setModalVisible}
                    onSubmit={async () => {
                        await getGuardianWoman();
                    }}
                    onClose={() => {
                        setSelectedGuardianWoman(null);
                    }}
                />

                <DeleteModal
                    setModalVisible={setDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={selectedGuardianWomanToDelete?.name || ''}
                    onSubmit={async () => {
                        if (selectedGuardianWomanToDelete) {
                            await confirmDelete(selectedGuardianWomanToDelete);
                        }
                    }}
                />
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    headerContent: {
        minHeight: 65,
        flexDirection: 'row',
        columnGap: 10,
        flexWrap: 'wrap',
        rowGap: 10,
        zIndex: 2
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));

export default GuardianAppUsers;
