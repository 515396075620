import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, ScrollView, TouchableOpacity, View } from 'react-native';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../../typings/Params';
import { cameraService } from '../../../services/central-api/cameras';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import Label from './Label';
import moment from 'moment';
import TimeButton from './TimeButton';

interface Props {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

function VideoAnalysisDetails({ navigation, authenticatedUser }: Props) {
    const { styles, theme } = useStyles(stylesheet);

    const route = useRoute<RouteProp<CamerasParamList, 'VideoAnalysisDetails'>>();

    const [data, setData] = useState<VideoAnalysis>();
    const [isLoading, setIsLoading] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const getVideoAnalysis = async () => {
        try {
            setIsLoading(true);

            const data = await cameraService.getVideoAnalysis(route.params.id);

            setData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }

    };

    const skipTo = (time: string) => {
        if (videoRef.current) {
            videoRef.current.currentTime = moment.duration(time.split(':').length == 2 ? `00:${time}` : time).asSeconds();
        }
    };

    useEffect(() => {
        getVideoAnalysis();
    }, [route.params.id]);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='video_analysis' lateralMenu='cameras' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <TouchableOpacity onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate('VideoAnalysis')}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.iconColor} />
                        </TouchableOpacity>
                        <MyAppText style={{ fontSize: 24, fontWeight: 'bold' }}>
                            {translate('videoAnalysis')}
                        </MyAppText>
                    </View>

                    <View style={styles.body}>
                        {
                            !data || isLoading ?
                                <ActivityIndicator size='small' color={theme.colors.iconColor} />
                                :
                                <ScrollView>
                                    <MyAppText style={[styles.divider, { fontSize: 18, fontWeight: 'bold' }]}>
                                        {translate('analysisInfoLabel')}
                                    </MyAppText>

                                    <View style={styles.dataContainer}>
                                        <Label title={translate('status')} value={translate(data.status)} />
                                        <Label title={translate('requestAnalysisDate')} value={moment(data.createdAt).format('DD/MM/YYYY')} />
                                        <Label title={translate('requestAnalysisHour')} value={moment(data.createdAt).format('hh:mm')} />
                                        <Label
                                            title={translate('analysisPeriod')}
                                            value={`${moment(+data.msStart).format('DD/MM/YYYY')} - ${moment(+data.msStart).format('hh:mm')} ${translate('to')} ${moment(+data.msFinish).format('hh:mm')}`}
                                            maxWidth={300}
                                        />
                                    </View>

                                    <View style={styles.dataContainer}>
                                        <Label title={translate('request')} value={data.prompt} maxWidth={'100%'} />
                                    </View>

                                    <MyAppText style={[styles.divider, { fontSize: 18, fontWeight: 'bold' }]}>
                                        {translate('cameraInfoLabel')}
                                    </MyAppText>

                                    <View style={styles.dataContainer}>
                                        <Label title={translate('camera')} value={data.camera.title} />
                                        <Label title={translate('address')} value={data.camera.address} />
                                        <Label title={translate('cameraType')} value={data.camera.type.toUpperCase()} />
                                        <Label title={translate('cameraTitleIdOrSerialNumber')} value={data.camera.serialNo} />
                                    </View>

                                    <MyAppText style={[styles.divider, { fontSize: 18, fontWeight: 'bold' }]}>
                                        {translate('requesterInfoLabel')}
                                    </MyAppText>

                                    <View style={styles.dataContainer}>
                                        <Label title={translate('name')} value={data.user.name} />
                                        <Label title={translate('email')} value={data.user.email} maxWidth={300} />
                                        <Label title={translate('registration')} value={data.user.registry} />
                                        <Label title={translate('phone')} value={data.user.phone} />
                                    </View>

                                    <MyAppText style={[styles.divider, { fontSize: 18, fontWeight: 'bold' }]}>
                                        {translate('analysisResultLabel')}
                                    </MyAppText>

                                    <View style={{ gap: 15 }}>
                                        <ScrollView horizontal contentContainerStyle={{ flexDirection: 'row', columnGap: 10, paddingRight: 10, paddingBottom: 10 }}>
                                            {data.analysisResult.map((result, id) => (
                                                <TimeButton key={id} value={result.timestamp} onPress={() => skipTo(result.timestamp)} />
                                            ))}
                                        </ScrollView>

                                        <video ref={videoRef} width='640' height='360' controls>
                                            <source src={data.videoUrl} type='video/mp4' />
                                        </video>
                                    </View>
                                </ScrollView>
                        }
                    </View>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },

    header: {
        alignItems: 'center',
        flexDirection: 'row',
        minWidth: 200,
        columnGap: 10
    },

    body: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        padding: 24,
        backgroundColor: theme.colors.backgroundColor,
        flex: 1,
        flexDirection: 'column',
    },

    dataContainer: {
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        gap: 30,
        marginBottom: 30,
        flexWrap: 'wrap'
    },

    divider: {
        width: '100%',
        borderBottomColor: theme.colors.borderColor,
        borderBottomWidth: 2,
        marginBottom: 10
    }
}));

export default VideoAnalysisDetails;
