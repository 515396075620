import React from 'react';
import LateralMenu from '../LateralMenu';
import { faVideo, faClipboardList, faUser } from '@fortawesome/free-solid-svg-icons';

import { translate } from '../../services/translate';
import logo from '../../../assets/logo_dashboard.png';
import collapsedLogo from '../../../assets/dashboard_icon.png';

export type SelectedMenu = 'cameras' | 'occurrences' | 'agents';

interface DashboardLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}
export default function DashboardLateralMenu({ navigation, selectedMenu, open, authenticatedUser, setOpen }: DashboardLateralMenuProps) {
    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faClipboardList,
                text: translate('occurrences'),
                action: () => navigation.navigate('Dashboard', { screen: 'DashboardOccurrences' }),
                selected: selectedMenu == 'occurrences'
            }, {
                icon: faVideo,
                text: translate('cameras'),
                action: () => navigation.navigate('Dashboard', { screen: 'DashboardCameras' }),
                selected: selectedMenu == 'cameras'
            }, {
                icon: faUser,
                text: translate('agents'),
                action: () => navigation.navigate('Dashboard', { screen: 'DashboardAgents' }),
                selected: selectedMenu == 'agents'
            }]}
            goBack={() => navigation.navigate('Menu')} />
    );
}
