
import React, { useContext, useEffect, useState } from 'react';
import {
    View,
    StyleSheet,
    ScrollView,
    TouchableOpacity,
    Image,
    ActivityIndicator
} from 'react-native';

import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { centralAPI } from '../../services/central-api';
import { translate } from '../../services/translate';
import moment from 'moment';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import PlateImage from '../../components/lpr/PlateImage';

interface AgentOccurrencesParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

export default function AgentOccurrences({ navigation, authenticatedUser }: AgentOccurrencesParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [occurrences, setOccurrences] = useState<Occurrence[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function getOccurrences(): Promise<void> {
        try {
            setIsLoading(true);
            const occurrencesRes = await centralAPI.gcmAgentOccurrences();
            setOccurrences(occurrencesRes);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOccurrences();
        const interval = setInterval(() => {
            if (!window.location.href.split('?')[0].endsWith('gcm-agent/agent-occurrences')) {
                return;
            }
            getOccurrences();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    function renderItem(occurrence: Occurrence) {
        return <TouchableOpacity key={occurrence.id} onPress={() => {
            navigation.navigate('GcmAgent', {
                screen: 'AgentOccurrenceDetails',
                params: { occurrenceId: occurrence.id }
            });
        }}>
            <View style={styles.card}>
                <View style={{ flex: 1 }}>
                    <MyAppText style={styles.mainText}>
                        {occurrence.triggerType
                            ? translate(occurrence.triggerType)
                            : occurrence.occurrenceType.name
                        }
                    </MyAppText>
                    <MyAppText style={styles.dateText}>
                        {moment(occurrence.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                    </MyAppText>
                </View>
                {occurrence.triggerType == 'face_detected' || occurrence.triggerType == 'mp_face_detected' ?
                    <View style={styles.imageBox}>
                        <Image
                            source={{ uri: occurrence.resume.imageUrl }}
                            style={styles.image}
                        />
                    </View>
                    : null}
                {occurrence.triggerType == 'plate_detected' ?
                    <PlateImage src={occurrence.resume.imageUrl} platePosition={occurrence.resume.platePosition} />
                    : null
                }
            </View>
        </TouchableOpacity>;
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout
                navigation={navigation}
                selectedMenu='occurrences'
                lateralMenu='gcmAgent'
                authenticatedUser={authenticatedUser}
            >
                {isLoading ?
                    <View style={styles.loadingView}>
                        <MyAppText style={styles.loadingText}>
                            {translate('loading')}...
                        </MyAppText>
                        <ActivityIndicator
                            animating={true}
                            style={styles.loading}
                            size='large'
                            color='#0071bc'
                        >
                        </ActivityIndicator>
                    </View> :

                    occurrences.length ?
                        <ScrollView>
                            <View style={styles.container}>
                                {occurrences.map((occurrence) => (
                                    renderItem(occurrence)
                                ))}
                            </View>
                        </ScrollView>
                        : <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <MyAppText style={{ color: getThemedColor(theme, '#58595B') }}>
                                {translate('noResults')}.
                            </MyAppText>
                        </View>}
            </DefaultPageLayout>
        </DefaultPageContainer >);
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        loading: {
            marginHorizontal: 'auto',
            transform: [{ scaleX: 2 }, { scaleY: 2 }],
            width: '20%',
            height: '20%',
        },
        loadingText: {
            color: getThemedColor(theme, '#000000'),
            textAlign: 'center',
            fontSize: 16
        },
        loadingView: {
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center'
        },
        container: {
            flex: 1,
            padding: 16,
            rowGap: 10
        },
        card: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#EEEEEE'),
            padding: 8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            height: 80,
            flex: 1
        },
        mainText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 15,
            flex: 1
        },
        dateText: {
            color: getThemedColor(theme, '#888888'),
            fontSize: 12,
        },
        image: {
            height: 64,
            width: 64,
            borderRadius: 4,
            objectFit: 'contain',
        },
        imageBox: {
            height: 64,
            width: 64,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center'
        },
    });
}
