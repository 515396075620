import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, ActivityIndicator, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { cameraService } from '../../services/central-api/cameras';
import moment from 'moment';
import MyDataTable from '../../components/MyDataTable';
import MyAppText from '../../components/MyAppText';
import Toast from 'react-native-toast-message';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../typings/Params';
import getBestContrastColor from '../../services/best-contrast-color';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FilterInput from '../../components/Filter/components/FilterInput';

export default function Downloads({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(stylesheet);

    const route = useRoute<RouteProp<CamerasParamList, 'Downloads'>>();

    // filters
    const [filters, setFilters] = useState<{ page: number; textFilter?: string, limit: number; }>({
        page: Math.max(+route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);

    const [downloads, setDownloads] = useState<Download[]>([]);
    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    async function getDownloads() {
        try {
            const response = await cameraService.getDownloads(filters);
            setDownloads(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getDownloads();
        const interval = setInterval(async () => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('cameras/downloads')) {
                return;
            }
            getDownloads();
        }, 15000);

        return () => clearInterval(interval);
    }, [filters]);

    function handleFilters() {
        setFilters({
            ...filters,
            page: 0,
            textFilter
        });

        navigation.setParams({
            page: 1,
            textFilter
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='downloads' lateralMenu='cameras' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
                            <FilterInput
                                label={translate('searchCamera')}
                                value={textFilter || ''}
                                placeholder={translate('typeToSearch')}
                                onChange={text => {
                                    setTextFilter(text);
                                }}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                    </View>

                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyDataTable
                                columns={[
                                    {
                                        name: translate('name'),
                                        selector: row => row.name,
                                        wrap: true,
                                    },
                                    {
                                        name: translate('user'),
                                        selector: row => row.user?.name || '',
                                    },
                                    {
                                        name: translate('camera'),
                                        selector: row => row.camera?.title || '',
                                        wrap: true,
                                    },
                                    {
                                        name: translate('status'),
                                        cell: row =>
                                            <View style={{ flexDirection: 'row', columnGap: 5, alignItems: 'center' }}>
                                                {row.status == 'pending' ?
                                                    <ActivityIndicator size={16} color={theme.colors.loadingColor} /> :
                                                    <FontAwesomeIcon
                                                        style={row.status == 'success' ? styles.success : styles.failed}
                                                        fontSize={16}
                                                        icon={row.status == 'success' ? faCheckCircle : faTimesCircle}
                                                    />
                                                }

                                                <MyAppText style={row.status == 'success' ? styles.success : row.status == 'failed' ? styles.failed : {}}>{translate(row.status)}</MyAppText>
                                            </View>
                                    },
                                    {
                                        name: translate('createdAt'),
                                        selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
                                    },
                                    {
                                        name: translate('tags'),
                                        cell: row =>
                                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5, paddingVertical: 5 }}>
                                                {row.tags ?
                                                    row.tags.map((tag) =>
                                                        <View key={tag.id} style={[styles.tagMarker, { backgroundColor: tag.color }]}>
                                                            <MyAppText style={styles.markerText(tag.color)}>
                                                                {tag.name}
                                                            </MyAppText>
                                                        </View>
                                                    ) : null
                                                }</View>
                                    },
                                    {
                                        name: translate('action'),
                                        button: true,
                                        cell: row =>
                                            <TouchableOpacity disabled={isDownloading} onPress={async () => {
                                                try {
                                                    setIsDownloading(true);
                                                    const video = await cameraService.getDownload(row.id);
                                                    window.open(video.resultUrl);
                                                } catch (error) {
                                                    Toast.show({
                                                        type: 'sentinelxError',
                                                        text1: translate('unexpectedError'),
                                                    });
                                                } finally {
                                                    setIsDownloading(false);
                                                }
                                            }}>
                                                {row.status == 'success' && (
                                                    authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_watch_download
                                                )
                                                    ? <FontAwesomeIcon
                                                        fontSize={16}
                                                        color={theme.colors.iconColor}
                                                        icon={faDownload}
                                                    />
                                                    : null
                                                }
                                            </TouchableOpacity>
                                    }
                                ]}
                                data={downloads}
                                progressPending={isLoading}
                            />
                        </View>
                        <Pagination
                            totalItems={totalItems}
                            currentPage={filters.page}
                            pageSize={filters.limit}
                            setPageSize={pageSize => {
                                setFilters(old => ({
                                    ...old,
                                    limit: pageSize,
                                    page: 0,
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    limit: pageSize,
                                    page: 0,
                                });
                            }}
                            setPage={page => {
                                setFilters(old => ({
                                    ...old,
                                    page
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    page: page + 1
                                });
                            }}
                        />
                    </View>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        flexWrap: 'wrap'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    success: {
        color: '#017B27'
    },
    failed: {
        color: '#B40119'
    },
    tagMarker: {
        borderRadius: 8,
        height: 16,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 8,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11
    }),
}));
