import React, { useContext, useEffect, useMemo, useState } from 'react';
import DefaultPageContainer from '../components/DefaultPageContainer';
import MyAppText from '../components/MyAppText';
import { View, Image, ActivityIndicator, TouchableOpacity, StyleSheet } from 'react-native';
import getThemedColor from '../services/get-themed-color';
import { translate } from '../services/translate';
import ThemeContext from '../context/Theme';
import PasswordInput from '../components/passwordInput';
import PasswordValidator from 'password-validator';
import Toast from 'react-native-toast-message';
import { ClientError, NotFound } from '../services/central-api/base-service';
import { WindowInformation } from '../services/window-information';
import { centralAPI } from '../services/central-api';
import { UnistylesRuntime } from 'react-native-unistyles';

interface ResetPasswordProps {
    route: {
        params?: {
            token?: string;
        };
    };
}

export default function ResetPassword({ route }: ResetPasswordProps) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();
    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isValidMin, setIsValidMin] = useState(false);
    const [isValidDigits, setIsValidDigits] = useState(false);
    const [isValidUppercase, setIsValidUppercase] = useState(false);
    const [isValidLowercase, setIsValidLowercase] = useState(false);
    const isSmartSampa = useMemo(() => window.location.hostname.includes('smartsampa'), [window.location]);

    const validator = new PasswordValidator();
    validator.is().min(8).has().uppercase().has().lowercase().has().digits(2);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        const missingValidations = validator.validate(password, { list: true }) as string[];
        setIsValidMin(!missingValidations.includes('min'));
        setIsValidUppercase(!missingValidations.includes('uppercase'));
        setIsValidLowercase(!missingValidations.includes('lowercase'));
        setIsValidDigits(!missingValidations.includes('digits'));
    }, [password]);

    useEffect(() => {
        if (password === '' || confirmPassword === '' || confirmPassword !== password) {
            return setIsFormValid(false);
        }

        if (!validator.validate(password)) {
            return setIsFormValid(false);
        }

        return setIsFormValid(true);
    }, [password, confirmPassword]);

    async function resetPassword() {
        try {
            setLoading(true);
            await centralAPI.resetPassword(
                password,
                confirmPassword,
                route.params?.token || ''
            );
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            window.location.href = '/login';

        } catch (err) {
            if (err instanceof NotFound) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate('TokenNotFound'),
                });
            }

            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <DefaultPageContainer justifyContent='center' alignItems='center'>
            <View style={[styles.cardList, windowInfo.isMobile ? { height: '100%' } : {}]}>

                <View style={styles.imageView}>
                    {isSmartSampa && !windowInfo.isMobileOrTablet ?
                        <Image style={styles.image} source={require(`../../assets/big_smartsampa_logo_${UnistylesRuntime.themeName}.png`)} />
                        :
                        <Image style={styles.image} source={require(`../../assets/SentinelX_logo_${UnistylesRuntime.themeName}.png`)} />
                    }
                </View>
                <View style={styles.formContainer}>
                    <View style={{ gap: 10 }}>
                        <MyAppText style={styles.headerText}>{translate('passwordRequirements')}</MyAppText>
                        <View style={{ gap: 5 }}>
                            <MyAppText style={[styles.label, password === '' ? null : isValidMin ? styles.valid : styles.invalid]}>{`\u2022 ${translate('atLeast8Characters')}`}</MyAppText>
                            <MyAppText style={[styles.label, password === '' ? null : isValidDigits ? styles.valid : styles.invalid]}>{`\u2022 ${translate('atLeast2Digits')}`}</MyAppText>
                            <MyAppText style={[styles.label, password === '' ? null : isValidUppercase ? styles.valid : styles.invalid]}>{`\u2022 ${translate('atLeastOneUppercase')}`}</MyAppText>
                            <MyAppText style={[styles.label, password === '' ? null : isValidLowercase ? styles.valid : styles.invalid]}>{`\u2022 ${translate('atLeastOneLowercase')}`}</MyAppText>
                        </View>
                    </View>
                    <PasswordInput
                        label={translate('newPassword')}
                        placeholder=''
                        value={password}
                        onChangeText={setPassword}
                        invalid={() => password === ''}
                    />
                    <PasswordInput
                        label={translate('confirmNewPassword')}
                        placeholder=''
                        value={confirmPassword}
                        onChangeText={setConfirmPassword}
                        invalid={() => password != confirmPassword}
                        errorMessage={translate('passwordsNotEqual')}
                    />

                    {loading ?
                        <View style={{ height: 40 }}>
                            <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                        </View>
                        : <TouchableOpacity style={[styles.saveButton, !isFormValid ? styles.buttonDisabled : null]} onPress={resetPassword}>
                            <MyAppText style={[styles.saveButtonText, !isFormValid ? styles.textDisabled : null]}>{translate('save')}</MyAppText>
                        </TouchableOpacity>
                    }
                </View>
            </View>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            flexDirection: 'column',
            gap: 30,
            maxWidth: 375,
            width: '100%'
        },
        saveButton: {
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 5,
            justifyContent: 'center',
            height: 40
        },
        saveButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 18,
            textAlign: 'center',
        },
        buttonDisabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        },
        image: {
            width: '100%',
            resizeMode: 'contain',
            height: '100%',
        },
        imageView: {
            width: 260,
            height: 40,
        },
        headerText: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold'
        },
        bodyText: {
            fontSize: 14,
            color: getThemedColor(theme, '#888888'),
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        valid: {
            color: getThemedColor(theme, '#008E2C'),
        },
        invalid: {
            color: '#FF0027',
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            paddingVertical: 50,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 50,
            maxWidth: 570,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        },
    });
}
