import * as React from 'react';

import Mpegts from 'mpegts.js';
import Hls from 'hls.js';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import MyAppText from './MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faForward, faPause, faPlay, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Hoverable } from 'react-native-web-hover';
import { translate } from '../services/translate';

export interface VideoPlayerProps {
    onSetLive: (videoPlayer: VideoPlayer) => void;
    hideControls?: boolean,
    watermark?: string,
    totalCameras?: number,
    onClickEvent?: () => void,
    camera: Camera;
}

export default class VideoPlayer extends React.Component<VideoPlayerProps> {

    private videoRef: React.RefObject<HTMLVideoElement>;
    private flvPlayer?: Mpegts.Player;
    private hlsPlayer?: Hls;
    private playing: boolean;
    private myProps: VideoPlayerProps;
    private isLive: boolean;
    private playbackRate: number;
    private showPlaybackModal: boolean;
    private currentCallback: () => void;

    constructor(props: VideoPlayerProps) {
        super(props);
        this.flvPlayer = undefined;
        this.hlsPlayer = undefined;
        this.videoRef = React.createRef<HTMLVideoElement>();
        this.playing = true;
        this.myProps = props;
        this.isLive = true;
        this.playbackRate = 1.0;
        this.showPlaybackModal = false;
        this.currentCallback = () => { };
    }

    private setPlaybackRate(playbackRate: number) {
        this.playbackRate = playbackRate;

        const video = this.videoRef?.current;
        if (!video) {
            return console.error('video not ready');
        }

        video.playbackRate = this.playbackRate;

    }

    private destroyPlayers() {
        if (this.flvPlayer) {
            this.flvPlayer.unload();
            this.flvPlayer.detachMediaElement();
            this.flvPlayer.destroy();
            this.flvPlayer = undefined;
        }
        if (this.hlsPlayer) {
            this.hlsPlayer.destroy();
            this.hlsPlayer = undefined;
        }
    }

    setIsLive(isLive: boolean) {
        this.isLive = isLive;
    }

    componentWillUnmount() {
        this.destroyPlayers();
    }

    componentDidMount() {
    }

    onVideoEnded(callback: () => void) {
        const video = this.videoRef?.current;
        if (!video) {
            return console.error('video not ready');
        }

        video.removeEventListener('ended', this.currentCallback);

        this.currentCallback = () => {
            video?.removeEventListener('ended', this.currentCallback);
            callback();
        };

        video.addEventListener('ended', this.currentCallback);
    }

    /**
     * Set the current video playing at given time
     * @param timeAt in seconds
     * @returns
     */
    setTimeAt(timeAt: number) {
        const video = this.videoRef?.current;
        if (!video) {
            return console.error('video not ready');
        }

        video.addEventListener('loadedmetadata', () => {
            video.currentTime = timeAt;
        }, false);
    }

    /**
     *
     * @returns currentTime in seconds
     */
    getTime() {
        const video = this.videoRef?.current;
        if (!video) {
            throw new Error('video not ready');
        }

        return video.currentTime;
    }

    setSrc(src: string, customHeaders?: { [key: string]: string; }) {
        const video = this.videoRef?.current;
        if (!video) {
            return console.error('video not ready');
        }

        this.destroyPlayers();
        if (src.endsWith('.mp4')) {
            video.src = src;
            video.playbackRate = this.playbackRate;
            return;
        }

        this.setPlaybackRate(1.0);

        if (this.myProps.camera.thirdPartyCamera?.type === 'hls') {
            if (Hls.isSupported()) {
                this.hlsPlayer = new Hls();
                this.hlsPlayer.loadSource(src);
                this.hlsPlayer.attachMedia(video);
                video.play();
                return;
            } else {
                return console.error('unsupported browser.');
            }
        } else {
            this.flvPlayer = Mpegts.createPlayer({
                type: 'mse',
                url: src,
                isLive: true,
                withCredentials: false,
            }, {
                enableWorker: true,
                lazyLoadMaxDuration: 3 * 60,
                seekType: 'range',
                liveBufferLatencyChasing: true,
                headers: customHeaders
            });
            this.flvPlayer.attachMediaElement(video);
            this.flvPlayer.load();
            this.flvPlayer.play();
        }
    }

    async togglePlay() {
        if (this?.myProps?.hideControls) return;
        const video = this.videoRef?.current;
        if (!video) {
            return console.error('video not ready');
        }
        if (this.playing) {
            video.pause();
        } else {
            await video.play();
        }

        this.playing = !this.playing;
    }

    render() {
        return (
            <View style={{ display: 'flex', flex: 1 }}>
                <View style={{ backgroundColor: 'black', display: 'flex', flex: 1 }}>
                    <View style={{ position: 'absolute', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                        <View style={{ width: 50, height: 50 }}>
                            <FontAwesomeIcon size='2x' spin={true} icon={faSpinner} color='#fff'></FontAwesomeIcon>
                        </View>
                    </View>
                    <video
                        disablePictureInPicture={true}
                        style={{ zIndex: 2, height: '100%', width: '100%', objectFit: 'fill', position: 'absolute', left: 0, top: 0 }}
                        autoPlay
                        muted
                        ref={this.videoRef}
                    ></video>
                    {!this.showPlaybackModal ?
                        <TouchableOpacity
                            style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 4, flexDirection: 'column', justifyContent: 'space-between', padding: 5 }}
                            onPress={async () => {
                                if (this.myProps?.onClickEvent) {
                                    return this.myProps?.onClickEvent();
                                }
                                this.togglePlay();
                            }}>
                            <View style={styles.topView}>
                                <MyAppText style={styles.topText}>{this.myProps.camera.title}</MyAppText>
                                <MyAppText style={styles.topText}>{this.myProps.watermark}</MyAppText>
                            </View>
                        </TouchableOpacity>
                        : null}
                </View>
                {this.myProps.hideControls ? null :
                    <View style={{
                        backgroundColor: '#000028',
                        padding: 5,
                        paddingLeft: 15,
                        paddingRight: 15,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <View style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 10
                        }}>
                            <TouchableOpacity onPress={async () => {
                                this.togglePlay();
                            }}>
                                {this.playing ?
                                    <FontAwesomeIcon icon={faPause} color='#fff'></FontAwesomeIcon>
                                    : <FontAwesomeIcon icon={faPlay} color='#fff'></FontAwesomeIcon>
                                }
                            </TouchableOpacity>
                            {!this.isLive ?
                                <TouchableOpacity onPress={() => this.myProps.onSetLive(this)}>
                                    <MyAppText
                                        style={{ color: '#fff', backgroundColor: '#cf0000', borderRadius: 5, paddingHorizontal: 5 }}>{translate('goLive')}</MyAppText>
                                </TouchableOpacity>
                                : null}
                            <View style={{ flexDirection: 'row', gap: 15 }}>
                                {!this.isLive ?
                                    <View>
                                        {this.showPlaybackModal ?
                                            <View style={{
                                                position: 'absolute',
                                                backgroundColor: '#000028',
                                                width: 60,
                                                top: -131,
                                                zIndex: 5,
                                                height: 125,
                                                padding: 5,
                                                left: -5
                                            }}>
                                                <TouchableOpacity onPress={() => {
                                                    this.showPlaybackModal = false;
                                                    this.setPlaybackRate(1.0);
                                                }}>
                                                    <Hoverable>
                                                        {({ hovered }) => (
                                                            <MyAppText style={{
                                                                padding: 2,
                                                                color: hovered ? '#000028' : '#FFFFFF',
                                                                backgroundColor: hovered ? '#FFFFFF' : '#000028'
                                                            }}>1x</MyAppText>
                                                        )}
                                                    </Hoverable>
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => {
                                                    this.showPlaybackModal = false;
                                                    this.setPlaybackRate(2.0);
                                                }}>
                                                    <Hoverable>
                                                        {({ hovered }) => (
                                                            <MyAppText style={{
                                                                padding: 2,
                                                                color: hovered ? '#000028' : '#FFFFFF',
                                                                backgroundColor: hovered ? '#FFFFFF' : '#000028'
                                                            }}>2x</MyAppText>
                                                        )}
                                                    </Hoverable>
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => {
                                                    this.showPlaybackModal = false;
                                                    this.setPlaybackRate(4.0);
                                                }}>
                                                    <Hoverable>
                                                        {({ hovered }) => (
                                                            <MyAppText style={{
                                                                padding: 2,
                                                                color: hovered ? '#000028' : '#FFFFFF',
                                                                backgroundColor: hovered ? '#FFFFFF' : '#000028'
                                                            }}>4x</MyAppText>
                                                        )}
                                                    </Hoverable>
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => {
                                                    this.showPlaybackModal = false;
                                                    this.setPlaybackRate(8.0);
                                                }}>
                                                    <Hoverable>
                                                        {({ hovered }) => (
                                                            <MyAppText style={{
                                                                padding: 2,
                                                                color: hovered ? '#000028' : '#FFFFFF',
                                                                backgroundColor: hovered ? '#FFFFFF' : '#000028'
                                                            }}>8x</MyAppText>
                                                        )}
                                                    </Hoverable>
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => {
                                                    this.showPlaybackModal = false;
                                                    this.setPlaybackRate(16.0);
                                                }}>
                                                    <Hoverable>
                                                        {({ hovered }) => (
                                                            <MyAppText style={{
                                                                padding: 2,
                                                                color: hovered ? '#000028' : '#FFFFFF',
                                                                backgroundColor: hovered ? '#FFFFFF' : '#000028'
                                                            }}>16x</MyAppText>
                                                        )}
                                                    </Hoverable>
                                                </TouchableOpacity>
                                            </View>
                                            : null}
                                        <TouchableOpacity
                                            onPress={() => {
                                                const video = this.videoRef?.current;
                                                if (!video) {
                                                    return console.error('video not ready');
                                                }
                                                this.showPlaybackModal = !this.showPlaybackModal;
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faForward} color='#fff'></FontAwesomeIcon>
                                        </TouchableOpacity>
                                    </View> : null}
                                <TouchableOpacity
                                    onPress={async () => {
                                        const video = this.videoRef?.current;
                                        if (!video) {
                                            return console.error('video not ready');
                                        }
                                        if (video.requestFullscreen) {
                                            video.requestFullscreen();
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faExpand} color='#fff'></FontAwesomeIcon>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                }
            </View >
        );
    }
}

const styles = StyleSheet.create({
    topView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingTop: 15,
    },
    topText: {
        fontSize: 12,
        color: 'white',
        textShadowColor: 'black',
        textShadowRadius: 2
    },
    logoText: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        color: 'white',
        textShadowColor: 'black',
        textShadowRadius: 2,
    },
});
