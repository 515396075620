import React, { useEffect, useState } from 'react';
import { View, Image, TouchableOpacity, FlatList } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import alertAudioFile from './../../../assets/alert.mp3';
import { Audio } from 'expo-av';
import moment from 'moment';
import { eventsService } from '../../services/central-api/events';
import PlateImage from '../../components/lpr/PlateImage';
import { OccurrenceIsNotPending, dispatchService } from '../../services/central-api/dispatch';
import { EventParamList } from '../../typings/Params';
import { RouteProp, useRoute } from '@react-navigation/native';
import Filters from './Filters';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import useFilters from '../../components/Filter/hooks/useFilters';

const sound = new Audio.Sound();

export default function EventsBoard({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<EventParamList, 'Events'>>();

    const [occurrences, setOccurrences] = useState<OccurrenceEvent[]>([]);
    const [pendingList, setPendingList] = useState<OccurrenceEvent[]>([]);
    const [inServiceList, setInServiceList] = useState<OccurrenceEvent[]>([]);
    const [ongoingList, setOngoingList] = useState<OccurrenceEvent[]>([]);
    const [concludedList, setConcludedList] = useState<OccurrenceEvent[]>([]);
    const [pendingListSize, setPendingListSize] = useState<number>();

    const [filters, setFilters] = useFilters<EventParamList['Events']>({ ...route.params });

    async function getEvents(): Promise<void> {
        try {
            const occurrencesRes = await eventsService.getEvents(filters);
            setOccurrences(occurrencesRes);
        } catch (err) {
            console.error(err);
        }
    }

    async function loadAudio() {
        try {
            await sound.loadAsync(alertAudioFile);
            await Audio.setAudioModeAsync({
                playsInSilentModeIOS: true,
            });
        } catch (error) {
            console.error('Error loading audio', error);
        }
    }

    async function playAlertSound() {
        try {
            await sound.playAsync();
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getEvents();
        const interval = setInterval(() => {
            if (!window.location.href.split('?')[0].endsWith('events/board')) {
                return;
            }
            getEvents();
        }, 5000);

        return () => clearInterval(interval);
    }, [filters]);

    useEffect(() => {
        loadAudio();
        return () => {
            sound.unloadAsync();
        };
    }, []);

    useEffect(() => {
        const pendingList = occurrences.filter((item) => item.situation == 'pending');
        const inServiceList = occurrences.filter((item) => item.situation == 'in_service');
        const ongoingList = occurrences.filter((item) => item.situation == 'ongoing');
        const concludedList = occurrences.filter((item) => item.situation == 'concluded');
        setPendingList(pendingList);
        setInServiceList(inServiceList);
        setOngoingList(ongoingList);
        setConcludedList(concludedList);

    }, [occurrences]);

    useEffect(() => {
        if (pendingListSize === undefined) {
            //First load do not play sound
            return setPendingListSize(pendingList.length);
        }
        if (pendingList.length > pendingListSize) {
            playAlertSound();
        }
        setPendingListSize(pendingList.length);
    }, [pendingList]);


    async function goToEvent(item: OccurrenceEvent) {
        try {
            if (item.situation == 'pending' && item.id) {
                try {
                    await dispatchService.setOccurrenceInService(item.id);
                } catch (err) {
                    if (!(err instanceof OccurrenceIsNotPending)) {
                        throw err;
                    }
                }
            }
            navigation.navigate('Events', { screen: 'EventsOccurrenceDetail', params: { occurrenceId: item.id } });
        } catch (err) {
            console.error(err);
        }
    }

    function getPriorityDescription(priority: OccurrencePriorities) {
        switch (priority) {
            case 'maximum':
                return translate('maximum_priority');
            case 'high':
                return translate('high_priority');
            case 'medium':
                return translate('medium_priority');
            case 'low':
                return translate('low_priority');
            default:
                return translate('medium_priority');
        }
    }

    function renderItem({ item }: { item: OccurrenceEvent; }) {
        return (
            <TouchableOpacity onPress={async () => {
                goToEvent(item);
            }}>
                <View style={styles.card}>
                    <View style={{ minWidth: 130, flex: 1 }}>
                        <MyAppText style={styles.itemLeftTitle}>
                            {item.triggerType ? translate(item.triggerType) : item.occurrenceType.name}
                        </MyAppText>
                        <MyAppText style={styles.itemLeftUserText}>{item.triggerType == 'mp_face_detected' ? item.mpSituation : null}</MyAppText>
                        <MyAppText style={styles.itemLeftUserText}>{item.triggerType == 'mp_plate_detected' ? item.mpNatureOfOccurrence : null}</MyAppText>
                        <MyAppText style={styles.itemLeftUserText}>{item.triggerType == 'alarm_center' && item.alarmCenterType ? translate(item.alarmCenterType) : null}</MyAppText>
                        <MyAppText style={styles.itemLeftDate}>{item?.createdAt ? moment(item?.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''}</MyAppText>
                        <View style={styles.itemLeftUser} >
                            <FontAwesomeIcon icon={faUserCircle} fontSize={16} color={theme.colors.iconColor} />
                            <MyAppText style={styles.itemLeftUserText}>{item.responsibleUser?.name ? item.responsibleUser.warName || item.responsibleUser.name : translate('noResponsible')}</MyAppText>
                        </View>
                    </View>
                    {item.priority ?
                        <View style={styles.itemPriority} >
                            {item.priority === 'maximum' ?
                                <MyAppText style={styles.itemMaximumPriorityText}>{getPriorityDescription(item.priority)}</MyAppText> :
                                <MyAppText style={styles.itemPriorityText}>{getPriorityDescription(item.priority)}</MyAppText>
                            }
                        </View> : null
                    }
                    {item.triggerType == 'face_detected' || item.triggerType == 'mp_face_detected' ?
                        <View style={styles.imageBox}>
                            <img
                                style={styles.facialBlurImage}
                                src={item.imageUrl}
                            />
                        </View>
                        : null
                    }
                    {item.triggerType == 'area_invasion' || item.triggerType == 'mp_plate_detected' ?
                        <View style={styles.imageBoxInvasion}>
                            <Image
                                style={styles.imageInvasion}
                                source={{ uri: item.imageUrl }}
                            />
                            {item.occurrenceImageCount > 1 ?
                                <MyAppText style={{
                                    position: 'absolute',
                                    top: -10,
                                    right: -10,
                                    backgroundColor: '#D50000',
                                    borderRadius: 10,
                                    minWidth: 20,
                                    height: 20,
                                    paddingHorizontal: 3,
                                    textAlign: 'center',
                                }}>{item.occurrenceImageCount}</MyAppText>
                                : null}
                        </View>
                        : null
                    }
                    {item.triggerType == 'plate_detected' && item.imageUrl && item.platePosition ?
                        <PlateImage width={128} height={64} src={item.imageUrl} platePosition={item.platePosition} />
                        : null
                    }
                </View>

            </TouchableOpacity >
        );
    }

    function getListView(itemsList: OccurrenceEvent[], text: string) {
        return <View style={styles.list}>
            <View style={styles.listHeader}>
                <MyAppText style={styles.listHeaderText}>{translate(text)}</MyAppText>
                <MyAppText style={styles.listHeaderTotal}>{itemsList.length}</MyAppText>
            </View>
            <View style={styles.listItemsContainer}>
                <FlatList
                    data={itemsList}
                    keyExtractor={(item) => item?.id?.toString() || '0'}
                    renderItem={renderItem}
                    contentContainerStyle={{ gap: 10 }}
                />
            </View>
        </View>;
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='events' lateralMenu='events' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.headerContent}>
                    <Filters
                        authenticatedUser={authenticatedUser}
                        filters={filters}
                        setFilters={setFilters} />
                </View>
                <View style={styles.container}>
                    {getListView(pendingList, 'pending')}
                    {getListView(inServiceList, 'in_service')}
                    {getListView(ongoingList, 'ongoing')}
                    {getListView(concludedList, 'concluded')}
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15,
        flexDirection: 'row',
        gap: 30
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 40,
        zIndex: 2,
        justifyContent: 'flex-end'
    },
    list: {
        flex: 1,
    },
    listHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
    },
    listHeaderText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: theme.colors.labelColor,
    },
    listHeaderTotal: {
        fontSize: 20,
        color: theme.colors.labelColor,
    },
    listItemsContainer: {
        flex: 1
    },
    card: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        padding: 8,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.colors.backgroundColor,
        minHeight: 85,
        flexWrap: 'wrap',
        gap: 5
    },
    itemLeftTitle: {
        fontSize: 15,
        color: theme.colors.textColor
    },
    itemLeftDate: {
        fontSize: 12,
        color: theme.colors.disabledText
    },
    itemLeftUser: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    itemLeftUserText: {
        fontSize: 12,
        color: theme.colors.disabledText,
    },
    itemPriority: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 64,
        height: 64,
    },
    itemPriorityText: {
        fontSize: 12
    },
    itemMaximumPriorityText: {
        fontSize: 12,
        color: '#D50000'
    },
    image: {
        width: 64,
        height: 64,
        borderRadius: 4,
        objectFit: 'contain',
    },
    facialBlurImage: {
        width: 64,
        height: 64,
        borderRadius: 4,
        objectFit: 'contain',
        filter: 'blur(5px)'
    },
    imageBox: {
        height: 64,
        width: 64,
        minWidth: 64,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageInvasion: {
        width: 128,
        minWidth: 128,
        height: 64,
        borderRadius: 4,
        objectFit: 'contain',
    },
    imageBoxInvasion: {
        height: 64,
        width: 128,
        minWidth: 128,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
