import React, { useState } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { translate } from '../../../services/translate';
import StatusDropdown from './StatusDropdown';
import UserDropdown from './UserDropdown';
import CustomButton from '../../../components/CustomButton';
import { GetPaginatedVideoAnalysisFilters } from '../../../services/central-api/cameras';

export type VideoAnalysisFilters = {
    textFilter?: string;
    status?: string;
    startDate?: string;
    startHour?: string;
    endDate?: string;
    endHour?: string;
    requesterUserId?: number;
};

interface Props {
    onFilter: (filterValues: Omit<GetPaginatedVideoAnalysisFilters, 'limit' | 'page'>) => void;
    filterValues: VideoAnalysisFilters;
}

function Filters({ onFilter, filterValues }: Props) {
    const { styles } = useStyles(styleSheet);

    const [requesterUserId, setRequesterUserId] = useState<number | undefined>(filterValues.requesterUserId);
    const [status, setStatus] = useState<VideoAnalysisStatus>(filterValues.status as VideoAnalysisStatus);

    const handleFilters = () => {
        const formattedFilters: Omit<GetPaginatedVideoAnalysisFilters, 'limit' | 'page'> = {
            requesterUserId,
            status,
        };

        onFilter(formattedFilters);
    };

    return (
        <View style={styles.header}>
            <View style={{ zIndex: 4 }}>
                <StatusDropdown
                    value={status}
                    onChange={status => {
                        setStatus(status as VideoAnalysisStatus);
                    }}
                />
            </View>

            <View style={{ zIndex: 3 }}>
                <UserDropdown
                    value={requesterUserId}
                    onChange={user => {
                        setRequesterUserId(+user);
                    }}
                />
            </View>

            <View style={styles.buttonsContainer}>
                <CustomButton
                    icon={faSearch}
                    onPress={() => {
                        handleFilters();
                    }}
                    text={translate('toSearch')}
                />
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet(theme => ({
    header: {
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        flexWrap: 'wrap',
        columnGap: 30,
        rowGap: 10,
        padding: 10
    },

    filterInput: {
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.backgroundColor,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: theme.colors.borderColor,
        height: 40,
        minHeight: 40,
        fontSize: 16,
        color: theme.colors.fieldColor,
        padding: 10
    },

    buttonsContainer: {
        marginTop: 20,
        marginLeft: 'auto',
    },
}));

export default Filters;
