import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import MyAppText from '../../../components/MyAppText';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { dispatchService } from '../../../services/central-api/dispatch';
import { WindowInformation } from '../../../services/window-information';
import { Tabs } from '../../../components/Tabs';
import MyDataTable from '../../../components/MyDataTable';
import moment from 'moment';
import { ScrollView } from 'react-native-web-hover';
import { centralAPI } from '../../../services/central-api';
import { Log } from '../../../services/central-api/audit-logs';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import DateTimePicker from '../../../components/DateTimePicker';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    unitId?: number;
}

export default function UnitsModalReadOnly({ onClose, unitId, isModalVisible, setModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [unit, setUnit] = useState<Unit>();
    const [logs, setLogs] = useState<Log[]>([]);
    const [ownUser, setOwnUser] = useState<UserData>();
    const [loadingHistoric, setLoadingHistoric] = useState<boolean>();

    async function getOwnUser() {
        try {
            const ownUser = await centralAPI.getOwnUser({ includeRole: true });
            setOwnUser(ownUser);
            return ownUser;
        } catch (err) {
            console.error(err);
        }
    }

    function cleanValues() {
        setUnit(undefined);
        setLogs([]);
    }

    async function loadPageInfo() {
        try {
            if (unitId) {
                setLoadingHistoric(true);
                await getOwnUser();
                const unit = await dispatchService.getUnit(unitId);
                setUnit(unit);

                // This endpoint takes way more time to load, should be the last
                const logs = await dispatchService.getUnitHistoric(unitId);
                setLogs(logs);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingHistoric(false);
        }
    }

    useEffect(() => {
        if (!unitId) {
            return;
        }
        loadPageInfo();
    }, [unitId]);

    const renderHistoricContent = (log: Log) => {
        if (!log.detail) {
            return <></>;
        }
        return <View style={{ paddingVertical: 10, paddingHorizontal: 30 }}>
            {Object.keys(log.detail).map((key, index) => {
                if (log.detail) {
                    if (key == 'occurrenceId') {
                        const occurrenceId = log.detail[key];
                        return <TouchableOpacity key={index} onPress={() => window.open(`/dispatch/occurrences/detail/${occurrenceId}`)}>
                            <MyAppText style={[styles.cardContent, { textDecorationLine: 'underline' }]}>
                                {translate(key)}: {occurrenceId}
                            </MyAppText>
                        </TouchableOpacity>;
                    } else if (['definitiveStart', 'definitiveFinish'].includes(key)) {
                        return <MyAppText key={index} style={styles.cardContent}>{translate(key)}: {moment(log.detail[key] as string).format('DD/MM/YYYY HH:mm')}</MyAppText>;
                    } else {
                        return <MyAppText key={index} style={styles.cardContent}>{translate(key)}: {log.detail[key]}</MyAppText>;
                    }
                }
            })}
        </View>;
    };

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
            <View style={styles.container}>
                <View style={[styles.formContainer, { maxHeight: 690 }, windowInfo.isMobile ? { width: '95%' } : { width: '50%' }]}>
                    <ScrollView>
                        <Tabs tabs={[{
                            key: 'details',
                            label: translate('details')
                        }, {
                            key: 'associated',
                            label: translate('associated')
                        }, {
                            key: 'historic',
                            label: translate('historic')
                        }]}>
                            {({ selectedTab }) => {
                                if (selectedTab === 'details') {
                                    return (
                                        <View style={{ rowGap: 10, flex: 1 }}>
                                            <View style={{ minWidth: 250 }}>
                                                <MyDropDownPicker
                                                    open={false}
                                                    setOpen={() => false}
                                                    value={unit?.unitNameId || 0}
                                                    setValue={() => false}
                                                    items={[unit?.unitName].map((item) => {
                                                        return {
                                                            label: item?.name,
                                                            value: item?.id
                                                        };
                                                    })}
                                                    disabled={true}
                                                />
                                            </View>
                                            <View style={{ minWidth: 250, flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
                                                <DateTimePicker
                                                    date={unit ? new Date(unit.estimatedStart) : null}
                                                    onChangeDate={() => false}
                                                    time={unit ? moment(unit?.estimatedStart).format('HH:mm') : ''}
                                                    onChangeTime={() => false}
                                                    label={translate('estimatedStart')}
                                                    disabled={true}
                                                />
                                                <DateTimePicker
                                                    date={unit ? new Date(unit.estimatedFinish) : null}
                                                    onChangeDate={() => false}
                                                    time={unit ? moment(unit?.estimatedFinish).format('HH:mm') : ''}
                                                    onChangeTime={() => false}
                                                    label={translate('estimatedFinish')}
                                                    disabled={true}
                                                />
                                            </View>
                                            <View style={{ minWidth: 250, flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
                                                <DateTimePicker
                                                    date={unit && unit.definitiveStart ? new Date(unit.definitiveStart) : null}
                                                    onChangeDate={() => false}
                                                    time={unit && unit.definitiveStart ? moment(unit?.definitiveStart).format('HH:mm') : ''}
                                                    onChangeTime={() => false}
                                                    label={translate('activated')}
                                                    disabled={true}
                                                />
                                                <DateTimePicker
                                                    date={unit && unit.definitiveFinish ? new Date(unit.definitiveFinish) : null}
                                                    onChangeDate={() => false}
                                                    time={unit && unit.definitiveFinish ? moment(unit?.definitiveFinish).format('HH:mm') : ''}
                                                    onChangeTime={() => false}
                                                    label={translate('finished')}
                                                    disabled={true}
                                                />
                                            </View>
                                            {ownUser?.isAdmin ?
                                                <View style={{ flexDirection: 'row', gap: 16, zIndex: 2 }}>
                                                    <View style={{ flex: 1, gap: 5 }}>
                                                        <MyAppText style={styles.label}>{translate('actingBody')}</MyAppText>
                                                        <MyDropDownPicker
                                                            open={false}
                                                            setOpen={() => false}
                                                            value={unit?.actingBodyUnit?.actingBodyId || 0}
                                                            setValue={() => false}
                                                            items={[{
                                                                label: unit?.actingBodyUnit?.actingBody?.name,
                                                                value: unit?.actingBodyUnit?.actingBody?.id,
                                                            }]}
                                                            disabled={true}
                                                        />
                                                    </View>
                                                    <View style={{ flex: 1, gap: 5 }}>
                                                        <MyAppText>{translate('unit')}</MyAppText>
                                                        <MyDropDownPicker
                                                            items={[{
                                                                label: unit?.actingBodyUnit?.name,
                                                                value: unit?.actingBodyUnit?.id
                                                            }]}
                                                            value={unit?.actingBodyUnitId || 0}
                                                            setValue={() => false}
                                                            setOpen={() => false}
                                                            open={false}
                                                            disabled={true}
                                                        />
                                                    </View>
                                                </View>
                                                : null
                                            }
                                            <View style={styles.row}>
                                                <View style={{ flex: 1 }}>
                                                    <FormInput
                                                        label={translate('description')}
                                                        placeholder={translate('description')}
                                                        value={unit?.description || ''}
                                                        onChangeText={() => false}
                                                        multiline={true}
                                                        numberOfLines={5}
                                                        invalid={() => false}
                                                        disabled={true}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    );
                                } else if (selectedTab === 'associated') {
                                    return (
                                        <View style={{ rowGap: 10 }}>
                                            {unit?.UnitUsers?.length ?
                                                <View>
                                                    <MyAppText>{translate('agents')}</MyAppText>
                                                    <MyDataTable
                                                        columns={[
                                                            {
                                                                cell: row => {
                                                                    return <View style={{ width: '100%' }}>
                                                                        <MyAppText >{row?.name || ''}{row?.warName ? ' - ' + row?.warName : ''}: {row?.registry || ''}</MyAppText>
                                                                    </View>;
                                                                },
                                                                grow: 2,
                                                                wrap: true
                                                            },
                                                        ]}
                                                        noTableHead={true}
                                                        data={unit?.UnitUsers || []}
                                                        dense={true}
                                                    />
                                                </View> : null
                                            }
                                            {unit?.equipments?.length ?
                                                <View>
                                                    <MyAppText>{translate('equipments')}</MyAppText>
                                                    <MyDataTable
                                                        columns={[
                                                            {
                                                                name: translate('name'),
                                                                cell: row => {
                                                                    return <View>
                                                                        <MyAppText >{row?.name}</MyAppText>
                                                                    </View>;
                                                                },
                                                                grow: 2,
                                                                wrap: true
                                                            },
                                                        ]}
                                                        data={unit.equipments}
                                                        noTableHead={true}
                                                        dense={true}
                                                    />
                                                </View> : null
                                            }
                                        </View>
                                    );
                                } else if (selectedTab === 'historic') {
                                    return (
                                        <View>
                                            <MyDataTable
                                                columns={[{
                                                    name: translate('date'),
                                                    selector: row => moment(row.date).format('DD/MM/YYYY HH:mm'),
                                                    maxWidth: '170px',
                                                    wrap: true
                                                },
                                                {
                                                    name: translate('user'),
                                                    selector: row => row.userName || '',
                                                    wrap: true
                                                },
                                                {
                                                    name: translate('ipAddress'),
                                                    selector: row => row.ip || '',
                                                    wrap: true
                                                },
                                                {
                                                    name: translate('action'),
                                                    selector: row => translate(row.action),
                                                    grow: 1,
                                                    wrap: true
                                                }]}
                                                data={logs}
                                                expandableRowsComponent={({ data }) => renderHistoricContent(data)}
                                                expandableRows
                                                progressPending={loadingHistoric}
                                            />
                                        </View>
                                    );
                                }
                            }}
                        </Tabs>
                    </ScrollView>
                    <FormActions
                        onSubmit={() => false}
                        onClose={() => {
                            onClose();
                            cleanValues();
                        }}
                        disabled={true} />
                </View >
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        flex: 1,
        height: 690
    },
    label: {
        color: theme.colors.labelColor
    },
    row: {
        flexDirection: 'row',
        columnGap: 30,
        flexWrap: 'wrap'
    },
    cardContent: {
        fontSize: 14,
        color: theme.colors.labelColor,
    },
}));
