import React, { useEffect, useState } from 'react';
import { translate } from '../../services/translate';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { accessService } from '../../services/central-api/access';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    actingBodyId?: number;
    zIndex: number;
}

function RolesDropdown({ value, setValue, zIndex, actingBodyId }: Props) {
    const [selectableRoles, setSelectableRoles] = useState<{ label: string, value: number; }[]>([]);

    async function loadRolesAndUnits(actingBodyId: number) {
        try {
            const actingBody = await accessService.getActingBody(actingBodyId);
            if (!actingBody.roles) {
                setSelectableRoles([]);
            } else {
                setSelectableRoles(actingBody.roles.map((role) => {
                    return {
                        value: role.id || 0,
                        label: role.name
                    };
                }));
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId && actingBodyId > 0) {
            loadRolesAndUnits(actingBodyId);
        }
    }, [actingBodyId]);

    return (
        <FilterDropdown
            label={translate('role')}
            items={selectableRoles}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default RolesDropdown;
