import React from 'react';
import { TeamNameCategory } from '../../../services/central-api/dispatch';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';

interface Props {
    value: TeamNameCategory | '';
    setValue: React.Dispatch<React.SetStateAction<TeamNameCategory | ''>>;
    zIndex: number;
    disabled?: boolean;
}

function CategoriesDropdown({ value, setValue, zIndex, disabled }: Props) {
    return (
        <FilterDropdown
            label={translate('category')}
            items={Object.values(TeamNameCategory).map((item: string) => {
                return {
                    label: item ? translate(item) : '', value: item
                };
            })}
            multiple={false}
            setValue={(selectedValue) => {
                const selectedCategory = selectedValue(value);
                setValue(selectedCategory == value ? '' : selectedCategory);
            }}
            value={value}
            zIndex={zIndex}
            disabled={disabled}
        />
    );
}

export default CategoriesDropdown;
