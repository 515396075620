import React from 'react';
import LateralMenu from '../LateralMenu';
import { faClipboardList, faIdBadge, faSearch, faShield } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_app_agent.png';
import collapsedLogo from '../../../assets/app_agent_icon.png';

export type SelectedMenu = 'personOfInterest' | 'occurrences' | 'units' | 'cortex_finder';

interface GcmAgentLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}

export default function GcmAgentLateralMenu({ navigation, selectedMenu, open, authenticatedUser, setOpen }: GcmAgentLateralMenuProps) {
    const items = [{
        icon: faClipboardList,
        text: translate('occurrences'),
        action: () => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' }),
        selected: selectedMenu == 'occurrences'
    }, {
        icon: faIdBadge,
        text: translate('personOfInterest'),
        action: () => navigation.navigate('GcmAgent', { screen: 'PersonOfInterest' }),
        selected: selectedMenu == 'personOfInterest'
    }, {
        icon: faShield,
        text: translate('teams'),
        action: () => navigation.navigate('GcmAgent', { screen: 'AgentUnits' }),
        selected: selectedMenu == 'units'
    }];

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.search_people_cortex || authenticatedUser?.permissions.search_cortex_any_time) {
        items.push({
            icon: faSearch,
            text: translate('searchOnCortex'),
            action: () => navigation.navigate('GcmAgent', { screen: 'CortexFinder' }),
            selected: selectedMenu == 'cortex_finder'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={items}
            goBack={() => navigation.navigate('Menu')} />
    );
}
