import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, View, TouchableOpacity, StyleSheet, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faLocationDot, faSearchPlus, faTimes, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import ThemeContext from '../../../context/Theme';
import getThemedColor from '../../../services/get-themed-color';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { WindowInformation } from '../../../services/window-information';
import { Hoverable } from 'react-native-web-hover';

export default function OccurrenceFormIrregularVehicle({
    event, imageUrl, navigation
}: {
    event: LprDetection;
    imageUrl: string;
    navigation: Navigation;
}) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const windowInfo = WindowInformation();

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        if (!event) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 4;

            const rect = {
                minX: Infinity,
                maxX: 0,
                minY: Infinity,
                maxY: 0
            };

            if (event.platePosition) {
                for (const position of event.platePosition) {
                    if (position.x < rect.minX) {
                        rect.minX = position.x;
                    }

                    if (position.x > rect.maxX) {
                        rect.maxX = position.x;
                    }

                    if (position.y < rect.minY) {
                        rect.minY = position.y;
                    }

                    if (position.y > rect.maxY) {
                        rect.maxY = position.y;
                    }
                }

                ctx.beginPath();
                ctx.moveTo(rect.minX, rect.minY);
                ctx.lineTo(rect.minX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.minY);
                ctx.closePath();
                ctx.stroke();
            }

        };

        image.src = imageUrl;
    });

    return (
        <ScrollView>
            <View style={{ gap: 20 }}>
                <View>
                    <MyAppText style={styles.plateText}>
                        {translate('plate')}
                    </MyAppText>
                    <MyAppText style={styles.plateInfoText}>
                        {event.plate}
                    </MyAppText>
                    <Hoverable>
                        {({ hovered }) => (<>
                            <canvas style={{ width: '100%' }} ref={canvasRef} />

                            {hovered &&
                                <View style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: '#00000080',
                                }}>
                                    <TouchableOpacity
                                        style={{
                                            flex: 1,
                                            justifyContent: 'center'
                                        }}
                                        onPress={async () => {
                                            setIsModalVisible(true);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                                    </TouchableOpacity>
                                </View>
                            }
                        </>)}
                    </Hoverable>
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('vehicleData')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('brand')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.brand']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('model')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.model']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('color')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.color']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('vehicleType')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.type']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('origin')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.city']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('year')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.modelYear']}
                        </MyAppText>
                    </View>
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('visualizedOn')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faVideo} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('CameraPlayer', { id: event.camera.id });
                        }}>
                            <MyAppText style={styles.detectionInfoText}>
                                {event.camera.title}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(new Date(event.time)).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {event.camera.address}
                        </MyAppText>
                    </View>
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('situation')}
                    </MyAppText>
                    <MyAppText style={styles.detectionInfoText}>
                        {event.violation == 'on_phone' ? translate('onPhone') : null}
                        {event.violation == 'without_belt' ? translate('withoutBelt') : null}
                    </MyAppText>
                </View>
            </View>

            {/* Zoom image modal */}
            <Modal
                animationType='fade'
                transparent={true}
                visible={isModalVisible}
                onRequestClose={() => setIsModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                            <View style={{ alignItems: 'flex-end' }}>
                                <TouchableOpacity
                                    style={{ width: 20, height: 20 }}
                                    onPress={() => setIsModalVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={getThemedColor(theme, '#58595B')} />
                                </TouchableOpacity>
                            </View>

                            <TransformWrapper>
                                <TransformComponent>
                                    <canvas style={{ width: '100%' }} ref={canvasRef} />
                                </TransformComponent>
                            </TransformWrapper>
                        </View>
                    </View>
                </View>
            </Modal>
        </ScrollView>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        detectionInfoText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        plateText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 14
        },
        plateInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 24
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199',
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            padding: 30,
        },
    });
}
