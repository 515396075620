import React, { useEffect, useState } from 'react';
import { cameraService } from '../../services/central-api/cameras';
import { translate } from '../../services/translate';
import { View } from 'react-native';
import MyAppText from '../MyAppText';
import MyDropDownPicker from '../MyDropDownPicker';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
    hideLabel?: boolean;
}

function AlertGroupsDropdown({ value, setValue, zIndex, hideLabel }: Props) {
    const [selectableAlertGroups, setSelectableAlertGroups] = useState<{ label: string, value: number; }[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const { styles, theme } = useStyles(stylesheet);

    async function getAlertGroups() {
        try {
            const alertGroups = await cameraService.getAlertGroups({ page: 0, limit: 9999999 });
            setSelectableAlertGroups([
                { value: 0, label: translate('maleNone') },
                ...alertGroups.rows.map((alertGroup) => {
                    return {
                        value: alertGroup.id,
                        label: alertGroup.name
                    };
                })
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getAlertGroups();
    }, []);

    return (
        <View style={{ minWidth: 200, zIndex: zIndex }}>
            {!hideLabel ? <MyAppText style={styles.filterText}>{translate('alertGroup')}</MyAppText> : null}
            <MyDropDownPicker
                items={selectableAlertGroups}
                multiple={false}
                setValue={setValue}
                value={value}
                borderColor={theme.colors.fieldBorderColor}
                searchable={true}
                height={40}
                open={isOpen}
                setOpen={setIsOpen}
                dropDownDirection={'BOTTOM'}
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    inputContainer: {
        flexGrow: 1,
        minWidth: 200,
    },

    filterText: {
        color: theme.colors.labelColor,
        fontSize: 14,
        height: 25,
    },
}));

export default AlertGroupsDropdown;
