import React, { useContext, useState } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import ThemeContext from '../../../context/Theme';
import getThemedColor from '../../../services/get-themed-color';
import MyDataTable from '../../MyDataTable';
import DeleteModal from '../../DeleteModal';
import InvolvedVehicleModal from './InvolvedVehicleModal';
import CustomButton from '../../CustomButton';

interface InvolvedVehiclesParams {
    involvedVehicles: InvolvedVehicle[];
    setInvolvedVehicles: (value: InvolvedVehicle[]) => void;
    actingBodyId: number;
}

export default function InvolvedVehicles({ involvedVehicles, setInvolvedVehicles, actingBodyId }: InvolvedVehiclesParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [deleteVehicleModalVisible, setDeleteVehicleModalVisible] = useState(false);
    const [vehicleModalOpen, setVehicleModalOpen] = useState<boolean>(false);
    const [selectedInvolvedVehicle, setSelectedInvolvedVehicle] = useState<InvolvedVehicle>(getBlankVehicle());

    function getBlankVehicle(): InvolvedVehicle {
        return {
            id: undefined,
            brand: '',
            chassis: '',
            color: '',
            model: '',
            plate: '',
            vehicleRelationId: 0,
            stolen: false,
            restriction: ''
        };
    }

    async function createUpdateInvolvedVehicles() {
        if (!selectedInvolvedVehicle.id) {
            selectedInvolvedVehicle.id = -Math.random();
            involvedVehicles.push(selectedInvolvedVehicle);
        } else {
            const index = involvedVehicles.findIndex(vehicle => vehicle.id === selectedInvolvedVehicle.id);
            involvedVehicles[index] = { ...involvedVehicles[index], ...selectedInvolvedVehicle };
        }

        setInvolvedVehicles(involvedVehicles);
        setSelectedInvolvedVehicle(getBlankVehicle());
    }

    return (
        <>
            <View style={{ flex: 1, rowGap: 20 }}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <MyAppText style={styles.headerText}>{translate('involvedVehicles')}</MyAppText>
                    <CustomButton
                        onPress={() => setVehicleModalOpen(true)}
                        text={translate('attach')}
                        icon={faPlus}
                    />
                </View>
                <MyDataTable
                    columns={[
                        {
                            name: translate('plate'),
                            selector: row => row.plate,
                            wrap: true,
                        },
                        {
                            name: translate('brand'),
                            selector: row => row.brand,
                            wrap: true,
                        },
                        {
                            name: translate('model'),
                            selector: row => row.model,
                            wrap: true,
                        },
                        {
                            name: translate('color'),
                            selector: row => row.color,
                            wrap: true,
                        },
                        {
                            name: translate('chassis'),
                            selector: row => row.chassis,
                            wrap: true,
                        },
                        {
                            name: translate('relation'),
                            selector: row => row.vehicleRelation?.name || '',
                            wrap: true,
                        },
                        {
                            name: translate('action'),
                            button: true,
                            cell: row =>
                                <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                    <TouchableOpacity onPress={() => {
                                        setSelectedInvolvedVehicle(row);
                                        setVehicleModalOpen(true);
                                    }}>
                                        <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {
                                        setSelectedInvolvedVehicle(row);
                                        setDeleteVehicleModalVisible(true);
                                    }}>
                                        <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                    </TouchableOpacity>
                                </View>
                        }
                    ]}
                    data={involvedVehicles}
                />
            </View>
            <DeleteModal
                setModalVisible={setDeleteVehicleModalVisible}
                isModalVisible={deleteVehicleModalVisible}
                itemName={translate('normalVehicle')}
                onSubmit={() => {
                    if (!selectedInvolvedVehicle) return;

                    const index = involvedVehicles.findIndex(vehicle => vehicle.id === selectedInvolvedVehicle.id);
                    if (index >= 0) {
                        involvedVehicles.splice(index, 1);
                        setInvolvedVehicles(involvedVehicles);
                        setSelectedInvolvedVehicle(getBlankVehicle());
                    }
                }}
                onCancel={() => {
                    setSelectedInvolvedVehicle(getBlankVehicle());
                }}
            />
            <InvolvedVehicleModal
                involvedVehicle={selectedInvolvedVehicle}
                setInvolvedVehicle={setSelectedInvolvedVehicle}
                isModalVisible={vehicleModalOpen}
                actingBodyId={actingBodyId}
                setModalVisible={(value) => {
                    if (!value) {
                        setSelectedInvolvedVehicle(getBlankVehicle());
                    }
                    setVehicleModalOpen(value);
                }}
                onSubmit={createUpdateInvolvedVehicles}
            />
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        headerText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            fontWeight: 'bold',
        }
    });
}
