import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { CamerasParamList } from '../../../typings/Params';
import CameraTypeDropdown from './CameraTypeDropdown';
import IntegrationTypeDropdown from './IntegrationTypeDropdown';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';

interface Props {
    filters: CamerasParamList['CamerasList'];
    setFilters: React.Dispatch<React.SetStateAction<CamerasParamList['CamerasList']>>;
    textFilter: string;
    setTextFilter: (value: string) => void;
}

function Filter({ filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<CamerasParamList, 'CamerasList'>>>();

    const [offlineFilter, setOfflineFilter] = useState(filters.offline ?? false);
    const [selectedCameraTypeFilter, setSelectedCameraTypeFilter] = useState<CameraType | 'all'>(filters.type ?? 'all');
    const [selectedIntegrationTypeFilter, setSelectedIntegrationTypeFilter] = useState<IntegrationType | 'all'>(filters.integrationType ?? 'all');

    const handleCleanFilter = () => {
        setTextFilter('');
        setOfflineFilter(false);
        setSelectedCameraTypeFilter('all');
        setSelectedIntegrationTypeFilter('all');
    };

    const handleFilter = () => {
        setFilters({
            ...filters,
            page: 1,
            textFilter,
            offline: offlineFilter ? offlineFilter : undefined,
            type: selectedCameraTypeFilter !== 'all' ? selectedCameraTypeFilter : undefined,
            integrationType: selectedIntegrationTypeFilter !== 'all' ? selectedIntegrationTypeFilter : undefined,
        });

        navigation.setParams({
            page: 1,
            textFilter,
            offline: offlineFilter ? offlineFilter : undefined,
            type: selectedCameraTypeFilter !== 'all' ? selectedCameraTypeFilter : undefined,
            integrationType: selectedIntegrationTypeFilter !== 'all' ? selectedIntegrationTypeFilter : undefined,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />
            <FilterCheckBox
                label={translate('offline')}
                value={offlineFilter}
                onChange={setOfflineFilter}
            />
            <CameraTypeDropdown value={selectedCameraTypeFilter} setValue={setSelectedCameraTypeFilter} zIndex={3} />
            <IntegrationTypeDropdown value={selectedIntegrationTypeFilter} setValue={setSelectedIntegrationTypeFilter} zIndex={2} />
        </Filters>
    );
}


export default Filter;
