import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import InstallationCompanyModal from './InstallationCompanyModal';
import { accessService } from '../../services/central-api/access';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { AccessParamList } from '../../typings/Params';
import MyDataTable from '../../components/MyDataTable';
import CustomButton from '../../components/CustomButton';

export default function InstallationCompany({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<AccessParamList, 'InstallationCompanies'>>();

    const [installationCompanies, setInstallationCompanies] = useState<InstallationCompanyList[]>([]);
    const [selectedInstallationCompanies, setSelectedInstallationCompanies] = useState<InstallationCompany>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState(0);
    const [filters, setFilters] = useState({
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    async function getInstallationCompanies() {
        try {
            setIsLoading(true);
            const response = await accessService.getInstallationCompanies(filters);
            setInstallationCompanies(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function confirmDelete(installationCompany: InstallationCompany) {
        try {
            if (installationCompany?.id) {
                await accessService.deleteInstallationCompany(installationCompany.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setDeleteModalVisible(false);
                getInstallationCompanies();

            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        getInstallationCompanies();
    }, []);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='installationCompanies' lateralMenu='access' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={styles.buttonsContainer}>
                            <CustomButton
                                onPress={() => {
                                    setSelectedInstallationCompanies(undefined);
                                    setModalVisible(true);
                                }}
                                text={translate('create')}
                                icon={faPlus}
                            />
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyDataTable
                                columns={[
                                    {
                                        name: translate('name'),
                                        selector: row => row.name,
                                        grow: 2,
                                        wrap: true
                                    },
                                    {
                                        name: translate('cameraCount'),
                                        selector: row => row.cameraCount,
                                        grow: 2,
                                    },
                                    {
                                        name: translate('action'),
                                        button: true,
                                        cell: row =>
                                            <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                                <TouchableOpacity onPress={() => {
                                                    setSelectedInstallationCompanies(row);
                                                    setModalVisible(true);
                                                }}>
                                                    <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => {
                                                    setSelectedInstallationCompanies(row);
                                                    setDeleteModalVisible(true);
                                                }}>
                                                    <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                </TouchableOpacity>
                                            </View>
                                    }
                                ]}
                                data={installationCompanies}
                                progressPending={isLoading}
                            />
                        </View>
                        <Pagination
                            currentPage={filters.page}
                            totalItems={totalItems}
                            pageSize={filters.limit}
                            setPageSize={pageSize => {
                                setFilters(old => ({
                                    ...old,
                                    limit: pageSize,
                                    page: 0,
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    limit: pageSize,
                                    page: 0,
                                });
                            }}
                            setPage={page => {
                                setFilters(old => ({
                                    ...old,
                                    page
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    page: page + 1
                                });
                            }}
                        />
                    </View>
                    {/* Delete Modal */}
                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={String(selectedInstallationCompanies?.name) || ''}
                        onSubmit={async () => {
                            if (selectedInstallationCompanies) {
                                await confirmDelete(selectedInstallationCompanies);
                            }
                        }}
                    />

                    {/* Create/Edit Modal */}
                    <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} >
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <InstallationCompanyModal
                                    onClose={() => {
                                        setSelectedInstallationCompanies(undefined);
                                        setModalVisible(false);
                                        getInstallationCompanies();
                                    }}
                                    installationCompanyId={selectedInstallationCompanies?.id}
                                />
                            </View>
                        </View>
                    </Modal>

                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        buttonsContainer: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 10,
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        cardList: {
            borderWidth: 1,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            // borderColor: theme.colors.dataTable.border,
            // backgroundColor: theme.colors.dataTable.background,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1,
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
    });
}
