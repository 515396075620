import React, { useEffect, useState } from 'react';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { translate } from '../../../services/translate';
import { View } from 'react-native';
import MyAppText from '../../../components/MyAppText';

interface Props {
    value?: string;
    onChange: (value: string) => void;
}

function CameraOfflineReasonDropdown({ value, onChange }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedReason, setSelectedReason] = useState<string>(value ?? '');

    useEffect(() => {
        onChange(selectedReason);
    }, [selectedReason]);

    useEffect(() => {
        if (value) {
            setSelectedReason(value);
        }
    }, [value]);

    const DROPDOWN_VALUES: { label: string, value: CameraHistoryReason; }[] = [{
        label: translate('internet_connection'),
        value: 'internet_connection'
    },
    {
        label: translate('power_supply'),
        value: 'power_supply'
    }, {
        label: translate('vandalism'),
        value: 'vandalism'
    }];

    return (
        <View style={{ gap: 5, minWidth: 200, flexGrow: 1, zIndex: 2 }}>
            <MyAppText>{translate('reason')}</MyAppText>
            <MyDropDownPicker
                items={DROPDOWN_VALUES}
                value={selectedReason}
                setValue={setSelectedReason}
                setOpen={setIsOpen}
                open={isOpen}
                multiple={false}
                mode='BADGE'
                borderColor='#888'
            />
        </View>
    );
}

export default CameraOfflineReasonDropdown;
