import React from 'react';
import Toast from 'react-native-toast-message';
import toastConfig from '../services/toast-config';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function ToastComponent() {
    const insets = useSafeAreaInsets();

    return (<View style={{
        marginTop: insets.top,
        marginRight: insets.right,
        marginLeft: insets.left,
        marginBottom: insets.bottom
    }}>
        <Toast config={toastConfig} />
    </View>);
}
