import React, { useEffect, useState } from 'react';
import { translate } from '../../services/translate';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { accessService } from '../../services/central-api/access';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    actingBodyId: number;
    zIndex: number;
}

function ActingBodiesUnitsDropdown({ value, setValue, zIndex, actingBodyId }: Props) {
    const [selectableActingBodyUnits, setSelectableActingBodyUnits] = useState<{ label: string, value: number; }[]>([]);

    async function getActingBodyUnits(actingBodyId: number) {
        try {
            const actingBody = await accessService.getActingBody(actingBodyId);

            if (!actingBody.actingBodyUnits) {
                setSelectableActingBodyUnits([]);
            } else {
                setSelectableActingBodyUnits([
                    ...actingBody.actingBodyUnits.map((role) => {
                        return {
                            value: role.id || 0,
                            label: role.name
                        };
                    })
                ]);
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId && actingBodyId > 0) {
            getActingBodyUnits(actingBodyId);
        }
    }, [actingBodyId]);

    return (
        <FilterDropdown
            placeholder={translate('selectActingBodyUnit')}
            items={selectableActingBodyUnits}
            multiple={true}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            mode='BADGE'
            style={{ minWidth: 240, maxWidth: 240 }}
        />
    );
}

export default ActingBodiesUnitsDropdown;
