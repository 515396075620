import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import ActingBodiesUnitsDropdown from './ActingBodiesUnitsDropdown';
import moment from 'moment';
import ActingBodiesCommandsDropdown from '../../../components/Dropdowns/ActingBodiesCommandsDropdown';
import FilterDatePicker from '../../../components/Filter/components/FilterDateInput';

interface Props {
    filters: DispatchParamList['Units'];
    setFilters: React.Dispatch<React.SetStateAction<DispatchParamList['Units']>>;
    authenticatedUser?: AuthenticatedUser;
    teamFilter: string;
    setTeamFilter: (value: string) => void;
}

function UnitsFilter({ authenticatedUser, filters, setFilters, teamFilter, setTeamFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<DispatchParamList, 'Units'>>>();

    const [agentFilter, setAgentFilter] = useState(filters.agentFilter ?? '');
    const [status, setStatus] = useState<string>(filters.status ?? '');
    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.beginIn ? new Date(filters.beginIn) : null);
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.beginIn ? moment(filters.beginIn).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? new Date(filters.endIn) : null);
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');
    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [actingBodyUnitIds, setActingBodyUnitIds] = useState<number[]>(filters.actingBodyUnitIds ?? []);
    const [actingBodyCommandIds, setActingBodyCommandIds] = useState<number[]>(filters.actingBodyCommandIds ?? []);

    const handleCleanFilter = () => {
        setAgentFilter('');
        setStatus('');
        setTeamFilter('');
        setActingBodyId(0);
        setActingBodyUnitIds([]);
        setActingBodyCommandIds([]);
        setBeginInDateFilter(null);
        setBeginInHourFilter('');
        setEndInDateFilter(null);
        setEndInHourFilter('');
    };

    const handleFilter = () => {
        const formatDateTime = (date: Date | null, time: string) =>
            date ? moment(`${moment(date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm:ss').valueOf() : undefined;
        const beginIn = formatDateTime(beginInDateFilter, beginInHourFilter ? `${beginInHourFilter}:00` : '00:00:00');
        const endIn = formatDateTime(endInDateFilter, endInHourFilter ? `${endInHourFilter}:59` : '23:59:59');

        navigation.setParams({
            page: 1,
            status,
            actingBodyId: actingBodyId > 0 ? actingBodyId : undefined,
            actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
            agentFilter,
            teamFilter,
            beginIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined
        });

        setFilters({
            ...filters,
            page: 1,
            status,
            actingBodyId: actingBodyId > 0 ? actingBodyId : undefined,
            actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
            agentFilter,
            teamFilter,
            beginIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('searchUnit')}
                value={teamFilter}
                placeholder={translate('typeToSearch')}
                onChange={text => {
                    setTeamFilter(text);
                }}
            />

            <FilterInput
                label={translate('searchAgent')}
                value={agentFilter}
                placeholder={translate('typeToSearch')}
                onChange={text => {
                    setAgentFilter(text);
                }}
            />

            <FilterDropdown
                label={translate('status')}
                items={['',
                    'active',
                    'displaced',
                    'operationalPause',
                    'waitingActivation',
                    'waitingActivationBeforeSchedule',
                    'activeAfterSchedule',
                    'finished'
                ].map((item: string) => {
                    return {
                        label: item ? translate(item) : item, value: item
                    };
                })}
                multiple={false}
                setValue={setStatus}
                value={status}
                zIndex={7}
            />

            <FilterDatePicker
                date={beginInDateFilter}
                onChangeDate={setBeginInDateFilter}
                time={beginInHourFilter}
                onChangeTime={setBeginInHourFilter}
                label={translate('createdAt')}
                zIndex={6}
            />

            <FilterDatePicker
                date={endInDateFilter}
                onChangeDate={setEndInDateFilter}
                time={endInHourFilter}
                onChangeTime={setEndInHourFilter}
                label={translate('endIn')}
                zIndex={5}
            />

            {authenticatedUser?.isAdmin &&
                <ActingBodiesDropdown value={actingBodyId} setValue={setActingBodyId} includeAllOption={true} zIndex={4} disabled={false} />
            }

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units) &&
                <ActingBodiesUnitsDropdown
                    value={actingBodyUnitIds}
                    actingBodyId={authenticatedUser?.isAdmin ? actingBodyId : authenticatedUser?.actingBodyUnit.actingBodyId ?? 0}
                    setValue={setActingBodyUnitIds}
                    zIndex={3}
                />
            }
            <ActingBodiesCommandsDropdown
                value={actingBodyCommandIds}
                actingBodyId={authenticatedUser?.isAdmin ? actingBodyId : authenticatedUser?.actingBodyUnit.actingBodyId ?? 0}
                setValue={setActingBodyCommandIds}
                zIndex={2}
            />
        </Filters>
    );
}


export default UnitsFilter;
