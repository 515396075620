import React, { useContext } from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faCalculator, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import ThemeContext from '../../../context/Theme';
import getThemedColor from '../../../services/get-themed-color';

export default function OccurrenceFormAlarmCentral({
    occurrence
}: {
    occurrence: Occurrence;
}) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    if (occurrence.triggerType != 'alarm_center') {
        return <></>;
    }

    return (
        <ScrollView>
            <View style={{ gap: 20 }}>
                <View>
                    <MyAppText style={styles.plateText}>
                        {translate('type')}
                    </MyAppText>
                    <MyAppText style={styles.plateInfoText}>
                        {translate(occurrence.resume.type)}
                    </MyAppText>
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('visualizedOn')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faCalculator} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {occurrence.externalReference?.title}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(new Date(occurrence.createdAt)).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {occurrence.externalReference?.address}
                        </MyAppText>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        detectionInfoText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        plateText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 14
        },
        plateInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 24
        },
    });
}
