import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import MyAppText from '../MyAppText';
import ThemeContext from '../../context/Theme';
import { translate } from '../../services/translate';
import getThemedColor from '../../services/get-themed-color';
import { faCalendarCheck, faLocationDot, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import OccurrenceVideoModal from '../dispatch/occurrenceForm/OccurrenceVideoModal';
import { gcmAgentService } from '../../services/central-api/gcmAgent';

export function AreaInvasionOccurrence({ occurrence, navigation }: { occurrence: Occurrence, navigation: Navigation; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isModalVisible, setModalVisible] = useState(false);

    if (occurrence.triggerType !== 'area_invasion') {
        return <></>;
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);

            for (const detection of occurrence.resume.detections) {
                ctx.strokeStyle = 'red';
                ctx.lineWidth = 4;
                ctx.rect(detection.objectRect.x, detection.objectRect.y, detection.objectRect.width, detection.objectRect.height);
                ctx.stroke();

                ctx.strokeStyle = 'yellow';
                ctx.lineWidth = 4;
                ctx.beginPath();
                ctx.setLineDash([15, 15]);

                ctx.moveTo(detection.regionPoints[0].x, detection.regionPoints[0].y);
                for (let i = 1; i < detection.regionPoints.length; i++) {
                    ctx.lineTo(detection.regionPoints[i].x, detection.regionPoints[i].y);
                }
                ctx.closePath();
                ctx.stroke();
            }
        };

        image.src = occurrence.resume.imageUrl;
    });

    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyAppText style={{ fontSize: 16, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                            {translate('nProtocol')}: {occurrence?.id}
                        </MyAppText>
                        <MyAppText> </MyAppText>
                        <MyAppText style={{ fontSize: 14, color: getThemedColor(theme, '#888888') }}>
                            ({translate('team')} {translate(occurrence.OccurrenceUnits?.length && occurrence.OccurrenceUnits[0].OccurrenceUnit.isStarter ? 'starter' : 'support').toLowerCase()})
                        </MyAppText>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={getThemedColor(theme, '#222222')} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertText}>
                    {translate('areaInvasionAlert')}:
                </MyAppText>
                <TransformWrapper>
                    <TransformComponent>
                        <canvas style={{ width: '100%' }} ref={canvasRef}></canvas>
                    </TransformComponent>
                </TransformWrapper>
            </View>
            <View style={styles.card}>
                <View style={{ gap: 15 }}>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faPlay} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                        <TouchableOpacity onPress={() => setModalVisible(true)}>
                            <MyAppText style={styles.watchVideoText}>
                                {translate('watchVideo')}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>
                    <View style={{ gap: 5 }}>
                        <MyAppText style={styles.cardBoldText}>{translate('visualizedOn')}</MyAppText>
                        <View style={styles.visualizedRow}>
                            <FontAwesomeIcon icon={faLocationDot} style={{ color: getThemedColor(theme, '#58595B') }} />
                            <MyAppText style={styles.cardText}>{occurrence.externalReference?.title}</MyAppText>
                        </View>
                        <View style={styles.visualizedRow}>
                            <FontAwesomeIcon icon={faCalendarCheck} style={{ color: getThemedColor(theme, '#58595B') }} />
                            <MyAppText style={styles.cardText}>{moment(occurrence.resume.time).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('occurrenceId')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.id}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    <View>
                        <View style={{ paddingBottom: 30 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('narrative')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{occurrence.narrative}</MyAppText>
                        </View>
                    </View>
                </View>
            </View>
            <OccurrenceVideoModal
                occurrenceId={occurrence.id || 0}
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                occurrenceTime={new Date(occurrence.resume.time).getTime()}
                apiService={gcmAgentService}
            />
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        alertText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            fontWeight: 'bold',
        },
        alertName: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 18,
            fontWeight: 'bold',
            paddingBottom: 8
        },
        card: {
            gap: 20,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#EEEEEE'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            padding: 14,
            display: 'flex',
            alignItems: 'flex-start',
        },
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        cardText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        cardBoldText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 14
        },
        plateInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 24
        },
        watchVideoText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            textDecorationLine: 'underline'
        }
    });
}
