import React, { useEffect, useRef, useState } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { Pagination } from '../../../components/Pagination';
import moment from 'moment';
import { faDownload, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cameraService } from '../../../services/central-api/cameras';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../../typings/Params';
import Tooltip from '../../../components/Tooltip';
import MyDataTable from '../../../components/MyDataTable';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FilterInput from '../../../components/Filter/components/FilterInput';
import Filter from './Filters';
import useFilters from '../../../components/Filter/hooks/useFilters';
import getBestContrastColor from '../../../services/best-contrast-color';
import Toast from 'react-native-toast-message';
import CustomButton from '../../../components/CustomButton';

export default function CamerasList({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cameras, setCameras] = useState<PaginatedCamera[]>([]);

    const route = useRoute<RouteProp<CamerasParamList, 'CamerasList'>>();
    const [filters, setFilters] = useFilters<CamerasParamList['CamerasList']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25
    });
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [totalItems, setTotalItems] = useState(0);

    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });

    useEffect(() => {
        if (!loaders.current.didTextFilterLoad) {
            loaders.current.didTextFilterLoad = true;
            return;
        }
    }, [textFilter]);

    async function getCamerasList() {
        try {
            setIsLoading(true);
            const response = await cameraService.getCamerasPaginated({
                ...filters,
                showDisabled: false,
                hideChildren: false,
            });
            setCameras(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }
    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    useEffect(() => {
        getCamerasList();
    }, [filters]);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='cameras' lateralMenu='cameras' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                            <FilterInput
                                label={translate('searchCamera')}
                                value={textFilter}
                                placeholder={translate('typeToSearch')}
                                onChange={text => {
                                    setTextFilter(text);
                                }}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={styles.buttonsContainer}>
                            <Filter
                                filters={filters}
                                setFilters={setFilters}
                                textFilter={textFilter}
                                setTextFilter={setTextFilter}
                            />
                            {window.location.href.includes('hidden-export') && (
                                authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_download_report
                            )
                                ? <CustomButton
                                    onPress={async () => {
                                        try {
                                            const csv = await cameraService.downloadCameraReport(filters.offline || false, false);
                                            if (!(csv instanceof Blob)) {
                                                throw new Error();
                                            }
                                            const url = window.URL.createObjectURL(csv);
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = `cameras-${moment(new Date()).format('YYYY-MM-DD')}.csv`;
                                            a.click();
                                            window.URL.revokeObjectURL(url);
                                        } catch (error) {
                                            Toast.show({
                                                type: 'sentinelxError',
                                                text1: translate('unexpectedError'),
                                            });
                                        }
                                    }}
                                    text={translate('downloadReport')}
                                    icon={faDownload}
                                />
                                : null
                            }
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyDataTable
                                columns={[
                                    {
                                        name: translate('title'),
                                        selector: row => row.title,
                                        grow: 3,
                                        wrap: true,
                                    },
                                    {
                                        name: translate('serialNo'),
                                        selector: row => row.serialNo || '',
                                    },
                                    {
                                        name: translate('type'),
                                        selector: row => translate(row.type),
                                    },
                                    {
                                        name: translate('integration'),
                                        selector: row => row.thirdPartyCamera?.origin ?? '',
                                        wrap: true
                                    },
                                    {
                                        name: translate('status'),
                                        cell: row => {
                                            return row.lastTimeOnline ?
                                                <Tooltip tooltipDirection='right' tooltipContent={translate('offlineSince', {
                                                    lastTimeOnline: moment(row.lastTimeOnline).format('DD/MM/YYYY HH:mm')
                                                })} width={300}>
                                                    <MyAppText style={[styles.cell, row.isConnected ? {} : { color: '#FF0027' }]}>
                                                        {row.isConnected ? translate('online') : translate('offline')}
                                                    </MyAppText>
                                                </Tooltip>
                                                : <MyAppText style={[styles.cell, row.isConnected ? {} : { color: '#FF0027' }]}>
                                                    {row.isConnected ? translate('online') : translate('offline')}
                                                </MyAppText>;
                                        }
                                    },
                                    {
                                        name: translate('tags'),
                                        grow: 3,
                                        cell: row => {
                                            if (!row.tags) {
                                                return '';
                                            }

                                            const tags = row.tags.map((tag) =>
                                                <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                    <MyAppText style={styles.markerText(tag.color)}>
                                                        {tag.name}
                                                    </MyAppText>
                                                </View>
                                            );

                                            return <View style={{
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                width: '100%',
                                                gap: 5
                                            }}>
                                                {tags}
                                            </View>;
                                        },
                                    },
                                    {
                                        name: translate('registeredAt'),
                                        selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
                                        wrap: true
                                    },
                                    {
                                        name: translate('action'),
                                        button: true,
                                        cell: row =>
                                            <TouchableOpacity onPress={() => navigation.navigate('CameraDetails', { id: row.id })}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity>
                                    }
                                ]}
                                data={cameras}
                                progressPending={isLoading}
                            />
                        </View>
                        <Pagination
                            totalItems={totalItems}
                            currentPage={filters.page}
                            pageSize={filters.limit}
                            setPageSize={pageSize => {
                                setFilters(old => ({
                                    ...old,
                                    limit: pageSize,
                                    page: 0,
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    limit: pageSize,
                                    page: 0,
                                });
                            }}
                            setPage={page => {
                                setFilters(old => ({
                                    ...old,
                                    page
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    page: page + 1
                                });
                            }}
                        />
                    </View>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.borderColor,
        backgroundColor: theme.colors.backgroundColor,
        paddingHorizontal: 10,
        flex: 1
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    marker: {
        borderRadius: 8,
        minHeight: 16,
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: '100%'
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11,
    }),
    cell: {
        fontSize: 14,
        color: theme.colors.textColor,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
}));

