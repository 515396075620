import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    actingBodyId: number;
    zIndex: number;
}

function SkillsDropdown({ value, setValue, actingBodyId, zIndex }: Props) {
    const [selectableSkills, setSelectableSkills] = useState<{ label: string, value: number; }[]>([]);

    async function getSkills({ actingBodyId }: { actingBodyId: number; }) {
        try {
            const skills = await dispatchService.getSimplifiedSkills(actingBodyId);
            setSelectableSkills(skills.map((item: DropdownResource) => {
                return {
                    label: item.name, value: item.id
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId) {
            getSkills({ actingBodyId });
        }
    }, [actingBodyId]);

    return (
        <FilterDropdown
            placeholder={translate('selectSkills')}
            items={selectableSkills}
            multiple={true}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            mode='BADGE'
            style={{ minWidth: 240, maxWidth: 240 }}
        />
    );
}

export default SkillsDropdown;
