import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, ScrollView, Modal } from 'react-native';
import Toast from 'react-native-toast-message';
import FormActions from '../../../components/formActions';
import FormInput from '../../../components/formInput';
import MyAppText from '../../../components/MyAppText';
import { ClientError, PartialBy } from '../../../services/central-api/base-service';
import { dispatchService } from '../../../services/central-api/dispatch';
import { WindowInformation } from '../../../services/window-information';
import { translate } from '../../../services/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../../components/DeleteModal';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import CustomTextInput from '../../../components/CustomTextInput';

interface Props {
    setModalVisible: (value: boolean) => void;
    isModalVisible: boolean;
    finishCategoryId?: number;
    onClose: () => void;
    authenticatedUser?: AuthenticatedUser;
}

export default function EditModal({ isModalVisible, setModalVisible, finishCategoryId, onClose, authenticatedUser }: Props) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [finishSubCategories, setFinishSubCategories] = useState<PartialBy<FinishSubCategory, 'name'>[]>([{ name: '' }]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedFinishSubCategoryForDeletion, setSelectedFinishSubCategoryForDeletion] = useState<Partial<FinishSubCategory>>();

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name) && finishSubCategories.length > 0;
    }

    function clearValues() {
        setName('');
        setActingBodyId(0);
        setFinishSubCategories([]);
    }

    async function getFinishCategory(finishCategoryId: number) {
        try {
            const finishCategory = await dispatchService.getFinishCategory(finishCategoryId);
            setName(finishCategory.name);
            setActingBodyId(finishCategory.actingBodyId);
            setFinishSubCategories(finishCategory.finishSubCategories || []);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteFinishSubCategory(finishSubCategory: FinishSubCategory) {
        try {
            if (!finishSubCategory.id) {
                return;
            }
            await dispatchService.deleteFinishSubCategory(finishSubCategory.id);
            const index = finishSubCategories.findIndex(r => r.id == finishSubCategory.id);
            finishSubCategories.splice(index, 1);
            setFinishSubCategories([...finishSubCategories]);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (finishCategoryId) {
            getFinishCategory(finishCategoryId);
        }
    }, [finishCategoryId]);

    async function formSubmit() {
        try {
            if (finishCategoryId) {
                await dispatchService.updateFinishCategory(finishCategoryId, {
                    name,
                    finishSubCategories: finishSubCategories.filter(fsc => fsc.name !== ''),
                });

            } else {
                await dispatchService.createFinishCategory({
                    name,
                    finishSubCategories: finishSubCategories.filter(fsc => fsc.name !== ''),
                    actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            setModalVisible(false);
            clearValues();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '30%' }]}>
                    {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_finish_categories) &&
                        <ActingBodiesDropdown includeAllOption={false} value={actingBodyId} setValue={setActingBodyId} zIndex={2} disabled={!!finishCategoryId} />
                    }
                    <FormInput
                        label={translate('name')}
                        placeholder={translate('name')}
                        value={name}
                        onChangeText={setName}
                        invalid={isNameInvalid}
                    />
                    <View style={{ gap: 8, maxHeight: 400 }}>
                        <MyAppText>{translate('finishSubCategories')}</MyAppText>
                        <ScrollView contentContainerStyle={{ gap: 5 }}>
                            {finishSubCategories.map((finishSubCategory, index) => (
                                <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                    <CustomTextInput
                                        placeholder={'subCategory'}
                                        value={finishSubCategory.name}
                                        onChangeText={(value) => {
                                            finishSubCategories[index].name = value;
                                            setFinishSubCategories([...finishSubCategories]);
                                        }} />
                                    <TouchableOpacity onPress={() => {
                                        const subCategory = finishSubCategories[index];
                                        if (subCategory.id) {
                                            setSelectedFinishSubCategoryForDeletion(subCategory);
                                            setIsDeleteModalVisible(true);
                                            return;
                                        }
                                        finishSubCategories.splice(index, 1);
                                        setFinishSubCategories([...finishSubCategories]);
                                    }}>
                                        <FontAwesomeIcon fontSize={24} icon={faTimes} color={theme.colors.iconColor} />
                                    </TouchableOpacity>
                                </View>))
                            }
                        </ScrollView>
                        <TouchableOpacity onPress={() => {
                            finishSubCategories.push({ name: '' });
                            setFinishSubCategories([...finishSubCategories]);
                        }}>
                            <MyAppText>+ {translate('addFinishSubCategory')}</MyAppText>
                        </TouchableOpacity>
                    </View>

                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()} />
                </View>

                <DeleteModal
                    setModalVisible={setIsDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={selectedFinishSubCategoryForDeletion?.name}
                    onSubmit={async () => {
                        if (selectedFinishSubCategoryForDeletion) {
                            await deleteFinishSubCategory(selectedFinishSubCategoryForDeletion as FinishSubCategory);
                        }
                    }}
                />
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));

